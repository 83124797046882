import { ClientRole, createClient, SDK_CODEC, SDK_MODE } from 'agora-rtc-react';
import {
  BasicOrderByValues,
  FileExtension,
  InvitationStatusId,
  SubscriptionSchemeId,
} from 'common/enums';

export const fetchVariable = (varName: string, defaultValue?: string) => {
  if (!import.meta.env[varName] && typeof defaultValue === 'undefined') {
    throw new Error(`Mandatory environment variable ${varName} is not set.`);
  }
  return import.meta.env[varName] || defaultValue;
};

const nodeEnv = fetchVariable('MODE');

export const environment = {
  isDevelopment: nodeEnv === 'development',
  isProduction: nodeEnv === 'production',
};

export const flagsmithEnvironmentId = fetchVariable(
  'VITE_APP_FLAGSMITH_ENVIRONMENT_ID',
  '',
) as string;

export const sentryDSN = fetchVariable('VITE_APP_SENTRY_DSN', '') as string;
export const sentryEnvironment = fetchVariable(
  'VITE_APP_SENTRY_ENV',
  '',
) as string;
export const sentryRelease = fetchVariable(
  'VITE_APP_SENTRY_RELEASE',
  '',
) as string;

export const posthogKey = fetchVariable(
  'VITE_APP_PUBLIC_POSTHOG_KEY',
  '',
) as string;
export const posthogHost = fetchVariable('VITE_APP_POSTHOG_HOST', '') as string;
export const analyticsKey = fetchVariable(
  'VITE_APP_ANALYTICS_TRACKING_ID',
  '',
) as string;

// -------- Mock Server Postam --------------
export const myMockServerUrl =
  'https://bc4effb0-8cd9-4636-8841-838b26abc839.mock.pstmn.io';
export const myHeaders = {
  'x-api-key':
    'PMAK-62449202636a1f63afd1174b-81a1dc8d293d928a75e472dd80d776d34e',
};
// ------------------------------------------

export const rootUrl = 'https://www.generationalstory.com';

export const errorsCode = {
  emailInUse: 40002,
  credentialsError: 40008,
  resentEmail: 42900,
  oldPasswordIncorrect: 40004,
  invalidCaptcha: 40025,
  forbidden: 40300,
  notAuthorized: 40100,
};

export const screenSizes = {
  xs: `(max-width: ${576}px)`,
  sm: `(max-width: ${768}px)`,
  md: `(max-width: ${998}px)`,
  lg: `(max-width: ${1200}px)`,
  xl: `(max-width: ${1400}px)`,
};

export const maxCharactersAlbumTitleByResolution = {
  xs: 15,
  sm: 20,
  md: 25,
  lg: 30,
  xl: 45,
  xxl: 55,
};

export const albumTitleMaxLength = 140;
export const questionsMaxLength = 140;
export const descriptionClipMaxLength = 280;

export const templatesCountMaxLength = 50;
export const templateQuestionsCountMaxLength = 50;
export const templateNameMaxLength = 140;

export const maxNumberDiscountCodes = 1; // TODO: fix then for multiples discount codes.

export const mbAlbumCoverMaxSize = 20;
export const mbVideoMaxSize = 5 * 1024; // 5GB
export const sFileMaxDuration = 900; // 15 min

export const msToRecallAlbumDetailEndpoint = 3000;
export const msToRecallMeEndpoint = 3000;

export const msToRecallPaymentEndpoint = 3000;

export const msToCheckIfUserAlreadyVerified = 5000; // 5 seconds
export const msMaxPercentageToAlertVideoCallTime = 90; // 90%
export const msToEnableResendEmailButton = 600000; // 10 min

export const msToHideClipRecordedWindow = 10000; // 10 seconds
export const msMaxRecordingPeriodTime = 900000; // 15 min
export const msMaxTimeBeforeShowingAlertLongRecording = 780000; // 13 min

export const msMaxClipChunkDuration = 300000; // 5 minutes // ! Not use for now
export const msMaxTimeBeforeShowingTimeRunOutToggle = 270000; // 4.5 min // ! Not use for now

export const sAnnualWarningTimeVideoCall = 600; // 10 minutes
export const sAnnualAlertTimeVideoCall = 60; // 1 minutes
export const msToRecallPingToGetVideoCallUsedTime = 60000; // 1 minute

export const maxTimeClipRecordedInSeconds = 900; // 15 min

export const clipsPackSize = 20;

export const extraVideoCallPackage = 2;

export const genericContainerPadding = 10;

export const paginationDefault = {
  pageNum: 1,
  pageSize: 12,
  lastUpdate: { id: 'last_update:desc', value: 'Last Update' },
};

export const albumCoverSupportedExtensions = [
  FileExtension.Jpg,
  FileExtension.Jpeg,
  FileExtension.Png,
];

export const ImageSupportedExtensions = [
  FileExtension.Jpg,
  FileExtension.Jpeg,
  FileExtension.Png,
];

export const videoSupportedExtensions = [FileExtension.Mp4, FileExtension.Webm];

export const albumsTitles: any = {
  albumName: 'Album name',
  lastUpdate: 'Last update',
  clipRecorded: 'Clip recorded',
  recordingLength: 'Recording length',
  albumOwner: 'Album owner',
  status: '',
};

export const subscriptionSchemeInfo = {
  [SubscriptionSchemeId.trial]: {
    name: 'Free Trial',
    paymentPeriod: '',
  },
  [SubscriptionSchemeId.readOnly]: {
    name: 'Read Only',
    paymentPeriod: 'yearly',
  },
  [SubscriptionSchemeId.standard]: {
    name: 'Standard',
    paymentPeriod: 'yearly',
  },
  [SubscriptionSchemeId.legacy]: {
    name: 'Legacy',
    paymentPeriod: 'one time',
  },
  [SubscriptionSchemeId.free]: {
    name: 'Free',
    paymentPeriod: 'one time',
  },
  [SubscriptionSchemeId.readOnlyFree]: {
    name: 'Read Only Free',
    paymentPeriod: 'yearly',
  },
  [SubscriptionSchemeId.lifeTime]: {
    name: 'Lifetime',
    paymentPeriod: 'one time',
  },
};

export const InvitationStatusInfo = {
  [InvitationStatusId.accepted]: {
    name: 'Accepted',
  },
  [InvitationStatusId.pending]: {
    name: 'Not accepted',
  },
  [InvitationStatusId.rejected]: {
    name: 'Rejected',
  },
};

export const albumsOrderByOptions: any = [
  { id: 'title:asc', value: BasicOrderByValues.alphabeticallyAZ },
  { id: 'title:desc', value: BasicOrderByValues.alphabeticallyZA },
  { id: 'last_update:desc', value: BasicOrderByValues.lastUpdate },
  { id: 'last_update:asc', value: BasicOrderByValues.oldestUpdate },
];

export const contributorsOrderByOptions: any = [
  { id: 'name:asc', value: BasicOrderByValues.alphabeticallyAZ },
  { id: 'name:desc', value: BasicOrderByValues.alphabeticallyZA },
  { id: 'last_update:desc', value: BasicOrderByValues.lastUpdate },
  { id: 'last_update:asc', value: BasicOrderByValues.oldestUpdate },
];

export const accountAlbumsTitles: any = {
  albumName: 'Album name',
  subscription: 'Subscription',
  lastBilling: 'Last billing',
  renewalExpiration: 'Renewal / Expiration',
  status: '',
};

export const contributorAlbumsTitles: any = {
  albumName: 'Album name',
  owner: 'Owner',
  company: 'Company',
};

export const storeLinks = {
  appleStore: 'https://apps.apple.com/uy/app/generational-story/id6443850095',
  androidStore:
    'https://play.google.com/store/apps/details?id=com.gs.production&hl=en&gl=US',
};

export const contactLink = 'https://www.generationalstory.com/contact-us';

export const deleteAccountLink =
  'https://www.generationalstory.com/delete-account';

export const apiBaseURL = fetchVariable('VITE_APP_API_BASE_URL');

export const stripePublicKey = fetchVariable('VITE_APP_STRIPE_PUBLIC_KEY');
export const stripeAPIVersionOverride = fetchVariable(
  'VITE_APP_STRIPE_API_VERSION_OVERRIDE',
  '',
);

export const captchaSiteKey = fetchVariable('VITE_APP_CAPTCHA_SITE_KEY');

export const isBeta =
  (fetchVariable('VITE_APP_IS_BETA') || '').toLowerCase() === 'true';

export const errorFormUrl = fetchVariable('VITE_APP_ERROR_FORM_URL', '');

export const agoraOptions = {
  appId: fetchVariable('VITE_APP_AGORA_APP_ID'),
  role: 'host' as ClientRole,
  logLevel: +(fetchVariable('VITE_APP_AGORA_LOG_LEVEL', '3') as string),
};

export const useClient = createClient({
  mode: 'live' as SDK_MODE,
  codec: 'vp8' as SDK_CODEC,
});

export const CAMERA_ID_KEY = 'selectedCameraId';
export const MICROPHONE_ID_KEY = 'selectedMicrophoneId';

export const CAMERA_ENABLED_KEY = 'cameraEnabled';
export const MICROPHONE_ENABLED_KEY = 'microphoneEnabled';

export const confirmationMsgLetUploadVideo =
  'Are you sure you want to leave this page? If you leave your in progress uploads will be cancelled.';

export const utmProperties: UtmProperties[] = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export const frequencyOptions: any = [
  { id: 'weekly', value: 'every week' },
  { id: 'bi_monthly', value: 'twice a month' },
  { id: 'monthly', value: 'every month' },
];

export const errorsCodeInPayments = {
  cardDeclined: 40024,
};

export const tutorialLinks = {
  introduction: 'https://www.generationalstory.com/tutorials/introduction',
  createAlbum: 'https://www.generationalstory.com/tutorials/create-an-album',
  planInterview:
    'https://www.generationalstory.com/tutorials/pre-plan-an-interivew', // TODO: This is misspelled. Ask for change the landing url
  createATemplate:
    'https://www.generationalstory.com/tutorials/create-a-template',
  recordOnVideoConference:
    'https://www.generationalstory.com/tutorials/record-over-video-conference-call',
  selfRecordOnBrowser:
    'https://www.generationalstory.com/tutorials/self-record-over-your-browser',
  addClipDetails:
    'https://www.generationalstory.com/tutorials/add-photos-descriptions-and-set-clips-to-audio-only',
  uploadVideos: 'https://www.generationalstory.com/tutorials/upload-videos',
  setUpReminders:
    'https://www.generationalstory.com/tutorials/set-up-email-reminders',
  addContributors:
    'https://www.generationalstory.com/tutorials/add-contributors',
  linkQRCode:
    'https://www.generationalstory.com/tutorials/connect-qr-labels-to-your-generational-story-album',
  sharingAndPrivacySettings:
    'https://www.generationalstory.com/tutorials/sharing-and-privacy',
  downloadAlbumOrClip:
    'https://www.generationalstory.com/tutorials/download-an-album-or-clip',
};

export const faqLinks = {
  whatIs: 'https://www.generationalstory.com/faq#1whatis',
  primaryFeatures: 'https://www.generationalstory.com/faq#10primaryfeatures',
  albumTypes: 'https://www.generationalstory.com/faq#26albumtypes',
  prepareInterview:
    'https://www.generationalstory.com/faq#30prepareforinterview',
  whatToAsk: 'https://www.generationalstory.com/faq#31whattoask',
  contributorsAndGuests:
    'https://www.generationalstory.com/faq#12contributorsandguests',
  customRecording: 'https://www.generationalstory.com/faq#13customrecording',
  clipsAmount: 'https://www.generationalstory.com/faq#5clipsinanalbum',
  legacyName: 'https://www.generationalstory.com/faq#3backupnames',
  multipleDevices: 'https://www.generationalstory.com/faq#4multipledevices',
  deletionPolicy: 'https://www.generationalstory.com/faq#9deletionpolicy',
  qrCodesAdvantages: 'https://www.generationalstory.com/faq#11qrcodeadvantages',
};
