/*
  Add all API paths used on the app here so that
  they are centrally documented.
*/
const API_ROUTES = {
  ME: '/api/sessions/me',
  SUBSCRIPTION_TYPES: '/api/subscriptions',
  GET_CUSTOM_TEMPLATES: 'api/interview_templates',
  ADD_CUSTOM_TEMPLATES: 'api/interview_templates',
  MODIFY_CUSTOM_TEMPLATES: (templateId: number) =>
    `api/interview_templates/${templateId}`,
  SIGNUP: '/api/sessions/sign_up',
  SIGNUP_TEMPORARY_USER: '/api/sessions/sign_up_temporary_user',
  SIGNIN: '/api/sessions',
  SIGNOUT: '/api/sessions',
  STORE: '/api/store/products',
  STORE_CREATE_ORDER: '/api/store/orders',
  FORGOTPASSWORD: '/api/sessions/forgot_password',
  RESETPASSWORD: '/api/sessions/reset_password',
  CHANGEPASSWORD: '/api/sessions/me/password',
  GET_CONTRIBUTORS: 'api/sessions/me/contributors',
  ALBUMS: '/api/albums',
  ALBUM_DETAIL: (id: number) => `/api/albums/${id}`,
  ALBUM_GUEST: (id: number) => `/api/albums/${id}/show_with_token`,
  ALBUM_EDIT: (id: number) => `/api/albums/${id}`,
  ALBUM_DETAIL_SHARED: (id: number) => `/api/albums/${id}/share`,
  ALBUM_DETAIL_VIDEO_CALL: (id: number, videoCallToken: string) =>
    `/api/albums/${id}/view_video_call/${videoCallToken}`,
  DELETE_ALBUM: (id: number) => `/api/albums/${id}`,
  RESEND_ALBUM_INVITATION: (invitationId: number) =>
    `/api/album_invitations/${invitationId}/resend`,
  ADD_CONTRIBUTOR: (id: number) => `/api/albums/${id}/contributors`,
  REMOVE_CONTRIBUTOR: (albumId: number, invitationId: number) =>
    `/api/albums/${albumId}/contributors/${invitationId}`,
  LEAVE_ALBUM: (albumId: number) => `/api/albums/${albumId}/leave_album`,
  CREATE_CLIP: (id: number) => `/api/albums/${id}/clips`,
  CREATE_CLIP_GUEST: (id: number) => `/api/albums/${id}/add_clip_with_token`,
  CREATE_REMINDER: (id: number) => `/api/albums/${id}/reminders`,
  CREATE_TEMPORARY_USER: '/api/sessions/temporary_user',
  DESTROY_CLIPS: (id: number) => `/api/albums/${id}/destroy_clips`,
  GET_DEFAULT_QUESTIONS: '/api/albums/suggested_questions',
  EDIT_CLIP: (id: number) => `/api/clips/${id}`,
  EDIT_CLIP_GUEST: (id: number) => `/api/clips/${id}/update_with_token`,
  EDIT_REMINDER: (albumId: number, reminderId: number) =>
    `api/albums/${albumId}/reminders/${reminderId}`,
  DELETE_CLIP: (id: number) => `/api/clips/${id}`,
  DELETE_REMINDER: (albumId: number, reminderId: number) =>
    `/api/albums/${albumId}/reminders/${reminderId}`,
  REQUEST_ALBUM_DOWNLOAD: (id: number) => `/api/albums/${id}/request_download`,
  PRESIGNED_URL: '/api/user/presigned_url',
  VERIFYEMAIL: '/api/sessions/verify_email',
  RESENDEMAIL: '/api/user/resend_verification',
  AGORA_OWNER_IN_CALL_STATUS: (videoCallToken: string) =>
    `/api/video_calls/${videoCallToken}/owner_in_call`,
  AGORA_GET_TOKEN: (videoCallToken: string) =>
    `/api/video_calls/${videoCallToken}/generate_token`,
  AGORA_START_RECORDING: (videoCallToken: string) =>
    `/api/video_calls/${videoCallToken}/start_recording`,
  AGORA_STOP_RECORDING: (videoCallToken: string) =>
    `/api/video_calls/${videoCallToken}/stop_recording`,
  AGORA_GET_VIDEO_CALL_USED_TIME: (id: number) =>
    `/api/albums/${id}/video_call/ping`,
  SHARE_CLIP_BY_EMAIL: (clipId: number) => `/api/clips/${clipId}/email_share`,
  UPGRADE_ALBUM: (id: number) => `/api/albums/${id}/upgrade`,
  CANCEL_SUBSCRIPTION: (id: number) => `/api/albums/${id}/cancel_subscription`,
  GET_SUBSCRIPTION_DEAL_BY_CODE: (code: string) =>
    `/api/subscription_deals/${code}`,
  APPLY_SUBSCRIPTION_DEAL_BY_CODE: '/api/subscription_deals',
  SUBSCRIPTION_PRICES: '/api/subscriptions/price',
  REMOVE_SUBSCRIPTION_DEL_CODE: (code: string) =>
    `/api/subscription_deals/${code}`,
  SEND_LINK_TO_VIDEO_CALL: (id: number) =>
    `/api/albums/${id}/send_link_to_call`,
  ADD_PAYMENT_METHOD: '/api/user/add_payment_method',
  GET_TIER_SWITCH_PRICES: (albumId: number) =>
    `api/albums/${albumId}/tier_switch_prices`,
  GET_SHIPPING_COSTS: '/api/store/shipping_costs',
  INVITE_GUEST_TO_RECORD: (id: number) => `/api/albums/${id}/invite_guest`,
  SEND_SAMPLE_EMAIL: (albumID: number) =>
    `/api/albums/${albumID}/reminders/sample_reminder`,
  BUY_CLIPS: (id: number) => `/api/albums/${id}/purchase_clips`,
  BUY_EXTRA_CALL_TIME: (albumId: number) =>
    `/api/albums/${albumId}/purchase_videocall_time`,
  GET_PAYMENT: (id: number) => `/api/payments/${id}`,
  USER_ACTIVE: '/api/sessions/active',
};

export { API_ROUTES };
