import { Modal } from 'common/modal';
import React, { useContext, useState } from 'react';
import { classnames, getPermissionsByAlbum, isEmailValid } from 'helpers/utils';
import { Input } from 'common/input';
import { Button, ButtonStyle } from 'common/button';
import { CrossIcon, PlusIcon } from 'assets/icons';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { appActions, AppContext } from 'context';
import { Spinner } from 'common/spinner';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { ApiErrorArray } from 'models/api-errors-array';
import styles from './modal-invite-users.module.scss';

export enum GuestType {
  contributor = 'contributor',
  guest = 'guest',
}

type ModalInviteUsersProps = {
  userType: GuestType;
  handleBack?: () => void;
  handleClose?: () => void;
  handleConfirm: () => void;
};

type User = {
  name: string;
  email: string;
};

type UserInvite = { key: string } & User;

const getUniqueKey = () => Math.random().toString(36).substring(2, 10);

const isEmailAlreadyAdded = (
  allUsers: { name: string; email: string }[],
  currentEmail: string,
) => allUsers.find((user: ContributorType) => user.email === currentEmail);

const ModalInviteUsers: React.FC<ModalInviteUsersProps> = ({
  userType,
  handleBack,
  handleConfirm,
  handleClose,
}) => {
  const {
    dispatch,
    state: { currentAlbum, subscriptionsScheme, data },
  } = useContext(AppContext);

  const [users, setUsers] = useState<UserInvite[]>([
    {
      key: getUniqueKey(),
      name: '',
      email: '',
    },
  ]);
  const [errors, setErrors] = useState<User[]>([
    {
      name: '',
      email: '',
    },
  ]);
  const [loading, setLoading] = useState(false);

  const invitesLeft =
    subscriptionsScheme && currentAlbum
      ? getPermissionsByAlbum(subscriptionsScheme, currentAlbum).contributors -
        currentAlbum.contributors.length
      : 0;

  const currentInvitesLeft =
    invitesLeft -
    users.filter((user) => user.email.length && user.name.length).length;

  const updateUser = (index: number, field: keyof User, value: string) => {
    const newUsersState = users.slice();
    newUsersState[index][field] = value;
    const newErrorState = errors.slice();
    newErrorState[index][field] = '';
    setUsers(newUsersState);
    setErrors(newErrorState);
  };

  const removeUser = (index: number) => {
    setUsers((prevState) => prevState.filter((_, i) => i !== index));
    setErrors((prevState) => prevState.filter((_, i) => i !== index));
  };

  const addUser = () => {
    setUsers((prevState) => [
      ...prevState,
      {
        key: getUniqueKey(),
        name: '',
        email: '',
      },
    ]);
    setErrors((prevState) => [
      ...prevState,
      {
        name: '',
        email: '',
      },
    ]);
  };

  const getEmailError = (
    userList: { name: string; email: string }[],
    contributors: ContributorType[],
    currentEmail: string,
  ) => {
    let emailError = '';

    if (!isEmailValid(currentEmail)) {
      emailError = 'invalid email';
    } else if (isEmailAlreadyAdded(contributors, currentEmail)) {
      emailError = 'Contributor has already been invited';
    } else if (isEmailAlreadyAdded(userList, currentEmail)) {
      emailError = 'Email is already on the list';
    }

    return emailError;
  };

  const validateField = (index: number, field: keyof User) => {
    let emailError = '';
    const user = users[index];
    const newErrorsState = errors.slice();
    if (user[field].trim() === '') {
      newErrorsState[index][field] = `${field} cannot be empty`;
      setErrors(newErrorsState);
    } else if (field === 'email') {
      setErrors(newErrorsState);
      if (user.email === data.user.email) {
        newErrorsState[index][field] = `Can't invite yourself`;
        setErrors(newErrorsState);
      } else {
        const usersAdded = users.slice();
        usersAdded.splice(index, 1);
        emailError = getEmailError(
          usersAdded,
          currentAlbum?.contributors ?? [],
          user[field],
        );
        newErrorsState[index][field] = emailError.toUpperCase();
        setErrors(newErrorsState);
      }
    }
  };

  const hasErrorsForm = () =>
    errors.some((error) => !!error.email || !!error.name);

  const checkAllFieldsAndGetErrors = () =>
    users.map(({ name, email }) => {
      const errs = { name: '', email: '' };

      if (name === '') {
        errs.name = 'Name cannot be empty';
      }
      if (email === '') {
        errs.email = 'Email cannot be empty';
      }
      if (!isEmailValid(email)) {
        errs.email = 'Invalid email';
      }

      return errs;
    });

  const inviteUsers = async () => {
    const newErrorsList = checkAllFieldsAndGetErrors();
    setErrors(newErrorsList);

    if (newErrorsList.some((error) => !!error.email || !!error.name)) {
      return;
    }

    setLoading(true);
    try {
      if (!currentAlbum) return;
      const body = users.map((user) => ({
        name: user.name,
        email: user.email,
      }));
      if (userType === GuestType.guest) {
        await AlbumsController.inviteGuestsToRecord(currentAlbum.id, body);
      } else {
        await AlbumsController.addContributors(currentAlbum.id, body);
      }
      handleConfirm();
      setLoading(false);
    } catch (error) {
      const errorMessage =
        error instanceof ApiErrorArray
          ? error.errors[0].details
          : 'Please try again later.';
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: "Can't invite collaborator(s).",
          message: errorMessage,
          type: NotificationType.Error,
        }),
      });
      setLoading(false);
    }
  };

  const sendButtonDisabled = hasErrorsForm();

  const addUserDisabled =
    userType === GuestType.contributor && invitesLeft - users.length === 0;

  return (
    <Modal contentClassName={styles.container} onClose={handleClose}>
      <div className={styles.content}>
        <div className={styles.usersInfo}>
          <div className={styles.leftSide}>
            <div className="text__heading4__primary50">
              Invite {userType}s to the album
            </div>
            {userType === GuestType.contributor ? (
              <p className="text__body__regular__medium__textNeutral30">
                <span className="text__body__semi__bold__medium__primary50">
                  Contributors have permanent management access to the album.
                </span>{' '}
                They can add prompts, see, add, edit and delete content. You’re
                allowed to have up 1 contributor on free albums, 5 on standard
                and 10 on lifetime or legacy.
              </p>
            ) : (
              <p className="text__body__regular__medium__textNeutral30">
                <span className="text__body__semi__bold__medium__primary50">
                  Guests have limited access to add content to the album.
                </span>{' '}
                They can’t add, edit or delete content that’s already there or
                modify the prompts. They can access the album for a week, after
                that they will have to be re-invited.
              </p>
            )}
            {userType === GuestType.contributor && (
              <span
                className={classnames(
                  'text__body__regular__medium__textNeutral30',
                  styles.invitesCounter,
                )}
              >
                <span className="text__body__semi__bold__medium__primary50">
                  {currentInvitesLeft > 0 ? currentInvitesLeft : 0}
                </span>{' '}
                invite{currentInvitesLeft !== 1 && 's'} left
              </span>
            )}
          </div>
          <div className={styles.rightSide}>
            <div className={styles.users}>
              {users.map(({ name, email, key }, index) => (
                <div
                  key={key}
                  className={classnames(styles.userInputs, {
                    [styles.firstInput]: index === 0,
                  })}
                >
                  <Input
                    id="name"
                    name="name"
                    label={index === 0 ? 'Name' : ''}
                    placeholder="Name"
                    value={name}
                    type="text"
                    errorMessage={errors[index].name}
                    onChange={(e: any) =>
                      updateUser(index, 'name', e.target.value)
                    }
                    onBlur={() => validateField(index, 'name')}
                    className={styles.input}
                    innerClassName={classnames(
                      'text__body__regular__medium__textNeutral30',
                      styles.inputContent,
                    )}
                  />
                  <Input
                    id="email"
                    name="email"
                    label={index === 0 ? 'Email' : ''}
                    placeholder="Email"
                    value={email}
                    type="email"
                    errorMessage={errors[index].email}
                    onChange={(e: any) =>
                      updateUser(index, 'email', e.target.value)
                    }
                    onBlur={() => validateField(index, 'email')}
                    className={classnames(styles.input)}
                    innerClassName={classnames(
                      'text__body__regular__medium__textNeutral30',
                      styles.inputContent,
                    )}
                  />
                  <Button
                    className={classnames(styles.deleteButton, {
                      [styles.firstDeleteButton]: index === 0,
                    })}
                    buttonStyle={ButtonStyle.PrimaryStroke}
                    onClick={() => removeUser(index)}
                    disabled={users.length === 1}
                  >
                    <CrossIcon />
                  </Button>
                </div>
              ))}
            </div>
            <Button
              buttonStyle={ButtonStyle.PrimaryStroke}
              className={styles.addButton}
              onClick={addUser}
              disabled={addUserDisabled}
            >
              <PlusIcon /> Add another
            </Button>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            buttonStyle={ButtonStyle.GreyGhost}
            onClick={handleBack ?? handleClose}
          >
            {handleBack ? 'Back' : 'Cancel'}
          </Button>
          {loading ? (
            <Spinner className={styles.spinner} />
          ) : (
            <Button
              buttonStyle={ButtonStyle.PrimaryFilled}
              disabled={sendButtonDisabled}
              onClick={inviteUsers}
            >
              Send invite(s)
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { ModalInviteUsers };
