import React, { useState, ChangeEvent, useContext } from 'react';

import { MailIcon } from 'assets/icons';
import { Button, ButtonStyle, ButtonSize } from 'common/button';
import { Modal } from 'common/modal';
import { Input } from 'common/input';
import { Spinner } from 'common/spinner';
import { classnames, isEmailValid, isTemporaryUser } from 'helpers/utils';
import { useFetch } from 'hooks/use-fetch';
import { AlbumsController } from 'networking/controllers/albums-controller';

import { ModalWarning } from 'common/modal-warning';
import { goToPage, RouteName } from 'routes';
import { AppContext } from 'context';
import { trackCustomEvent } from 'helpers/analytics';
import styles from './share-by-email.module.scss';

type ShareByEmailProps = {
  className?: string;
  clip: ClipType;
};

const ShareByEmail: React.FC<ShareByEmailProps> = ({
  className = '',
  clip,
}) => {
  const { state } = useContext(AppContext);
  const [openModal, setOpenModal] = useState(false);
  const [modalSignUp, setModalSignUp] = useState(false);
  const [email, setEmail] = useState('');
  const [inputError, setInputError] = useState('');
  const [error, setError] = useState(null);
  const [successfulShared, setSuccessfulShared] = useState(false);
  const { 0: loading, 3: setExecution } = useFetch({
    action: AlbumsController.shareClipByEmail,
    param: { clipId: clip.id, email },
    exec: false,
    onError: setError,
    onSuccess: () => setSuccessfulShared(true),
  });

  const handleCancel = () => {
    setOpenModal(false);
    setEmail('');
    setSuccessfulShared(false);
    setError(null);
  };
  const handleClick = () => {
    setError(null);
    if (isEmailValid(email)) {
      setExecution(true);
      trackCustomEvent('confirmSendClipByEmail', { email });
      return;
    }
    setInputError('invalid email');
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    setInputError('');
    setSuccessfulShared(false);
  };
  const modalFooterClass = classnames(styles.modalFooter, {
    [styles.rowReverse]: !loading && !error && !successfulShared,
  });

  return (
    <>
      {modalSignUp && (
        <ModalWarning
          title="Sign up to share or download your recordings"
          content={
            <div className={styles.modalSignUpInfo}>
              <div className="text__body__regular__medium__textNeutral40">
                Please complete the sign up process to share or download your
                clips, as well as access the rest of our features.
              </div>
              <div className="text__body__regular__medium__textNeutral40">
                Don’t worry, your first album is{' '}
                <span className="text__body__semi__bold__medium__textNeutral40">
                  free
                </span>{' '}
                for a month, and signing up only takes 2 minutes!
              </div>
            </div>
          }
          successButtonText="Sign up"
          closeFn={() => setModalSignUp(false)}
          successFn={() => goToPage(RouteName.SignUp)}
          successStyleButton={ButtonStyle.PrimaryFilled}
        />
      )}
      {openModal && (
        <Modal
          title="Share clip via email"
          onClose={handleCancel}
          className={styles.modalContainer}
          contentClassName={styles.modalContent}
        >
          <p className="text__body__semi__bold__medium__textNeutral40">
            Who do you want to share this with?
          </p>
          <Input
            id="share-email"
            value={email}
            onChange={handleChange}
            placeholder="Type email"
            errorMessage={inputError}
          />
          <div className={modalFooterClass}>
            {loading && <Spinner className={styles.spinner} />}
            {error && (
              <div className={styles.errorContainer}>
                <span className="text__body__regular__large__warning30">
                  Something went wrong.
                </span>
                <span className="text__body__regular__small__textNeutral30">
                  We couldn’t share the clip. Please try again.
                </span>
              </div>
            )}
            {successfulShared && (
              <p className="text__body__semi__bold__small__primary50">
                Clip sent!
              </p>
            )}
            <div className={styles.buttonsfooter}>
              <Button
                buttonStyle={ButtonStyle.GreyGhost}
                buttonSize={ButtonSize.Small}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button disabled={!email || loading} onClick={handleClick}>
                Send
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <Button
        buttonStyle={ButtonStyle.PrimaryGhost}
        className={classnames(styles.container, className)}
        onClick={
          isTemporaryUser(state.data.user)
            ? () => setModalSignUp(true)
            : () => {
                setOpenModal(true);
                trackCustomEvent('startSendClipByEmail');
              }
        }
      >
        <MailIcon />
        <span> Email </span>
      </Button>
    </>
  );
};

export { ShareByEmail };
