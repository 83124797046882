import { ContributorsStateType } from 'pages/contributors/contributors-reducer';
import { classnames } from 'helpers/utils';
import { AlertTriangleIcon, ChevronIcon } from 'assets/icons';
import { AlbumTabs, InvitationStatusId } from 'common/enums';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { RouteName, goToPage } from 'routes';
import styles from './contributors-mobile.module.scss';

type ContributorsMobileProps = {
  contributorsState: ContributorsStateType;
};

const ContributorsMobile: React.FC<ContributorsMobileProps> = ({
  contributorsState,
}) => {
  const { contributorsList } = contributorsState;

  const showInvitationStatus = (contri: ContributorElementType) => {
    const invitationStatusId: InvitationStatusId = contri.invitationStatus;

    if (invitationStatusId !== InvitationStatusId.accepted) {
      const iconStyles =
        invitationStatusId === InvitationStatusId.rejected
          ? styles.dangerIconColor
          : styles.warningIconColor;

      return <AlertTriangleIcon className={iconStyles} />;
    }

    return null;
  };

  return (
    <div className={styles.contributorsMobile}>
      {contributorsList.map((contributor: ContributorElementType) => (
        <div key={contributor.idAux} className={styles.row}>
          <div className={styles.leftColumn}>
            <p
              className={classnames(
                'text__body__semi__bold__medium__textNeutral40',
                styles.nameAndStatus,
              )}
            >
              <span>{contributor.user.name}</span>
              <span>{showInvitationStatus(contributor)}</span>
            </p>
            <p>
              <span className="text__body__semi__bold__small__textNeutral30">
                Album: &nbsp;
              </span>
              <span className="text__body__regular__small__textNeutral30">
                {contributor.album.title}
              </span>
            </p>
          </div>
          <div className={styles.rightColumn}>
            <Button
              buttonSize={ButtonSize.Tiny}
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              onClick={() => {
                goToPage(
                  RouteName.AlbumDetail,
                  { id: contributor.album.id },
                  { tab: AlbumTabs.settings },
                );
              }}
            >
              <ChevronIcon className={styles.chevronIcon} />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export { ContributorsMobile };
