import { Modal } from 'common/modal';
import React from 'react';
import VacationImage from 'assets/images/events-vacation.png';
import WeddingImage from 'assets/images/events-wedding.png';
import BirthdaysImage from 'assets/images/events-birthday.png';
import FamilyImage from 'assets/images/events-family.png';
import MemorialImage from 'assets/images/events-memorial.png';
import OtherImage from 'assets/images/events-other.png';
import { classnames } from 'helpers/utils';
import styles from './pick-tutorials.module.scss';

enum EventsEnum {
  vacation = 'vacation',
  wedding = 'wedding',
  birthdays = 'birthdays',
  familyReunion = 'familyReunion',
  memorial = 'memorial',
  other = 'other',
}

type EventContent = {
  name: string;
  image: string;
};

type Events = {
  [key in EventsEnum]: EventContent;
};

const events: Events = {
  [EventsEnum.vacation]: {
    name: 'Vacation',
    image: VacationImage,
  },
  [EventsEnum.wedding]: {
    name: 'Wedding',
    image: WeddingImage,
  },
  [EventsEnum.birthdays]: {
    name: 'Birthdays',
    image: BirthdaysImage,
  },
  [EventsEnum.familyReunion]: {
    name: 'Family reunion',
    image: FamilyImage,
  },
  [EventsEnum.memorial]: {
    name: 'Memorial',
    image: MemorialImage,
  },
  [EventsEnum.other]: {
    name: 'Other',
    image: OtherImage,
  },
};

type EventsModalProps = {
  handleSelectEvent: () => void;
};

const EventsModal: React.FC<EventsModalProps> = ({ handleSelectEvent }) => (
  <Modal contentClassName={styles.container} disableManualClosing>
    <div className={styles.content}>
      <div className="text__heading4__primary50">
        What event memories will go into this album?
      </div>
      <div className={classnames(styles.cards, styles.cards3columns)}>
        {Object.keys(events).map((tutorial) => (
          <button
            type="button"
            className={classnames(styles.card)}
            onClick={handleSelectEvent}
          >
            <img
              alt="illustration"
              src={events[tutorial as EventsEnum].image}
            />
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral30',
                styles.cardTitle,
              )}
            >
              {events[tutorial as EventsEnum].name}
            </div>
          </button>
        ))}
      </div>
    </div>
  </Modal>
);

export { EventsModal };
