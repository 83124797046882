/* eslint-disable max-len */
import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ButtonStyle } from 'common/button';
import {
  PaymentType,
  SubModificationType,
  SubscriptionSchemeId,
  SubscriptionStatus,
} from 'common/enums';
import { ModalWarning } from 'common/modal-warning';
import { appActions, AppContext } from 'context';
import { goToPage, RouteName } from 'routes';
import { UserController } from 'networking/controllers/user-controller';
import { dayjs } from 'helpers/dayjs';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { SubscriptionType } from 'models/subscription-type';
import { AddPaymentMethodModal } from 'common/add-payment-method-modal';
import { trackCustomEvent } from 'helpers/analytics';
import { ModalProcessingAlbumPayment } from './components/modal-processing-payment';
import { UpgradeConfirmSubscriptionModal } from './components/upgrade-confirm-subscription-modal';
import styles from './all-payment-process.module.scss';
import { ModifySubscriptionModal } from './components/modify-subscription-modal';

type AllPaymentProcessProps = {
  paymentType: PaymentType;
  title?: string;
  albumReceived?: AlbumType;
  confirmPaymentTextBody?: ReactNode | string;
  payButtonText?: string;
  successPaymentTextBody: string;
  successPaymentButtonText?: string;
  failedPaymentTextBody: string;
  creatingFreeTrial?: boolean;
  payAlbumWithFullDiscout?: boolean;
  isInsideVideoCall?: boolean;
  notifyProcessConfirmation: (
    tier?: SubscriptionSchemeId,
  ) => Promise<AlbumType | null>;
  cancelPaymentProcess: () => void;
  successPaymentProcess: (newTier: SubscriptionSchemeId) => void;
  failedPaymentProcess: () => void;
  notifyError: () => void;
  dataCy?: string;
};

const AllPaymentProcess: React.FC<AllPaymentProcessProps> = ({
  albumReceived = null,
  paymentType,
  title = '',
  confirmPaymentTextBody = '',
  payButtonText = '',
  successPaymentTextBody,
  successPaymentButtonText = 'Okay',
  failedPaymentTextBody,
  creatingFreeTrial = false,
  payAlbumWithFullDiscout = false,
  isInsideVideoCall = false,
  notifyProcessConfirmation,
  cancelPaymentProcess,
  successPaymentProcess,
  failedPaymentProcess,
  notifyError,
  dataCy,
}) => {
  const { state, dispatch } = useContext(AppContext);

  const [confirmCreateAlbumProcess, setConfirmCreateAlbumProcess] =
    useState(false);
  const [addPaymentMethodModal, setAddPaymentMethodModal] = useState(false);

  const [processingAlbumPaymentModal, setProcessingAlbumPaymentModal] =
    useState(false);
  const [showPaymentSuccesfulModal, setShowPaymentSuccesfulModal] =
    useState(false);
  const [showPaymentFailedModal, setShowPaymentFailedModal] = useState(false);

  const [discountedPrices, setDiscountedPrices] = useState<
    SubscriptionDiscountedPriceType[]
  >([]);

  const [
    showModifyAlbumSubscriptionModal,
    setShowModifyAlbumSubscriptionModal,
  ] = useState(false);
  const [
    showDowngradeConfirmSubscriptionModal,
    setShowDowngradeConfirmSubscriptionModal,
  ] = useState(false);

  const [
    showUpgradeConfirmSubscriptionModal,
    setShowUpgradeConfirmSubscriptionModal,
  ] = useState(false);

  const [album, setAlbum] = useState<AlbumType | null>(albumReceived);

  const [loading, setLoading] = useState<boolean>(false);

  const subscriptionSeleted = useRef<SubscriptionTypeType>(
    new SubscriptionType(),
  );
  const newTier = useRef<SubscriptionSchemeId | null>(null);

  const { data, subscriptionsScheme } = state;

  const getSubscriptionPrices = async () => {
    const response = await SubscriptionController.getSubscriptionPrices();
    setDiscountedPrices(response.prices);
  };

  const startProcess = () => {
    // Create free album OR create album with full discount (no payment method needed)
    if (creatingFreeTrial || payAlbumWithFullDiscout) {
      setConfirmCreateAlbumProcess(true);
      return;
    }

    // Not payment method associated
    if (!data.user.paymentMethods?.length) {
      trackCustomEvent('startAddPaymentMethod', { step: 'createAlbum' });
      setAddPaymentMethodModal(true);
      return;
    }

    // Create paid album
    if (paymentType === PaymentType.CreateNewAlbum) {
      setConfirmCreateAlbumProcess(true);
      return;
    }

    // Modify album subscription
    if (paymentType === PaymentType.ModifyAlbumSubscription) {
      setShowModifyAlbumSubscriptionModal(true);
    }
  };

  useEffect(() => {
    getSubscriptionPrices();
    startProcess();
  }, []);

  const handleUupdateUserData = async () => {
    const dataResponse = await UserController.me();
    dispatch({ type: appActions.USER_LOGGED, data: dataResponse });

    getSubscriptionPrices();
  };

  const handleConfirmOperation = async (
    isFreeTrial: boolean,
    subModificationType?: SubModificationType,
    newTierSelected?: SubscriptionSchemeId,
  ) => {
    trackCustomEvent('confirmAlbumPayment');
    setLoading(true);
    const albumResponse = await notifyProcessConfirmation(newTierSelected);
    setLoading(false);

    if (subModificationType === SubModificationType.downgrade) {
      setShowDowngradeConfirmSubscriptionModal(false);
    } else if (subModificationType === SubModificationType.upgrade) {
      setShowUpgradeConfirmSubscriptionModal(false);
    } else {
      setConfirmCreateAlbumProcess(false);
    }

    if (albumResponse) {
      setAlbum(albumResponse);

      if (
        isFreeTrial ||
        payAlbumWithFullDiscout ||
        subModificationType === SubModificationType.downgrade
      ) {
        const allData = await UserController.me();
        dispatch({ type: appActions.USER_LOGGED, data: allData });

        successPaymentProcess(albumResponse.subscriptionStatus);
      } else {
        setProcessingAlbumPaymentModal(true);
      }
    } else {
      notifyError();
    }
  };

  const showCreateFreeTrialAlbumModalComponent = () => (
    <ModalWarning
      title={title}
      content="This album will be a free trial with limited functionality. You can upgrade to a subscription later to get full access."
      successButtonText="Create album"
      successStyleButton={ButtonStyle.PrimaryFilled}
      closeFn={() => {
        cancelPaymentProcess();
        trackCustomEvent('cancelAlbumPayment');
      }}
      successFn={() => {
        handleConfirmOperation(true);
      }}
    />
  );

  const handleCloseProcessingPaymentModal = (
    subscriptionStatus: SubscriptionStatus,
  ) => {
    setProcessingAlbumPaymentModal(false);

    if (subscriptionStatus === SubscriptionStatus.subscriptionActive) {
      setShowPaymentSuccesfulModal(true);
    }

    if (subscriptionStatus === SubscriptionStatus.paymentFailed) {
      setShowPaymentFailedModal(true);
    }
  };

  const handleErrorProcessingPayment = () => {
    setProcessingAlbumPaymentModal(false);
    notifyError();
  };

  const showConfirmPayNewAlbumFullPriceModalComponent = () => (
    <ModalWarning
      loading={loading}
      title={title}
      content={confirmPaymentTextBody}
      successButtonText={payButtonText}
      successStyleButton={ButtonStyle.PrimaryFilled}
      closeFn={() => {
        cancelPaymentProcess();
        trackCustomEvent('cancelAlbumPayment');
      }}
      successFn={() => {
        handleConfirmOperation(false);
      }}
    />
  );

  const addPaymentMethodModalComponent = () => (
    <AddPaymentMethodModal
      showWarningNotPayMethod
      notifyClose={() => {
        setAddPaymentMethodModal(false);
        cancelPaymentProcess();
        trackCustomEvent('cancelAddPaymentMethod');
      }}
      notifySuccess={() => {
        setAddPaymentMethodModal(false);
        startProcess();
        trackCustomEvent('confirmAddPaymentMethod');
      }}
    />
  );

  const confirmCreateAlbumProcessComponent = () =>
    creatingFreeTrial
      ? showCreateFreeTrialAlbumModalComponent()
      : showConfirmPayNewAlbumFullPriceModalComponent();

  const handleChangeSubscriptionType = (
    subSelected: SubscriptionTypeType,
    subModificationType: SubModificationType,
    newTierSelected: SubscriptionSchemeId,
  ) => {
    subscriptionSeleted.current = subSelected;
    newTier.current = newTierSelected;

    setShowModifyAlbumSubscriptionModal(false);

    if (subModificationType === SubModificationType.downgrade) {
      setShowDowngradeConfirmSubscriptionModal(true);
    } else {
      setShowUpgradeConfirmSubscriptionModal(true);
    }
  };

  const showModifyAlbumSubscriptionModalComponent = () => (
    <ModifySubscriptionModal
      album={album!}
      onClose={() => cancelPaymentProcess()}
      notifyUpdateUserData={() => handleUupdateUserData()}
      discountedPrices={discountedPrices}
      isInsideVideoCall={isInsideVideoCall}
      onSuccess={(
        subSelected: SubscriptionTypeType,
        subModificationType: SubModificationType,
        newTierSelected: SubscriptionSchemeId,
      ) =>
        handleChangeSubscriptionType(
          subSelected,
          subModificationType,
          newTierSelected,
        )
      }
    />
  );

  const processingAlbumPaymentModalComponent = () => (
    <ModalProcessingAlbumPayment
      albumResponse={album!}
      title={title}
      disableManualClosing
      closeFn={(albResponse: AlbumType) => {
        newTier.current = albResponse.subscriptionType;
        handleCloseProcessingPaymentModal(albResponse.subscriptionStatus);
      }}
      errorFn={() => handleErrorProcessingPayment()}
    />
  );

  const paymentSuccesfulContentModal = () => (
    <p
      className="text__body__semi__bold__large__textNeutral40"
      data-cy={dataCy}
    >
      {successPaymentTextBody}
    </p>
  );

  const paymentFailedContentModal = () => (
    <div className={styles.failedPaymentContent}>
      <p className="text__body__semi__bold__large__textNeutral40">
        Your card was declined.
      </p>
      <p className="text__body__semi__bold__medium__textNeutral30">
        {failedPaymentTextBody}
      </p>
    </div>
  );

  const showPaymentSuccesfulModalComponent = () => (
    <ModalWarning
      title={title}
      disableManualClosing
      showCancelButton={false}
      content={paymentSuccesfulContentModal()}
      successButtonText={successPaymentButtonText}
      successStyleButton={ButtonStyle.PrimaryFilled}
      successFn={() => {
        setShowPaymentSuccesfulModal(false);
        successPaymentProcess(newTier.current!);
      }}
    />
  );

  const showPaymentFailedModalComponent = () => (
    <ModalWarning
      title={title}
      disableManualClosing
      content={paymentFailedContentModal()}
      successButtonText="Review payment method"
      successStyleButton={ButtonStyle.PrimaryFilled}
      successFn={() => {
        goToPage(RouteName.Account);
      }}
      closeFn={() => {
        setShowPaymentFailedModal(false);
        failedPaymentProcess();
      }}
    />
  );

  const downgradeSubContentModal = () => (
    <div className={styles.downgradeSubContentModal}>
      <p className="text__title2__textNeutral40">
        Confirm subscription downgrade
      </p>
      <p className="text__body__regular__small__textNeutral30">
        {`This downgrade will take effect on ${dayjs(
          album!.nextPaymentDate,
        ).format('MM/DD/YYYY')}, when the current subscription runs out.`}
      </p>
    </div>
  );

  const showDowngradeConfirmSubscriptionModalComponent = () => (
    <ModalWarning
      loading={loading}
      title="Modify subscription"
      content={downgradeSubContentModal()}
      successButtonText="Confirm downgrade"
      successStyleButton={ButtonStyle.PrimaryFilled}
      closeFn={() => cancelPaymentProcess()}
      successFn={() =>
        handleConfirmOperation(
          false,
          SubModificationType.downgrade,
          newTier.current!,
        )
      }
    />
  );

  const showUpgradeConfirmSubscriptionModalComponent = () => (
    <UpgradeConfirmSubscriptionModal
      footerLoading={loading}
      subscriptionsScheme={subscriptionsScheme!}
      album={album!}
      newSubscriptionSelected={subscriptionSeleted.current!}
      discountedPrices={discountedPrices}
      closeFn={() => cancelPaymentProcess()}
      successFn={() =>
        handleConfirmOperation(
          false,
          SubModificationType.upgrade,
          newTier.current!,
        )
      }
    />
  );

  return (
    <>
      {confirmCreateAlbumProcess && confirmCreateAlbumProcessComponent()}
      {addPaymentMethodModal && addPaymentMethodModalComponent()}

      {processingAlbumPaymentModal && processingAlbumPaymentModalComponent()}
      {showPaymentSuccesfulModal && showPaymentSuccesfulModalComponent()}
      {showPaymentFailedModal && showPaymentFailedModalComponent()}

      {showModifyAlbumSubscriptionModal &&
        showModifyAlbumSubscriptionModalComponent()}
      {showDowngradeConfirmSubscriptionModal &&
        showDowngradeConfirmSubscriptionModalComponent()}
      {showUpgradeConfirmSubscriptionModal &&
        showUpgradeConfirmSubscriptionModalComponent()}
    </>
  );
};

export { AllPaymentProcess };
