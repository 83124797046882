import React, { RefObject } from 'react';

class OnboardingMessage {
  show: boolean;

  title: string;

  message: string;

  itemRef: RefObject<any>;

  skippable: boolean;

  confirmationButton?: { text: string; action: () => void };

  cancelButton?: { text: string; action: () => void };

  constructor(params: OnboardingMessageType | null) {
    this.show = params?.show || false;
    this.title = params?.title || '';
    this.message = params?.message || '';
    this.itemRef = params?.itemRef || React.createRef();
    this.skippable = params?.skippable || false;
    this.confirmationButton = params?.confirmationButton || undefined;
    this.cancelButton = params?.cancelButton || undefined;
  }
}

export { OnboardingMessage };
