import { ApiService } from 'networking/api-service';
import { API_ROUTES } from 'networking/api-routes';
import { AlbumsSerializer } from 'networking/serializers/albums-serializer';
import { AlbumsPagination } from 'models/albumsPagination';
import { Clip } from 'models/clip';
import { Album } from 'models/album';
import { TemplateAndQuestionsSerializer } from 'networking/serializers/templates-and-questions-serializer';
import { TemplateAndQuestions } from 'models/template-and-questions';
import { ContributorSerializer } from 'networking/serializers/contributor-serializer';
import { SubscriptionSchemeId } from 'common/enums';
import { Contributor } from 'models/contributor';
import { ContributorInvitation } from 'models/contributor-invitation';
import { AlbumsQueryParamsSerialized } from 'networking/types/albums-query-params';
import { RecordingGuest } from 'models/recording-guest';
import { PaymentSerializer } from 'networking/serializers/payment-serializer';

class AlbumsController {
  static async getAlbums(queryParams: AlbumsQueryParamsSerialized) {
    const params = AlbumsSerializer.serializeAlbumsQueryParams(queryParams);

    const response = await ApiService.get<AlbumsPaginationRaw>(
      API_ROUTES.ALBUMS,
      params,
    );

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbums(
      response.data,
    );

    return new AlbumsPagination(deSerializedAlbums);
  }

  static async getAlbum(id: number) {
    const response = await ApiService.get<AlbumRaw>(
      API_ROUTES.ALBUM_DETAIL(id),
    );

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return new Album(deSerializedAlbums);
  }

  static async deleteClip(id: number) {
    const clipDeleted = await ApiService.delete<ClipRaw>(
      API_ROUTES.DELETE_CLIP(id),
    );

    const data = AlbumsSerializer.deSerializeClip(clipDeleted.data);

    return new Clip(data);
  }

  static async deleteAlbum(AlbumId: number) {
    await ApiService.delete<void>(API_ROUTES.DELETE_ALBUM(AlbumId));
  }

  static async createAlbum(newAlbum: AlbumNewRequestType) {
    const serializedNewAlbum = AlbumsSerializer.serializeNewAlbum(newAlbum);

    const allData = await ApiService.post<AlbumRaw>(
      API_ROUTES.ALBUMS,
      serializedNewAlbum,
    );
    const data = AlbumsSerializer.deSerializeAlbum(allData.data);

    return new Album(data);
  }

  static async editAlbumQuestions(albumId: number, data: AlbumEditRequestType) {
    const serializedNewAlbum = AlbumsSerializer.serializeAlbumEditRequest(data);

    await ApiService.put<AlbumEditRequestRaw>(
      API_ROUTES.ALBUM_EDIT(albumId),
      serializedNewAlbum,
    );
  }

  static async editAlbumModal(
    albumId: number,
    requestData: AlbumEditRequestType,
  ) {
    const serializedAlbumEditRequest =
      AlbumsSerializer.serializeAlbumEditRequest(requestData);

    await ApiService.put<void>(
      API_ROUTES.ALBUM_EDIT(albumId),
      serializedAlbumEditRequest,
    );
  }

  static async getDefaultQuestions() {
    const response = await ApiService.get<DefaultQuestionsRaw>(
      API_ROUTES.GET_DEFAULT_QUESTIONS,
    );

    const deserializedTemplatesAndQuestions =
      TemplateAndQuestionsSerializer.deserializeDefaultQuestions(response.data);

    return new TemplateAndQuestions(deserializedTemplatesAndQuestions);
  }

  static async getSharedAlbum(albumId: number) {
    const response = await ApiService.get<AlbumRaw>(
      API_ROUTES.ALBUM_DETAIL_SHARED(albumId),
    );

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return new Album(deSerializedAlbums);
  }

  static async getVideoCallAlbum(albumId: number, videoCallToken: string) {
    const response = await ApiService.get<AlbumRaw>(
      API_ROUTES.ALBUM_DETAIL_VIDEO_CALL(albumId, videoCallToken),
    );

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return new Album(deSerializedAlbums);
  }

  static async editClip(idClip: number, requestData: ClipEditRequestType) {
    const serializedClipEditRequest =
      AlbumsSerializer.serializeClipEditRequest(requestData);

    const response = await ApiService.put<ClipRaw>(
      API_ROUTES.EDIT_CLIP(idClip),
      serializedClipEditRequest,
    );

    const deSerializedClip = AlbumsSerializer.deSerializeClip(response.data);

    return new Clip(deSerializedClip);
  }

  static async shareClipByEmail({
    clipId,
    email,
  }: {
    clipId: number;
    email: string;
  }) {
    await ApiService.post(API_ROUTES.SHARE_CLIP_BY_EMAIL(clipId), { email });
  }

  static async destroyExtraClips(
    albumId: number,
    data: DestroyClipsRequestType,
  ) {
    const serializedDestroyClipsRequest =
      AlbumsSerializer.serializeDestroyClipsRequest(data);

    await ApiService.put(
      API_ROUTES.DESTROY_CLIPS(albumId),
      serializedDestroyClipsRequest,
    );
  }

  static async sendLinkToVideoCall({ id }: { id: number }) {
    await ApiService.post(API_ROUTES.SEND_LINK_TO_VIDEO_CALL(id));
  }

  static async requestAlbumDownload({
    id,
    includePrivateClips,
  }: {
    id: number;
    includePrivateClips: boolean;
  }) {
    return ApiService.post(API_ROUTES.REQUEST_ALBUM_DOWNLOAD(id), {
      include_private_clips: includePrivateClips,
    });
  }

  static async createClip(albumId: number, data: CreateClipRequestType) {
    const serializedCreateClipRequest =
      AlbumsSerializer.serializeCreateClipRequest(data);

    const response = await ApiService.post<ClipRaw>(
      API_ROUTES.CREATE_CLIP(albumId),
      serializedCreateClipRequest,
    );

    const deSerializedClip = AlbumsSerializer.deSerializeClip(response.data);

    return deSerializedClip;
  }

  static async modifyAlbumSubscription(
    albumId: number,
    tier?: SubscriptionSchemeId,
  ) {
    const response = await ApiService.put<AlbumRaw>(
      API_ROUTES.UPGRADE_ALBUM(albumId),
      { tier },
    );

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return new Album(deSerializedAlbums);
  }

  static async cancelSubscription(albumId: number) {
    await ApiService.post(API_ROUTES.CANCEL_SUBSCRIPTION(albumId));
  }

  static async addContributors(
    albumId: number,
    contributors: {
      name: string;
      email: string;
    }[],
  ) {
    const response = await ApiService.post<ContributorInvitationRaw>(
      API_ROUTES.ADD_CONTRIBUTOR(albumId),
      { contributors },
    );

    const deSerializedInvitation = ContributorSerializer.deSerializeInvitation(
      response.data,
    );

    return new ContributorInvitation(deSerializedInvitation);
  }

  static async removeContributor({
    albumId,
    invitationId,
  }: {
    albumId: number;
    invitationId: number;
  }) {
    await ApiService.delete<void>(
      API_ROUTES.REMOVE_CONTRIBUTOR(albumId, invitationId),
    );
  }

  static async leaveAlbum(albumId: number) {
    await ApiService.delete<void>(API_ROUTES.LEAVE_ALBUM(albumId));
  }

  static async resendAlbumInvitation(invitationId: number) {
    const response = await ApiService.put<ContributorRaw>(
      API_ROUTES.RESEND_ALBUM_INVITATION(invitationId),
    );

    const deSerializedContributor = AlbumsSerializer.deSerializeContributorInfo(
      response.data,
    );

    return new Contributor(deSerializedContributor);
  }

  static async inviteGuestsToRecord(
    albumId: number,
    guests: { name: string; email: string }[],
  ) {
    const response = await ApiService.post<RecordingGuestType>(
      API_ROUTES.INVITE_GUEST_TO_RECORD(albumId),
      { guests },
    );

    const deSerializedGuest = AlbumsSerializer.deSerializeGuest(response.data);

    return new RecordingGuest(deSerializedGuest);
  }

  static async getAlbumForGuest(id: number) {
    const response = await ApiService.get<AlbumRaw>(API_ROUTES.ALBUM_GUEST(id));

    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return new Album(deSerializedAlbums);
  }

  static async createClipByGuest(albumId: number, data: CreateClipRequestType) {
    const serializedCreateClipRequest =
      AlbumsSerializer.serializeCreateClipRequest(data);

    const response = await ApiService.post<ClipRaw>(
      API_ROUTES.CREATE_CLIP_GUEST(albumId),
      serializedCreateClipRequest,
    );

    const deSerializedClip = AlbumsSerializer.deSerializeClip(response.data);

    return deSerializedClip;
  }

  static async editClipByGuest(
    idClip: number,
    requestData: ClipEditRequestType,
  ) {
    const serializedClipEditRequest =
      AlbumsSerializer.serializeClipEditRequest(requestData);

    const response = await ApiService.put<ClipRaw>(
      API_ROUTES.EDIT_CLIP_GUEST(idClip),
      serializedClipEditRequest,
    );

    const deSerializedClip = AlbumsSerializer.deSerializeClip(response.data);

    return new Clip(deSerializedClip);
  }

  static async createReminder(albumId: number, data: ReminderRequestType) {
    const serializedReminderRequest =
      AlbumsSerializer.serializeReminderRequest(data);

    const response = await ApiService.post<ReminderResponseType>(
      API_ROUTES.CREATE_REMINDER(albumId),
      serializedReminderRequest,
    );
    const deSerializedReminder = AlbumsSerializer.deSerializeReminder(
      response.data,
    );

    return deSerializedReminder;
  }

  static async deleteReminder(albumId: number, reminderId: number) {
    await ApiService.delete(API_ROUTES.DELETE_REMINDER(albumId, reminderId));
  }

  static async editReminder(
    albumId: number,
    reminderId: number,
    data: ReminderRequestType,
  ) {
    const serializedReminderRequest =
      AlbumsSerializer.serializeReminderRequest(data);

    const response = await ApiService.put<ReminderResponseType>(
      API_ROUTES.EDIT_REMINDER(albumId, reminderId),
      serializedReminderRequest,
    );
    const deSerializedReminder = AlbumsSerializer.deSerializeReminder(
      response.data,
    );

    return deSerializedReminder;
  }

  static async sendSampleEmail(albumId: number) {
    await ApiService.post(API_ROUTES.SEND_SAMPLE_EMAIL(albumId));
  }

  static async updateAlbumQuestions(
    albumId: number,
    data: AlbumEditRequestType,
  ) {
    const serializedNewAlbum = AlbumsSerializer.serializeAlbumEditRequest(data);

    const response = await ApiService.put<AlbumRaw>(
      API_ROUTES.ALBUM_EDIT(albumId),
      serializedNewAlbum,
    );
    const deSerializedAlbums = AlbumsSerializer.deSerializeAlbum(response.data);

    return deSerializedAlbums;
  }

  static async buyClips(albumId: number, data: BuyClipsRaw) {
    const response = await ApiService.post<PaymentInfoRaw>(
      API_ROUTES.BUY_CLIPS(albumId),
      data,
    );

    const deSerializedPayment = PaymentSerializer.deSerializePaymentInfo(
      response.data,
    );

    return deSerializedPayment;
  }

  static async buyExtraCallTime(albumId: number, extraCallTime: number) {
    const serializedBuyExtraCallTime =
      AlbumsSerializer.serializeBuyExtraCallTime(extraCallTime);
    const response = await ApiService.post<PaymentInfoRaw>(
      API_ROUTES.BUY_EXTRA_CALL_TIME(albumId),
      serializedBuyExtraCallTime,
    );
    const deSerializedPaymentInfo = PaymentSerializer.deSerializePaymentInfo(
      response.data,
    );
    return deSerializedPaymentInfo;
  }

  static async getPayment(paymentId: number) {
    const response = await ApiService.get<PaymentInfoRaw>(
      API_ROUTES.GET_PAYMENT(paymentId),
    );

    const deSerializedPayment = PaymentSerializer.deSerializePaymentInfo(
      response.data,
    );

    return deSerializedPayment;
  }
}

export { AlbumsController };
