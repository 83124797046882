import { Spinner } from 'common/spinner';
import { appActions, AppContext } from 'context';
import { getQueryParam, hasAlbumAccess } from 'helpers/utils';
import { UserController } from 'networking/controllers/user-controller';
import React, { useContext, useEffect, useState } from 'react';
import { goToPage, RouteName } from 'routes';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { LocalStorageApi } from 'helpers/local-storage';
import { getRouteFor } from 'routes/route-helpers';
import { errorsCode } from 'config/constants';
import { ModalReminderAccessDenied } from 'common/modal-reminder-access';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import styles from './album-reminder.module.scss';

enum SignUpPage {
  inheritGuard = 'inheritGuard',
  default = 'default',
}

const AlbumReminder: React.FC = () => {
  const [fetching, setFetching] = useState(true);
  const [showNotAccessModal, setShowNotAccessModal] = useState(false);

  const { dispatch, state } = useContext(AppContext);
  const email = getQueryParam('email');

  const authenticate = async () => {
    const albumIdParam = getQueryParam('albumId');
    if (albumIdParam) {
      const albumUrl = getRouteFor(
        RouteName.AlbumDetail,
        {
          id: albumIdParam,
        },
        { tab: getQueryParam('tab') },
      );
      LocalStorageApi.set('previous-url', albumUrl);
    }

    try {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });

      if (albumIdParam) {
        try {
          const albumId = parseInt(albumIdParam, 10);
          const album = await AlbumsController.getAlbum(albumId);
          if (hasAlbumAccess(data.user, album)) {
            goToPage(
              RouteName.AlbumDetail,
              { id: album.id },
              { tab: getQueryParam('tab') },
            );
          } else {
            setShowNotAccessModal(true);
          }
        } catch (error: any) {
          const notAuthorizedError = error.errors.find((e: any) =>
            [errorsCode.forbidden, errorsCode.notAuthorized].includes(
              e.errorCode,
            ),
          );
          if (notAuthorizedError) {
            setShowNotAccessModal(true);
          } else {
            goToPage(RouteName.NotFound);
          }
        }
      } else {
        goToPage(RouteName.NotFound);
      }

      setFetching(false);
    } catch (e) {
      dispatch({ type: appActions.SESSION_EXPIRED });

      if (email) {
        const activated = await UserController.isUserActive(email);

        const token = getQueryParam('token') ?? '';
        const name = getQueryParam('name') ?? '';
        const signUnpPage = getQueryParam('signUpPage') ?? SignUpPage.default;
        if (activated) {
          goToPage(RouteName.SignIn, {}, { email });
        } else {
          const signUpRoute =
            signUnpPage === SignUpPage.inheritGuard
              ? RouteName.SignUpIgn
              : RouteName.SignUp;
          goToPage(signUpRoute, {}, { email, token, name });
        }
      } else {
        goToPage(RouteName.SignIn);
      }
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  if (fetching) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className={globalStyles.loggedMainContainer}>
      {showNotAccessModal && email && (
        <ModalReminderAccessDenied
          emailMatches={email !== state.data.user.email}
          email={email}
          onClose={() => {
            setShowNotAccessModal(false);
            goToPage(RouteName.Albums);
          }}
        />
      )}
    </div>
  );
};

export { AlbumReminder };
