import { Button, ButtonStyle } from 'common/button/button';
import { Modal } from 'common/modal/modal';
import { useContext } from 'react';
import { captureEvent } from 'posthog';
import { UserController } from 'networking/controllers/user-controller';
import { goToPage, RouteName } from 'routes';
import { appActions, AppContext } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { trackCustomEvent } from 'helpers/analytics';
import styles from './modal-temporary-user.module.scss';

type ModalTemporaryUserType = {
  onClose: () => void;
};

const ModalTemporaryUser = ({ onClose }: ModalTemporaryUserType) => {
  const { dispatch } = useContext(AppContext);

  const logOutUser = async () => {
    captureEvent('logout');
    trackCustomEvent('logOut');
    try {
      await UserController.signOutUser();
      goToPage(RouteName.SignIn);
      dispatch({ type: appActions.SESSION_EXPIRED });
    } catch (e) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Oh no',
          message: 'Something went wrong. Please try again.',
          type: NotificationType.Error,
        }),
      });
    }
  };

  return (
    <Modal
      title="Your recordings will be deleted"
      onClose={onClose}
      contentClassName={styles.modalContent}
      className={styles.modal}
    >
      <div className="text__body__regular__medium__textNeutral40">
        <span className="text__body__semi__bold__medium__textNeutral40">
          If you leave without signing up your recordings will be deleted.
        </span>{' '}
        Don’t worry, your first album is{' '}
        <span className="text__body__semi__bold__medium__textNeutral40">
          free
        </span>{' '}
        for a month, and signing up only takes 2 minutes!
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={logOutUser} buttonStyle={ButtonStyle.RedGhost}>
          Delete my recordings
        </Button>
        <Button onClick={() => goToPage(RouteName.SignUp)}>Sign Up</Button>
      </div>
    </Modal>
  );
};

export { ModalTemporaryUser };
