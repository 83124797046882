import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import React, { useContext } from 'react';
import { OnboardingMessage } from 'common/onboarding-message';
import { appActions, AppContext } from 'context';
import { UserController } from 'networking/controllers/user-controller';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { OnboardingMessage as OnboardingMessageObject } from 'models/onboardingMessage';
import { PickTutorials } from './components/pick-tutorials/pick-tutorials';
import { ProductTourCarousel } from './components/product-tour-carousel/product-tour-carousel';

type OnboardingPhases = {
  [key in OnboardingPhase]: React.ReactElement;
};

type OnboardingProcessType = {};

const OnboardingProcess: React.FC<OnboardingProcessType> = () => {
  const { state: generalState, dispatch: generalDispatch } =
    useContext(AppContext);
  const {
    state: { onboardingMessage, onboardingPhase },
    dispatch,
  } = useContext(OnboardingContext);

  const handleSkipTutorial = async () => {
    try {
      const userResponse = await UserController.update({
        ...generalState.data.user,
        firstVisitDisplayed: true,
      });
      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: userResponse },
      });
    } catch (e) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Skip tutorial',
          message: "Tutorial couldn't be skipped",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const onboardingPhases: OnboardingPhases = {
    [OnboardingPhase.productTour]: (
      <ProductTourCarousel
        handleClose={() => {
          dispatch({ type: onboardingActions.SET_ONBOARDING_PHASE });
        }}
        handleSkipTutorial={handleSkipTutorial}
      />
    ),
    [OnboardingPhase.pickTutorial]: (
      <PickTutorials handleSkipTutorial={handleSkipTutorial} />
    ),
  };

  return (
    <>
      {onboardingMessage?.show && (
        <OnboardingMessage
          itemRef={onboardingMessage.itemRef}
          title={onboardingMessage.title}
          message={onboardingMessage.message}
          skippable={onboardingMessage.skippable}
          confirmationButton={onboardingMessage.confirmationButton}
          cancelButton={onboardingMessage.cancelButton}
          handleSkipTutorial={handleSkipTutorial}
          handleClose={() =>
            dispatch({
              type: onboardingActions.ONBOARDING_MESSAGE,
              onboardingMessage: new OnboardingMessageObject(null),
            })
          }
        />
      )}
      {onboardingPhase && onboardingPhases[onboardingPhase]}
    </>
  );
};

export { OnboardingProcess };
