import React, { useContext, useEffect, useRef, useState } from 'react';
import { HelpIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { appActions, AppContext } from 'context';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import { UserController } from 'networking/controllers/user-controller';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { faqLinks, tutorialLinks } from 'config/constants';
import { useClickOutside } from 'hooks/click-outside';
import styles from './help-button.module.scss';
import {
  HelpButtonMessage,
  HelpButtonMessageType,
} from './components/help-button-message';

type HelpButtonMessages = {
  [key in HelpInfoSections]: HelpButtonMessageType;
};

const HelpButton: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [showMessage, setShowMessage] = useState(false);
  const helpButtonRef = useRef<HTMLDivElement>(null);
  useClickOutside(helpButtonRef, () => setShowMessage(false));

  const messages: HelpButtonMessages = {
    [HelpInfoSections.albums_empty]: {
      title: 'Your albums',
      content: (
        <>
          <p>This is where you can find all your albums and create new ones.</p>
          <br />
          <span className="text__body__semi__bold__medium__primary50">
            Click &apos;new album&apos; to get started.
          </span>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Introduction',
          url: tutorialLinks.introduction,
        },
        {
          title: 'Tutorial: Create an album',
          url: tutorialLinks.createAlbum,
        },
        {
          title: 'What is Generational Story?',
          url: faqLinks.whatIs,
        },
        {
          title: 'What are the primary features of Generational Story?',
          url: faqLinks.primaryFeatures,
        },
        {
          title: 'What types of albums are available?',
          url: faqLinks.albumTypes,
        },
      ],
    },
    [HelpInfoSections.albums_filled]: {
      title: 'Your albums',
      content: (
        <>
          <p>This is where you can find all your albums and create new ones.</p>
          <br />
          <span className="text__body__semi__bold__medium__primary50">
            Open your album to get started or create a new one.
          </span>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Introduction',
          url: tutorialLinks.introduction,
        },
        {
          title: 'Tutorial: Create an album',
          url: tutorialLinks.createAlbum,
        },
        {
          title: 'What is Generational Story?',
          url: faqLinks.whatIs,
        },
        {
          title: 'What are the primary features of Generational Story?',
          url: faqLinks.primaryFeatures,
        },
        {
          title: 'What types of albums are available?',
          url: faqLinks.albumTypes,
        },
      ],
    },
    [HelpInfoSections.add_prompts_empty]: {
      title: 'Add prompts',
      content: (
        <>
          <p>
            Try adding prompts - themed questions and topics to help plan out
            your album. You or a collaborator can add content labeled by prompt.
          </p>
          <br />
          <p>
            <span className="text__body__semi__bold__medium__primary50">
              Your prompt queue is empty.
            </span>{' '}
            Want help to plan an interview? We can{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.pickPrompts}
            >
              help you pick prompts
            </button>
            , or you can{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.typePrompts}
            >
              type your own
            </button>{' '}
            or{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.chooseTemplate}
            >
              use your own personalized templates
            </button>
            .
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Pre-plan your interview',
          url: tutorialLinks.planInterview,
        },
        {
          title: 'Tutorial: Create a template',
          url: tutorialLinks.createATemplate,
        },
        {
          title: 'How can I prepare for the interview?',
          url: faqLinks.prepareInterview,
        },
        {
          title: 'How will I know what to ask during an interview?',
          url: faqLinks.whatToAsk,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.add_prompts_filled]: {
      title: 'Add prompts',
      content: (
        <>
          <p>
            Try adding prompts - themed questions and topics to help plan out
            your album. You or a collaborator can add content labeled by prompt.
          </p>
          <br />
          <p>
            <span className="text__body__semi__bold__medium__primary50">
              You have some prompts in your queue.
            </span>{' '}
            You can add more prompts or go ahead and start{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.goToRecord}
            >
              recording
            </button>{' '}
            and{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.goToUpload}
            >
              uploading
            </button>{' '}
            responses.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Pre-plan your interview',
          url: tutorialLinks.planInterview,
        },
        {
          title: 'Tutorial: Create a template',
          url: tutorialLinks.createATemplate,
        },
        {
          title: 'How can I prepare for the interview?',
          url: faqLinks.prepareInterview,
        },
        {
          title: 'How will I know what to ask during an interview?',
          url: faqLinks.whatToAsk,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.record_empty]: {
      title: 'Recording',
      content: (
        <>
          <p>Here you can record videos.</p>
          <br />
          <p>
            If you pre-plan your album by{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.goToAddPrompts}
            >
              adding prompts
            </button>
            , they will show up on the left side column for you to record
            responses.
          </p>
          <br />
          <p>
            Click{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.inviteCollaborators}
            >
              invite collaborators
            </button>{' '}
            if you&apos;d like to invite someone else to add content to your
            album.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Record on your browser',
          url: tutorialLinks.selfRecordOnBrowser,
        },
        {
          title: 'Tutorial: Add photos, descriptions & set clips to audio only',
          url: tutorialLinks.addClipDetails,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
        {
          title: "What does 'custom recording' mean and when is it used?",
          url: faqLinks.customRecording,
        },
      ],
    },
    [HelpInfoSections.record_filled]: {
      title: 'Recording',
      content: (
        <>
          <p>
            Here you can record videos. Select a prompt and record your answer,
            or go off-script for a custom recording.
          </p>
          <br />
          <p>
            You can also{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.inviteCollaborators}
            >
              invite collaborators
            </button>{' '}
            if you want someone else to make recordings.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Record on your browser',
          url: tutorialLinks.selfRecordOnBrowser,
        },
        {
          title: 'Tutorial: Add photos, descriptions & set clips to audio only',
          url: tutorialLinks.addClipDetails,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
        {
          title: "What does 'custom recording' mean and when is it used?",
          url: faqLinks.customRecording,
        },
      ],
    },
    [HelpInfoSections.upload_empty]: {
      title: 'Uploading',
      content: (
        <>
          <p>
            This page allows you to upload custom recordings that you can later
            label.
          </p>
          <br />
          <p>
            If you{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.goToAddPrompts}
            >
              add prompts to your album
            </button>
            , they will show up on the left side column for you to upload
            responses.
          </p>
          <br />
          <p>
            You can also{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.inviteCollaborators}
            >
              invite collaborators
            </button>{' '}
            if you want someone else to make uploads.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Upload videos',
          url: tutorialLinks.uploadVideos,
        },
        {
          title: 'How many clips may be stored in an album?',
          url: faqLinks.clipsAmount,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.upload_filled]: {
      title: 'Uploading',
      content: (
        <>
          <p>
            This page allows you to upload video responses to the prompts you
            added to your album, or do custom uploads that you can later label.
          </p>
          <br />
          <p>
            You can also{' '}
            <button
              type="button"
              className="text__body__link__medium__primary50"
              onClick={state.helpLinks?.inviteCollaborators}
            >
              invite collaborators
            </button>{' '}
            if you want someone else to make uploads.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Upload videos',
          url: tutorialLinks.uploadVideos,
        },
        {
          title: 'How many clips may be stored in an album?',
          url: faqLinks.clipsAmount,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.video_call]: {
      title: 'Recording on video call',
      content: (
        <>
          <p>
            You can record answers to your prompts, or make custom recordings,
            while on a video call with a contributor or guest.
          </p>
          <br />
          <p>
            Join as interviewer to lead the conversation, or join as respondent
            to answer the questions.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Record over video call',
          url: tutorialLinks.recordOnVideoConference,
        },
        {
          title: "What does 'custom recording' mean and when is it used?",
          url: faqLinks.customRecording,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.album_settings]: {
      title: 'Album settings',
      content: (
        <>
          <p>
            Review your subscription type, cost, and renewal date, usage, any
            discounts you have applied, and who the album owner is.
          </p>
          <br />
          <p>
            Consider adding contributors and setting up email reminders to
            periodically add to your album.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Set up email reminders',
          url: tutorialLinks.setUpReminders,
        },
        {
          title: 'Tutorial: Add contributors',
          url: tutorialLinks.addContributors,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
        {
          title: 'What types of albums are available?',
          url: faqLinks.albumTypes,
        },
      ],
    },
    [HelpInfoSections.album_clips]: {
      title: 'Clips',
      content: (
        <>
          <p>
            Find, edit and share your content, attach images, and control your
            set your privacy settings.
          </p>
          <br />
          <p>
            Clips with the designation of private may not be shared even if you
            share the entire album, these clips will not appear to the viewer
            from the link.
          </p>
        </>
      ),
      links: [
        {
          title:
            'Tutorial: Add photos, descriptions, and set clips to audio only',
          url: tutorialLinks.addClipDetails,
        },
        {
          title: 'Tutorial: Connect a QR label to a Generational Story album',
          url: tutorialLinks.linkQRCode,
        },
        {
          title: 'Tutorial: Sharing and privacy settings',
          url: tutorialLinks.sharingAndPrivacySettings,
        },
        {
          title: 'Tutorial: Download an album or clip',
          url: tutorialLinks.downloadAlbumOrClip,
        },
        {
          title: 'How many clips may be stored in an album?',
          url: faqLinks.clipsAmount,
        },
      ],
    },
    [HelpInfoSections.album_clips_empty]: {
      title: 'Clips',
      content: (
        <>
          <p>
            Find, edit and share your content, attach images, and control your
            set your privacy settings.
          </p>
          <br />
          <p>
            Clips with the designation of private may not be shared even if you
            share the entire album, these clips will not appear to the viewer
            from the link.
          </p>
          <br />
          <p className="text__body__semi__bold__medium__primary50">
            Your album is empty. Uploaded or recorded clips will appear here.
          </p>
        </>
      ),
      links: [
        {
          title:
            'Tutorial: Add photos, descriptions, and set clips to audio only',
          url: tutorialLinks.addClipDetails,
        },
        {
          title: 'Tutorial: Connect a QR label to a Generational Story album',
          url: tutorialLinks.linkQRCode,
        },
        {
          title: 'Tutorial: Sharing and privacy settings',
          url: tutorialLinks.sharingAndPrivacySettings,
        },
        {
          title: 'Tutorial: Download an album or clip',
          url: tutorialLinks.downloadAlbumOrClip,
        },
        {
          title: 'How many clips may be stored in an album?',
          url: faqLinks.clipsAmount,
        },
      ],
    },
    [HelpInfoSections.account_settings]: {
      title: 'Settings',
      content: (
        <p>
          Go to account to see and edit your personal information, albums and
          subscription types, discounts and payment method on file.
        </p>
      ),
      links: [
        {
          title: 'What are legacy names and emails used for?',
          url: faqLinks.legacyName,
        },
        {
          title: 'Can I use my account across multiple devices?',
          url: faqLinks.multipleDevices,
        },
        {
          title: 'Deletion Policy',
          url: faqLinks.deletionPolicy,
        },
        {
          title: 'What types of albums are available?',
          url: faqLinks.albumTypes,
        },
      ],
    },
    [HelpInfoSections.contributors]: {
      title: 'Contributors',
      content: (
        <>
          <p>
            See the list of all the contributors you have invited and which
            albums they are associated with.
          </p>
          <br />
          <p className="text__body__semi__bold__medium__primary50">
            You can invite new contributors from the &apos;Record answer&apos;
            or &apos;settings&apos; tabs inside your albums.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Add contributors',
          url: tutorialLinks.addContributors,
        },
        {
          title: 'What are contributors and guests?',
          url: faqLinks.contributorsAndGuests,
        },
      ],
    },
    [HelpInfoSections.templates_empty]: {
      title: 'Templates',
      content: (
        <>
          <p>
            By creating a template, you can reuse your personal prompt list
            across multiple albums.
          </p>
          <br />
          <p className="text__body__semi__bold__medium__primary50">
            Create a new template to get started.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Create a template',
          url: tutorialLinks.createATemplate,
        },
        {
          title: 'Tutorial: Pre-plan your interview',
          url: tutorialLinks.planInterview,
        },
      ],
    },
    [HelpInfoSections.templates_filled]: {
      title: 'Templates',
      content: (
        <>
          <p>
            By creating a template, you can reuse your personal prompt list
            across multiple albums.
          </p>
          <br />
          <p className="text__body__semi__bold__medium__primary50">
            Add your templates to your albums inside the album&apos;s &apos;add
            prompts&apos; tab.
          </p>
        </>
      ),
      links: [
        {
          title: 'Tutorial: Create a template',
          url: tutorialLinks.createATemplate,
        },
        {
          title: 'Tutorial: Pre-plan your interview',
          url: tutorialLinks.planInterview,
        },
      ],
    },
    [HelpInfoSections.store]: {
      title: 'Store',
      content: (
        <p>
          We offer a variety of QR codes to adhere to almost any surface. Make
          sure your memories are accessible for generations by applying QR
          labels to your keepsakes.
        </p>
      ),
      links: [
        {
          title: 'Tutorial: Connect a QR label to a Generational Story album',
          url: tutorialLinks.linkQRCode,
        },
        {
          title: 'What advantages are there in using a QR label?',
          url: faqLinks.qrCodesAdvantages,
        },
      ],
    },
  };

  const turnBackTutorialOn = async () => {
    try {
      const userResponse = await UserController.update({
        ...state.data.user,
        firstVisitDisplayed: false,
      });
      dispatch({
        type: appActions.USER_LOGGED,
        data: { user: userResponse },
      });
    } catch (e) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Skip tutorial',
          message: "Tutorial couldn't be skipped",
          type: NotificationType.Error,
        }),
      });
    }
  };

  useEffect(() => {
    if (showMessage) {
      setShowMessage(false);
    }
  }, [state.helpSection]);

  return (
    <div ref={helpButtonRef} className={styles.container}>
      {showMessage && state.helpSection && (
        <HelpButtonMessage
          message={messages[state.helpSection]}
          handleCloseMessage={() => {
            setShowMessage(false);
          }}
          handleClickActionButton={turnBackTutorialOn}
        />
      )}
      <Button
        buttonSize={ButtonSize.Large}
        buttonStyle={ButtonStyle.PrimaryStroke}
        className={classnames(styles.button)}
        onClick={() => setShowMessage((prevState) => !prevState)}
      >
        <HelpIcon />
        Help
      </Button>
    </div>
  );
};
export { HelpButton };
