import React, { ChangeEvent, useState } from 'react';
import { Line } from 'common/line';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { goToPage, RouteName } from 'routes';
import { Notification } from 'common/notification';
import { UserController } from 'networking/controllers/user-controller';
import { Input } from 'common/input';
import { ChevronIcon } from 'assets/icons';
import { Spinner } from 'common/spinner';
import {
  classnames,
  isEmailValid,
  noEmptyFields,
  noErrors,
} from 'helpers/utils';
import { captureEvent } from 'posthog';
import { trackCustomEvent } from 'helpers/analytics';
import styles from './forgot-password-step.module.scss';

const mandatoryFields: MandatoryFieldsType = {
  email: '',
};

type ForgotPasswordStep1Props = {
  notifSuccess: () => void;
  email: string;
  setEmail: (email: string) => void;
};

const ForgotPasswordStep1: React.FC<ForgotPasswordStep1Props> = ({
  notifSuccess,
  email,
  setEmail,
}) => {
  const [errors, setErrors] = useState<MandatoryFieldsType>(mandatoryFields);
  const [showError, setShowError] = useState(false);
  const [fetching, setFetching] = useState(false);

  const validateEmail = (value: string) => {
    let emailMessage = '';

    emailMessage = value.trim() === '' ? "email shouldn't be empty" : '';
    setErrors((prevState) => ({
      ...prevState,
      email: emailMessage.toUpperCase(),
    }));

    if (emailMessage) return;

    emailMessage = !isEmailValid(value) ? 'invalid email' : '';
    setErrors((prevState) => ({
      ...prevState,
      email: emailMessage.toUpperCase(),
    }));
  };

  const handleSubmit = async () => {
    validateEmail(email);

    if (!noErrors(errors)) {
      return;
    }

    setFetching(true);

    captureEvent('sendPasswordResetEmail');
    trackCustomEvent('sendPasswordResetEmail');

    try {
      await UserController.forgotPassword(email);
      notifSuccess();
    } catch (e) {
      setShowError(true);
    } finally {
      setFetching(false);
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const showContentView = () => (
    <>
      <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
        Forgot password
      </div>
      <div
        className={classnames(
          'text__body__regular__small__textNeutral30',
          'my-7',
        )}
      >
        Enter the email associated with your account, and we will send you
        instructions to reset your password.
      </div>

      <div>
        <Input
          id="email"
          name="email"
          label="Email"
          placeholder="email@example.com"
          value={email}
          type="email"
          errorMessage={errors.email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
          onBlur={(e) => validateEmail(e.target.value)}
        />
      </div>
      <Button
        className={classnames(
          styles.sendButton,
          fetching ? 'noClick fixSizeFetching' : '',
        )}
        onClick={() => handleSubmit()}
        buttonSize={ButtonSize.Medium}
        buttonType={ButtonType.Submit}
        disabled={!noEmptyFields({ email })}
      >
        {fetching ? (
          <Spinner color="white" className="spinner-inside-button" />
        ) : (
          <> Send instructions </>
        )}
      </Button>
      <Line />
      <div className={classnames(styles.footerForgotReset)}>
        <span className={styles.chevronIcon}>
          <ChevronIcon />
        </span>

        <Button
          buttonStyle={ButtonStyle.PrimaryGhost}
          buttonSize={ButtonSize.Small}
          onClick={() => goToPage(RouteName.SignIn)}
        >
          Back to log in
        </Button>
      </div>
    </>
  );

  return (
    <>
      {showError && showErrorNotification()}
      {showContentView()}
    </>
  );
};

export { ForgotPasswordStep1 };
