import React, { useState } from 'react';
import { ModalChooseGuestType } from './components/modal-choose-guest-type';
import { ModalInviteUsers } from './components/modal-invite-users';
import { GuestType } from './components/modal-invite-users/modal-invite-users';
import { ModalInviteConfirmation } from './components/modal-invite-confirmation';

type InviteToRecordProcessProps = {
  handleClose: () => void;
  handleFinishProcess?: () => void;
  initialStep?: InvitationStep;
};

export enum InvitationStep {
  chooseGuestType = 'chooseGuestType',
  inviteContributors = 'inviteContributors',
  inviteContributorsConfirmation = 'inviteContributorsConfirmation',
  inviteGuests = 'inviteGuests',
  inviteGuestsConfirmation = 'inviteGuestsConfirmation',
}

const InviteToRecordProcess: React.FC<InviteToRecordProcessProps> = ({
  handleClose,
  initialStep,
  handleFinishProcess,
}) => {
  const [step, setStep] = useState(
    initialStep ?? InvitationStep.chooseGuestType,
  );

  const handleBack =
    initialStep === InvitationStep.chooseGuestType || !initialStep
      ? () => setStep(InvitationStep.chooseGuestType)
      : undefined;

  if (step === InvitationStep.inviteContributors) {
    return (
      <ModalInviteUsers
        userType={GuestType.contributor}
        handleClose={handleClose}
        handleBack={handleBack}
        handleConfirm={() =>
          setStep(InvitationStep.inviteContributorsConfirmation)
        }
      />
    );
  }
  if (step === InvitationStep.inviteContributorsConfirmation) {
    return (
      <ModalInviteConfirmation
        userType={GuestType.contributor}
        onClickInviteGuests={() => setStep(InvitationStep.inviteGuests)}
        onClickInviteContributors={() =>
          setStep(InvitationStep.inviteContributors)
        }
        handleClose={handleClose}
        handleFinishProcess={handleFinishProcess}
      />
    );
  }
  if (step === InvitationStep.inviteGuests) {
    return (
      <ModalInviteUsers
        userType={GuestType.guest}
        handleClose={handleClose}
        handleBack={handleBack}
        handleConfirm={() => setStep(InvitationStep.inviteGuestsConfirmation)}
      />
    );
  }
  if (step === InvitationStep.inviteGuestsConfirmation) {
    return (
      <ModalInviteConfirmation
        userType={GuestType.guest}
        onClickInviteGuests={() => setStep(InvitationStep.inviteGuests)}
        onClickInviteContributors={() =>
          setStep(InvitationStep.inviteContributors)
        }
        handleClose={handleClose}
        handleFinishProcess={handleFinishProcess}
      />
    );
  }

  return (
    <ModalChooseGuestType
      onClickInviteGuests={() => setStep(InvitationStep.inviteGuests)}
      onClickInviteContributors={() =>
        setStep(InvitationStep.inviteContributors)
      }
      handleClose={handleClose}
    />
  );
};

export { InviteToRecordProcess };
