import { Modal } from 'common/modal';
import React, { useEffect } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './modal-invite-confirmation.module.scss';
import { GuestType } from '../modal-invite-users/modal-invite-users';

type ModalInviteConfirmationProps = {
  onClickInviteGuests: () => void;
  onClickInviteContributors: () => void;
  handleClose: () => void;
  handleFinishProcess?: () => void;
  userType: GuestType;
};

const ModalInviteConfirmation: React.FC<ModalInviteConfirmationProps> = ({
  onClickInviteGuests,
  onClickInviteContributors,
  handleClose,
  handleFinishProcess,
  userType,
}) => {
  useEffect(
    () => () => {
      handleFinishProcess?.();
    },
    [],
  );

  return (
    <Modal contentClassName={styles.container} onClose={handleClose}>
      <div className={styles.content}>
        <div className="text__heading4__primary50">
          Your {userType}(s) have been invited!
        </div>
        <p className="text__body__regular__medium__textNeutral30">
          Do you want to send more invites?
        </p>
        <div className={styles.buttons}>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={onClickInviteGuests}
          >
            Invite guest(s)
          </Button>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={onClickInviteContributors}
          >
            Invite contributor(s)
          </Button>
          <Button buttonSize={ButtonSize.Large} onClick={handleClose}>
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ModalInviteConfirmation };
