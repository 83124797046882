import React, { ChangeEvent, useState } from 'react';

import { PlusIcon, InfoIcon } from 'assets/icons';
import { Modal } from 'common/modal';
import { HelperText } from 'common/helper-text';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Input } from 'common/input';
import {
  classnames,
  isEditingAlbum,
  isEditingMyTemplates,
  noErrors,
} from 'helpers/utils';
import { questionsMaxLength } from 'config/constants';
import { QuestionsManagmentViewType } from 'common/enums';
import styles from './custom-questions-modal.module.scss';

type CustomQuestionsModalProps = {
  questionsManagmentView: QuestionsManagmentViewType;
  onAddQuestions: Function;
  onClose: Function;
  remainingQuestions: number;
};

const CustomQuestionsModal: React.FC<CustomQuestionsModalProps> = ({
  questionsManagmentView,
  onAddQuestions,
  onClose,
  remainingQuestions,
}) => {
  const [auxId, setAuxId] = useState(1);
  const [newQuestions, setNewQuestions] = useState<QuestionType[]>([
    {
      name: '',
      auxId: `question-${auxId}`,
    },
  ]);
  const [mandatoryQuestion] = newQuestions;
  const mandatoryField = { [mandatoryQuestion.auxId!]: '' };

  const [errors, setErrors] = useState<MandatoryFieldsType>(mandatoryField);
  const [currentRemaining, setCurrentRemaining] = useState(
    remainingQuestions - 1,
  );

  const canAddNewQuestion = () =>
    isEditingAlbum(questionsManagmentView) ||
    (isEditingMyTemplates(questionsManagmentView) && currentRemaining > 0);

  const cleanEmptyQuestions = () => {
    const finalQuestions = newQuestions.filter((q) => q.name !== '');
    return finalQuestions;
  };

  const validateQuestion = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    if (value.length >= questionsMaxLength) {
      setErrors((prevState) => ({
        ...prevState,
        [id]: `max. ${questionsMaxLength} characters`.toUpperCase(),
      }));
    } else {
      setErrors((prevState) => ({
        ...prevState,
        [id]: '',
      }));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    validateQuestion(e);
    const { id, value } = e.target;
    if (newQuestions.some((q) => q.auxId === id)) {
      setNewQuestions((prevState: QuestionType[]) =>
        prevState.map((q) => {
          if (q.auxId === id) {
            return { ...q, name: value };
          }
          return q;
        }),
      );
    }
  };

  const handleTypeAnotherQuestion = () => {
    setCurrentRemaining((prevState) => prevState - 1);
    setNewQuestions((prevState) => [
      ...prevState,
      { auxId: `question-${auxId + 1}`, name: '' },
    ]);
    setAuxId(auxId + 1);
  };

  const handleAddQuestions = () => {
    onAddQuestions(cleanEmptyQuestions());
    onClose();
  };

  const isFirstQuestionCreated = () => {
    const firstQuestion = newQuestions.findIndex(
      (q) => q.id === mandatoryQuestion.id,
    );
    const content = newQuestions[firstQuestion].name;
    return content !== '' && content.length <= questionsMaxLength;
  };

  const isReadyToSubmit = () => noErrors(errors) && isFirstQuestionCreated();

  return (
    <Modal
      title="Add prompts"
      onClose={() => onClose()}
      className={styles.modal}
    >
      <div className={styles.content}>
        <div>
          {newQuestions.map((nq) => (
            <div className={styles.question} key={nq.auxId}>
              <div
                className={classnames(
                  ...['text__heading6__textNeutral40', styles.questionTitle],
                )}
              >
                Type custom prompt
              </div>
              <Input
                autoFocus
                key={nq.id}
                id={nq.auxId!}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e)
                }
                onBlur={(e) => validateQuestion(e)}
                withValidation={errors[nq.auxId!]}
                errorMessage={errors[nq.auxId!]}
              />
              {!errors[nq.auxId!] ? (
                <HelperText
                  content={`max. ${questionsMaxLength} characters`}
                  icon={<InfoIcon className={styles.icon} />}
                  className={styles.helperText}
                />
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => handleTypeAnotherQuestion()}
          className={
            !canAddNewQuestion()
              ? classnames(
                  'text__body__semi__bold__tiny__surfaceDark20',
                  styles.anotherQuestion,
                )
              : classnames(
                  'text__body__semi__bold__tiny__primary50',
                  styles.anotherQuestion,
                )
          }
          disabled={!canAddNewQuestion()}
        >
          <PlusIcon />
          Type another prompt
        </button>
      </div>
      <div className={styles.actions}>
        <Button
          buttonStyle={ButtonStyle.GreyGhost}
          buttonSize={ButtonSize.Medium}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          buttonStyle={ButtonStyle.PrimaryFilled}
          buttonSize={ButtonSize.Medium}
          onClick={handleAddQuestions}
          disabled={!isReadyToSubmit()}
        >
          Add all
        </Button>
      </div>
    </Modal>
  );
};
export { CustomQuestionsModal };
