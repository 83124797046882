import { AllDataUser } from 'models/all-data-user';
import { NotificationObject } from 'models/notificationObject';
import { MetaTags } from 'models/meta-tags';
import { SubscriptionType } from 'models/subscription-type';
import { identifyUser } from 'posthog';
import { appActions } from './action-types';

export enum HelpInfoSections {
  'albums_empty' = 'albums_empty',
  'albums_filled' = 'albums_filled',
  'add_prompts_empty' = 'add_prompts_empty',
  'add_prompts_filled' = 'add_prompts_filled',
  'record_empty' = 'record_empty',
  'record_filled' = 'record_filled',
  'upload_empty' = 'upload_empty',
  'upload_filled' = 'upload_filled',
  'video_call' = 'video_call',
  'album_settings' = 'album_settings',
  'album_clips' = 'album_clips',
  'album_clips_empty' = 'album_clips_empty',
  'account_settings' = 'account_settings',
  'contributors' = 'contributors',
  'templates_empty' = 'templates_empty',
  'templates_filled' = 'templates_filled',
  'store' = 'store',
}

export enum HelpInfoLinks {
  goToRecord = 'goToRecord',
  goToUpload = 'goToUpload',
  goToAddPrompts = 'goToAddPrompts',
  pickPrompts = 'pickPrompts',
  typePrompts = 'typePrompts',
  chooseTemplate = 'chooseTemplate',
  inviteCollaborators = 'inviteCollaborators',
}

type HelpLinks = {
  [key in HelpInfoLinks]: () => void;
};

export type InitialStateType = {
  data: AllDataUserType;
  logged: boolean;
  actualSection?: string;
  notification: NotificationObjectType;
  showBetaMsg: boolean;
  metaTags: MetaTagsType;
  subscriptionsScheme?: SubscriptionTypeType[];
  imageToEnlarge?: string;
  temporaryEmail?: string;
  isQrCodeImageEnlarged?: boolean;
  currentAlbum?: AlbumType;
  refetchAlbum: boolean;
  helpSection?: HelpInfoSections;
  helpLinks?: Partial<HelpLinks>;
};

const initialState: InitialStateType = {
  data: new AllDataUser(),
  logged: false,
  actualSection: '',
  notification: new NotificationObject(null),
  showBetaMsg: false,
  metaTags: new MetaTags(null),
  subscriptionsScheme: [],
  refetchAlbum: false,
};

type Action = {
  type: string;
  data: AllDataUserType;
  actualSection?: string;
  notification: NotificationObjectType;
  showBetaMsg: boolean;
  metaTags: MetaTagsType;
  subscriptionsScheme: SubscriptionType[];
  imageToEnlarge?: string;
  temporaryEmail?: string;
  isQrCodeImageEnlarged?: boolean;
  currentAlbum?: AlbumType;
  refetchAlbum: boolean;
  helpSection?: HelpInfoSections;
  helpLinks?: Partial<HelpLinks>;
};

const AppReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case appActions.USER_LOGGED:
      // Identify user in posthog
      identifyUser(action.data.user.id);
      return {
        ...state,
        data: action.data,
        logged: true,
      };
    case appActions.SESSION_EXPIRED:
      return {
        ...state,
        data: new AllDataUser(),
        logged: false,
        metaTags: new MetaTags(null),
        showBetaMsg: false,
      };
    case appActions.ACTUAL_SECTION:
      return {
        ...state,
        actualSection: action.actualSection,
      };
    case appActions.NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case appActions.BETA_MSG:
      return {
        ...state,
        showBetaMsg: action.showBetaMsg,
      };
    case appActions.SET_META_TAGS:
      return {
        ...state,
        metaTags: action.metaTags,
      };
    case appActions.SUBSCRIPTIONS_SCHEME:
      return {
        ...state,
        subscriptionsScheme: action.subscriptionsScheme,
      };
    case appActions.SET_IMAGE_TO_ENLARGE:
      return {
        ...state,
        imageToEnlarge: action.imageToEnlarge,
        isQrCodeImageEnlarged: action.isQrCodeImageEnlarged || false,
      };
    case appActions.SET_TEMPORARY_EMAIL:
      return {
        ...state,
        temporaryEmail: action.temporaryEmail,
      };
    case appActions.SET_CURRENT_ALBUM:
      return {
        ...state,
        currentAlbum: action.currentAlbum,
      };
    case appActions.SET_REFETCH_ALBUM:
      return {
        ...state,
        refetchAlbum: action.refetchAlbum,
      };
    case appActions.SET_HELP_SECTION:
      return {
        ...state,
        helpSection: action.helpSection,
      };
    case appActions.SET_HELP_LINKS:
      return {
        ...state,
        helpLinks: { ...state.helpLinks, ...action.helpLinks },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { initialState, AppReducer };
