import { OnboardingMessage } from 'models/onboardingMessage';
import { onboardingActions } from './action-types';

export enum OnboardingPhase {
  productTour = 'productTour',
  pickTutorial = 'pickTutorial',
}

export type InitialStateType = {
  onboardingPhase?: OnboardingPhase;
  onboardingMessage: OnboardingMessageType;
};

const initialState: InitialStateType = {
  onboardingMessage: new OnboardingMessage(null),
};

type Action = {
  type: string;
  onboardingMessage: OnboardingMessageType;
  onboardingPhase?: OnboardingPhase;
};

const OnboardingReducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case onboardingActions.ONBOARDING_MESSAGE:
      return {
        ...state,
        onboardingMessage: action.onboardingMessage,
      };
    case onboardingActions.SET_ONBOARDING_PHASE:
      return {
        ...state,
        onboardingPhase: action.onboardingPhase,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export { initialState, OnboardingReducer };
