import { CrossIcon } from 'assets/icons';
import React from 'react';
import { Button, ButtonSize } from 'common/button';
import styles from './help-button-message.module.scss';
import { FaqAndTutorials } from './faq-and-tutorials';

export type HelpButtonMessageType = {
  title: string;
  content: React.ReactElement;
  links?: { title: string; url: string }[];
};

type HelpButtonMessageProps = {
  message: HelpButtonMessageType;
  handleCloseMessage: () => void;
  handleClickActionButton?: () => void;
};

const HelpButtonMessage: React.FC<HelpButtonMessageProps> = ({
  message,
  handleCloseMessage,
  handleClickActionButton,
}) => {
  const handleClose = async () => {
    handleCloseMessage();
  };

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <div className={styles.messageHeader}>
          <div className="text__body__semi__bold__medium__textNeutral40">
            {message.title}
          </div>
          <button type="button" onClick={handleClose}>
            <CrossIcon />
          </button>
        </div>
        <div className="text__body__regular__medium__textNeutral30">
          {message.content}
        </div>
        {handleClickActionButton && (
          <Button
            buttonSize={ButtonSize.Small}
            onClick={() => {
              handleClickActionButton();
              handleClose();
            }}
          >
            Show me what&apos;s new
          </Button>
        )}
      </div>
      {message.links?.length && (
        <div className={styles.bottomSection}>
          <FaqAndTutorials links={message.links} />
        </div>
      )}
    </div>
  );
};

export { HelpButtonMessage };
