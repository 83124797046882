import { ReactNode, useEffect, useState } from 'react';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { copyToClipboard, classnames } from 'helpers/utils';
import { ModalWarning } from 'common/modal-warning';
import { goToPage, RouteName } from 'routes';
import styles from './copy-link.module.scss';

type CopyLinkProps = {
  path: string;
  disabled?: boolean;
  className?: string;
  buttonType?: ButtonType;
  buttonStyle?: ButtonStyle;
  buttonSize?: ButtonSize;
  children: ReactNode;
  showModalWarning?: boolean;
  copyLinkCallback?: () => void;
};

const CopyLink: React.FC<CopyLinkProps> = ({
  path,
  disabled = false,
  buttonType = ButtonType.Button,
  buttonStyle = ButtonStyle.PrimaryGhost,
  buttonSize = ButtonSize.Medium,
  className = '',
  children,
  showModalWarning = false,
  copyLinkCallback,
}) => {
  const [showMsg, setShowMsg] = useState(false);
  const [display, setDisplay] = useState(false);
  const [showModalWarningState, setShowModalWarning] = useState(false);

  const handleClick = () => {
    if (showModalWarning) {
      setShowModalWarning(true);
      return;
    }
    copyToClipboard(path);
    setDisplay(true);
    setShowMsg(true);
    copyLinkCallback?.();
  };
  const hidden = () => {
    setDisplay(false);
    setTimeout(() => setShowMsg(false), 900);
  };

  useEffect(() => {
    if (showMsg) {
      setTimeout(hidden, 3000);
    }
  }, [showMsg]);

  const tooltipClass = classnames(
    styles.tooltip,
    display ? styles.openAnimation : styles.closeAnimation,
  );

  return (
    <>
      {showModalWarningState && (
        <ModalWarning
          title="Sign up to share or download your recordings"
          content={
            <div className={styles.modalSignUpInfo}>
              <div className="text__body__regular__medium__textNeutral40">
                Please complete the sign up process to share or download your
                clips, as well as access the rest of our features.
              </div>
              <div className="text__body__regular__medium__textNeutral40">
                Don’t worry, your first album is{' '}
                <span className="text__body__semi__bold__medium__textNeutral40">
                  free
                </span>{' '}
                for a month, and signing up only takes 2 minutes!
              </div>
            </div>
          }
          successButtonText="Sign up"
          closeFn={() => setShowModalWarning(false)}
          successFn={() => goToPage(RouteName.SignUp)}
          successStyleButton={ButtonStyle.PrimaryFilled}
        />
      )}
      <Button
        buttonStyle={buttonStyle}
        buttonType={buttonType}
        buttonSize={buttonSize}
        disabled={disabled}
        className={classnames(styles.container, className)}
        onClick={handleClick}
      >
        {showMsg && <span className={tooltipClass}>Copied!</span>}
        {children}
      </Button>
    </>
  );
};

export { CopyLink };
