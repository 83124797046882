import React, {
  useState,
  ChangeEvent,
  Dispatch,
  useContext,
  useRef,
} from 'react';
import { Input } from 'common/input';
import { Line } from 'common/line';
import {
  classnames,
  isEmailValid,
  isPasswordValid,
  isTemporaryUser,
  noEmptyFields,
  noErrors,
} from 'helpers/utils';
import { AlertTriangleIcon, CrossIcon, EyeIcon } from 'assets/icons';
import { captchaSiteKey, errorsCode } from 'config/constants';
import { Notification } from 'common/notification';
import CypressIds from 'cypressIds';
import ReCAPTCHA from 'react-google-recaptcha';
import { Toggle } from 'common/toggle/toggle';
import { UserController } from 'networking/controllers/user-controller';
import { AppContext, appActions } from 'context';
import { NotificationType } from 'common/enums';
import { trackCustomEvent } from 'helpers/analytics';
import { SignUpFooter } from '../sign-up-footer';
import { Action } from '../sign-up-reducer';
import styles from './sign-up-step.module.scss';

type SignUpStep1Props = {
  dispatch: Dispatch<Action>;
  user: UserSignUpType;
  isCompany: boolean;
  couponsCodeApplied: CouponCodeID[];
};

type Mandatory = {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
};

const mandatoryStringFieldsErrors: Mandatory = {
  name: '',
  email: '',
  password: '',
  repeatPassword: '',
};

type SelectMandatory = {
  companyName: string;
};

const mandatorySelectFieldsErrors = {
  companyName: '',
};

const SignUpStep1: React.FC<SignUpStep1Props> = ({
  user,
  dispatch,
  isCompany,
  couponsCodeApplied,
}) => {
  const { name, email, password, companyName, repeatPassword } = user;
  const {
    dispatch: dispatchContext,
    state: { data: userData },
  } = useContext(AppContext);

  const [errors, setErrors] = useState<Mandatory>(mandatoryStringFieldsErrors);
  const [companyNameError, setCompanyNameError] = useState<SelectMandatory>(
    mandatorySelectFieldsErrors,
  );
  const [inputPassword, setInputPassword] = useState<boolean>(true);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [companyNameToggle, setCompanyNameToggle] = useState<boolean>(false);
  const [showCaptchaError, setShowCaptchaError] = useState<boolean>(false);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const validateField = (field: string, label?: string) => {
    const emptyFieldMessage =
      user[field].trim() === '' ? `${label || field} shouldn't be empty` : '';
    setErrors((prevState) => ({
      ...prevState,
      [field]: emptyFieldMessage.toUpperCase(),
    }));

    if (emptyFieldMessage) return;

    if (field === 'email') {
      const emailMessage = !isEmailValid(user[field]) ? 'invalid email' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: emailMessage.toUpperCase(),
      }));

      if (emailMessage) return;
    }

    if (field === 'password') {
      const passwordMessage = !isPasswordValid(user[field])
        ? 'Invalid password. It must have at least 8 characters, one letter and one number.'
        : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: passwordMessage.toUpperCase(),
      }));

      if (passwordMessage) return;
    }

    if (field === 'repeatPassword') {
      const repeatMessage =
        user.password !== user.repeatPassword ? 'passwords do not match' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: repeatMessage.toUpperCase(),
      }));
    }
  };

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    objectToChange: string,
  ) => {
    dispatch({
      type: 'CHANGE_INPUT',
      object: objectToChange,
      field: e.target.name,
      value: e.target.value,
    });
  };

  const validateCompanyName = () => {
    setCompanyNameError((prevState) => ({
      ...prevState,
      companyName: '',
    }));
    if (companyNameToggle && !companyName) {
      const stateMessage = 'company accounts need a company name';
      setCompanyNameError((prevState) => ({
        ...prevState,
        companyName: stateMessage.toUpperCase(),
      }));
    }
  };

  const checkFrontendErrors = () => {
    validateCompanyName();

    Object.keys(mandatoryStringFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryStringFieldsErrors);
    });
  };

  const showBackendErrors = (backendErrors: any) => {
    const errorsCopy = { ...errors };

    Object.keys(errorsCopy).forEach((field) => {
      errorsCopy[field as keyof typeof mandatoryStringFieldsErrors] =
        backendErrors[field]
          ? backendErrors[field].toUpperCase()
          : errorsCopy[field as keyof typeof mandatoryStringFieldsErrors];
    });

    setErrors(errorsCopy);
  };

  const generateBackendErrors = (err: any) => {
    const captchaError = err.errors.find(
      (e: any) => e.errorCode === errorsCode.invalidCaptcha,
    );

    const emailInUse = err.errors.find(
      (e: any) => e.errorCode === errorsCode.emailInUse,
    );

    if (emailInUse) {
      const objectErrors: MandatoryFieldsType = {
        email: 'Email already in use',
      };
      showBackendErrors(objectErrors);
    }
    if (captchaError) {
      if (captchaRef.current) {
        captchaRef.current.reset();
      }
      setShowCaptchaError(true);
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const getCouponCodesFlatArray = () =>
    couponsCodeApplied.map((sub: CouponCodeID) => sub.code);

  const createUser = async () => {
    dispatchContext({ type: appActions.SESSION_EXPIRED });
    try {
      const data = await UserController.registerUser(user);
      dispatchContext({
        type: appActions.NOTIFICATION,
        notification: {
          show: true,
          title: 'Your trial clips expired.',
          message:
            'Your trial clips are only saved for a week before sign up, but we still create a free account, re-record them and get a month of free storage. No credit card required.',
          type: NotificationType.Error,
          icon: <AlertTriangleIcon />,
        },
      });
      dispatchContext({ type: appActions.USER_LOGGED, data });
      trackCustomEvent('completeSignUp');
      return true;
    } catch (e) {
      setShowError(true);
      return false;
    }
  };

  const registerUser = async () => {
    checkFrontendErrors();
    if (!noErrors(errors)) {
      return false;
    }
    try {
      const requestData: UserSignUpType = {
        ...user,
        couponCodes: getCouponCodesFlatArray(),
      };

      const isTemporary = isTemporaryUser(userData.user);

      let data;
      if (isTemporary) {
        data = await UserController.registerTemporaryUser(requestData);
      } else {
        data = await UserController.registerUser(requestData);
      }
      dispatchContext({ type: appActions.USER_LOGGED, data });

      return true;
    } catch (e) {
      generateBackendErrors(e);
      if (errors) {
        return false;
      }
      if (isTemporaryUser(userData.user)) {
        const success = await createUser();
        return success;
      }
      setShowError(true);
      return false;
    }
  };
  const handleCaptcha = (e: string | null) => {
    setShowCaptchaError(false);
    if (!e) {
      return;
    }
    dispatch({
      type: 'CHANGE_INPUT',
      object: 'user',
      field: 'captchaToken',
      value: e,
    });
  };
  return (
    <>
      {showError && showErrorNotification()}
      <div>
        <div
          className={classnames(
            styles.titleView,
            'text__title1__textNeutral40',
          )}
        >
          {' '}
          Sign up
        </div>

        <div
          className={classnames(
            'text__body__regular__medium__textNeutral30',
            'my-12',
          )}
        >
          Personal information
        </div>

        <Input
          id="name"
          name="name"
          label="Name"
          placeholder="first & last name"
          value={name}
          type="text"
          errorMessage={errors.name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => validateField('name')}
          className="mb-6"
          dataCy={CypressIds.nameSignup}
        />

        {isCompany ? (
          <div className={classnames(styles.emailBox, 'mb-6')}>
            <span
              className={classnames(
                'text__body__semi__bold__medium__textNeutral40',
              )}
            >
              Email
            </span>
            <span
              className={classnames(
                styles.email,
                'text__body__regular__medium__textNeutral30',
              )}
            >
              {email}
            </span>
          </div>
        ) : (
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="email@example.com"
            value={email}
            type="email"
            errorMessage={errors.email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
            onBlur={() => validateField('email')}
            dataCy={CypressIds.emailSignup}
          />
        )}

        <div
          className={
            companyNameToggle
              ? styles.companyActivate
              : styles.companyDeactivate
          }
        >
          <div className={styles.toggleLabel}>
            <Toggle
              id="switch"
              name="companyNameToggle"
              checked={isCompany || companyNameToggle}
              onChangeFn={() => {
                setCompanyNameError((prevState) => ({
                  ...prevState,
                  companyName: '',
                }));
                dispatch({
                  type: 'CHANGE_INPUT',
                  object: 'user',
                  field: 'companyName',
                  value: '',
                });
                setCompanyNameToggle(!companyNameToggle);
              }}
              disabled={isCompany}
              buttonContainerClass={styles.toggle}
              tooltipText="Your url referred you to a company account"
            />
            <span
              className={classnames(
                'text__body__regular__medium__textNeutral40',
              )}
            >
              I’m creating a company account
            </span>
          </div>
          {(companyNameToggle || isCompany) && (
            <Input
              id="companyName"
              name="companyName"
              label="Company name"
              placeholder="company name"
              value={companyName}
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChange(e, 'user')
              }
              className="mb-6"
              dataCy={CypressIds.companyNameSignup}
              withValidation
              errorMessage={companyNameError.companyName}
              onBlur={() => validateCompanyName()}
              disabled={isCompany}
            />
          )}
        </div>

        <Input
          id="password"
          name="password"
          label="Password"
          placeholder="password"
          value={password}
          type={inputPassword ? 'password' : 'text'}
          errorMessage={errors.password}
          withIconEnd={
            <EyeIcon onClick={() => setInputPassword(!inputPassword)} />
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => {
            validateField('password');
            if (repeatPassword) {
              validateField('repeatPassword');
            }
          }}
          className="mb-6"
          dataCy={CypressIds.passwordSignup}
        />

        <Input
          id="repeatPassword"
          name="repeatPassword"
          label="Repeat password"
          placeholder="password"
          value={repeatPassword}
          type={inputPasswordRepeat ? 'password' : 'text'}
          errorMessage={errors.repeatPassword}
          withIconEnd={
            <EyeIcon
              onClick={() => setInputPasswordRepeat(!inputPasswordRepeat)}
            />
          }
          onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e, 'user')}
          onBlur={() => validateField('repeatPassword', 'Repeat Password')}
          className="mb-6"
          dataCy={CypressIds.repeatPasswordSignup}
        />

        <ReCAPTCHA
          sitekey={captchaSiteKey!}
          onChange={(e) => handleCaptcha(e)}
          ref={captchaRef}
        />
        {showCaptchaError && (
          <div className={styles.helperBox}>
            <span className={classnames(styles.iconCross)}>
              <CrossIcon className={styles.iconError} />
            </span>
            <span
              className={classnames(
                styles.errorMessage,
                'text__body__regular__overline__warning30',
              )}
            >
              Please verify that you’re not a robot
            </span>
          </div>
        )}

        <Line />

        <SignUpFooter
          enableNext={
            noEmptyFields({
              name,
              email,
              password,
              repeatPassword,
            }) &&
            noErrors(errors) &&
            user.captchaToken &&
            !(companyNameToggle && !companyName)
          }
          registerFn={() => registerUser()}
          isCompany={isCompany}
        />
      </div>
    </>
  );
};

export { SignUpStep1 };
