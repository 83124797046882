/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentType } from 'react';
import { HelpButton } from 'common/help-button';
import { Auth } from './auth';

const withAuth =
  <TOriginalProps extends {}>(
    Component: ComponentType<TOriginalProps>,
    optional?: boolean,
  ) =>
  (props: TOriginalProps) => (
    <Auth optional={optional}>
      <Component {...props} />
      <HelpButton />
    </Auth>
  );

export { withAuth };
