import { Dispatch, useRef } from 'react';
import { TemplateStateType } from 'pages/templates/templates-reducer';
import { InterviewScript } from 'common/interview-script';
import { UserController } from 'networking/controllers/user-controller';
import { templateQuestionsCountMaxLength } from 'config/constants';
import { appActions } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { questionsHaveChanged } from 'helpers/utils';
import { NotificationType, QuestionsManagmentViewType } from 'common/enums';

type TemplateQuestionsSectionProps = {
  templatesState: TemplateStateType;
  className?: string;
  generalDispatch: Dispatch<any>;
  notifyUpdateTemplateFromList: (
    updatedTemplate: PreBuiltQuestionsType,
  ) => void;
  notifyUpdateTemplateSelected: (
    updatedTemplate: PreBuiltQuestionsType,
  ) => void;
};

const TemplateQuestionsSection: React.FC<TemplateQuestionsSectionProps> = ({
  templatesState,
  className = '',
  generalDispatch,
  notifyUpdateTemplateFromList,
  notifyUpdateTemplateSelected,
}) => {
  const auxId = useRef<number>(1000);

  const { selectedTemplate } = templatesState;

  const handleEditQuestions = async (questionsUpdated: QuestionType[]) => {
    if (questionsHaveChanged(questionsUpdated, selectedTemplate.questions)) {
      try {
        const requestData: EditTemplateRequestType = {
          template: {
            ...selectedTemplate,
            questions: questionsUpdated,
          },
        };

        const updatedTemplate =
          await UserController.editCustomTemplate(requestData);
        notifyUpdateTemplateFromList(updatedTemplate);
        notifyUpdateTemplateSelected(updatedTemplate);

        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Templates Information',
            message: 'Template updated',
            type: NotificationType.Success,
          }),
        });
      } catch (err: any) {
        generalDispatch({
          type: appActions.NOTIFICATION,
          notification: new NotificationObject({
            show: true,
            title: 'Templates Information',
            message: 'Error updating template ',
          }),
        });
      }
    }
  };

  const getOnlyQuestionTypeProps = (
    questions: QuestionType[] = [],
  ): QuestionType[] =>
    questions.map((question) => {
      auxId.current += 1;

      return {
        name: question.name,
        auxId: auxId.current.toString(),
      };
    });

  return (
    <div className={className}>
      <InterviewScript
        unansQuestions={getOnlyQuestionTypeProps(selectedTemplate.questions)}
        maxQuestionsAllowed={templateQuestionsCountMaxLength}
        title="Prompts"
        questionsManagmentView={QuestionsManagmentViewType.myTemplates}
        setInterviewScript={(questionsUpdated: QuestionType[]) =>
          handleEditQuestions(questionsUpdated)
        }
      />
    </div>
  );
};

export { TemplateQuestionsSection };
