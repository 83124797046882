import React, { RefObject, useEffect, useState } from 'react';
import { Checkbox } from 'common/checkbox';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './onboarding-message.module.scss';

type OnboardingMessageProps = {
  itemRef: RefObject<any>;
  title?: string;
  message?: string;
  skippable?: boolean;
  confirmationButton?: { text: string; action: () => void };
  cancelButton?: { text: string; action: () => void };
  handleSkipTutorial: () => void;
  handleClose: () => void;
};

const OnboardingMessage: React.FC<OnboardingMessageProps> = ({
  itemRef,
  title,
  message,
  skippable = false,
  confirmationButton,
  cancelButton,
  handleSkipTutorial,
  handleClose,
}) => {
  const [position, setPosition] = useState<
    { left: number; top: number } | undefined
  >();
  const [skipTutorial, setSkipTutorial] = useState(false);

  const onConfirm = () => {
    if (skipTutorial) {
      handleSkipTutorial();
    } else {
      confirmationButton?.action();
    }
    handleClose();
  };

  const onCancel = () => {
    cancelButton?.action();
    handleClose();
  };

  useEffect(() => {
    if (itemRef?.current) {
      const { left, top, width } = itemRef.current.getBoundingClientRect();
      setPosition({ left: left + width + 24, top });
    }
  }, [itemRef?.current]);

  if (!position) return null;

  return (
    <>
      <div className={styles.messageBackground} />
      <div style={position} className={styles.container}>
        <div className="text__body__semi__bold__medium__textNeutral40">
          {title}
        </div>
        <div className="text__body__regular__medium__textNeutral30">
          {message}
        </div>
        <div className={styles.footer}>
          <div className={styles.checkbox}>
            {skippable && (
              <>
                <Checkbox
                  itemKey="skip-tutorial"
                  onChange={() => setSkipTutorial((prevState) => !prevState)}
                  isChecked={skipTutorial}
                />
                <span className="text__body__regular__medium__textNeutral40">
                  Skip tutorial
                </span>
              </>
            )}
          </div>
          <div className={styles.buttons}>
            {cancelButton && (
              <Button
                buttonSize={ButtonSize.Small}
                buttonStyle={ButtonStyle.PrimaryStroke}
                onClick={onCancel}
              >
                {cancelButton.text}
              </Button>
            )}
            {confirmationButton && (
              <Button buttonSize={ButtonSize.Small} onClick={onConfirm}>
                {confirmationButton.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { OnboardingMessage };
