import { Modal } from 'common/modal';
import React from 'react';
import { classnames } from 'helpers/utils';
import { Button, ButtonStyle } from 'common/button';
import styles from './pick-tutorials.module.scss';

export enum TutorialsEnum {
  recordMyLife = 'recordMyLife',
  journaling = 'journaling',
  recordSomeoneElse = 'recordSomeoneElse',
  uploadVideos = 'uploadVideos',
  uploadDocuments = 'uploadDocuments',
  preserveMemories = 'preserveMemories',
}

export type TutorialContent = {
  name: string;
  image: string;
  action: () => void;
};

export type Tutorials = {
  [key in TutorialsEnum]: TutorialContent;
};

type PickTutorialsModalProps = {
  tutorials: Tutorials;
  selectedTutorials: TutorialsEnum[];
  handleClickTutorial: (tutorial: TutorialsEnum) => void;
  handleClickNext: () => void;
  handleClickSkip: () => void;
};

const PickTutorialsModal: React.FC<PickTutorialsModalProps> = ({
  tutorials,
  selectedTutorials,
  handleClickTutorial,
  handleClickNext,
  handleClickSkip,
}) => (
  <Modal contentClassName={styles.container} disableManualClosing>
    <div className={styles.content}>
      <div className="text__heading4__primary50">What do you want to do?</div>
      <div className="text__body__regular__medium__textNeutral30">
        Let’s set up our album. Tell us what your plans are so we can help you
        on your first steps.
      </div>
      <div className={classnames(styles.cards, styles.cards3columns)}>
        {Object.keys(tutorials).map((tutorial) => (
          <button
            type="button"
            className={classnames(styles.card, {
              [styles.cardSelected]: selectedTutorials.includes(
                tutorial as TutorialsEnum,
              ),
            })}
            onClick={() => handleClickTutorial(tutorial as TutorialsEnum)}
          >
            <img
              alt="illustration"
              src={tutorials[tutorial as TutorialsEnum].image}
            />
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral30',
                styles.cardTitle,
              )}
            >
              {tutorials[tutorial as TutorialsEnum].name}
            </div>
          </button>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button buttonStyle={ButtonStyle.GreyGhost} onClick={handleClickSkip}>
          Skip tutorial
        </Button>
        <Button onClick={handleClickNext} disabled={!selectedTutorials.length}>
          Next
        </Button>
      </div>
    </div>
  </Modal>
);

export { PickTutorialsModal };
