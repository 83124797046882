import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

enum RouteName {
  Home = 'home',
  Account = 'account',
  Contributors = 'contributors',
  Templates = 'templates',
  Albums = 'albums',
  AlbumNew = 'albumNew',
  AlbumDetail = 'albumDetail',
  AlbumDetailGuest = 'albumDetailGuest',
  JoinVideoCallGuest = 'joinVideoCallGuest',
  ClipDetailGuest = 'clipDetailGuest',
  SignIn = 'signIn',
  SignUp = 'signUp',
  EmailWaitingConfirm = 'emailWaitingConfirm',
  EmailVerifying = 'emailVerifying',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
  AgoraVideoCall = 'agoraVideoCall',
  NotFound = 'notFound',
  NotFoundRedirect = 'notFoundRedirect',
  Store = 'store',
  UnlinkedQrCode = 'unlinkQrCode',
  SelfRecordingGuest = 'selfRecordingGuest',
  ReviewVideos = 'reviewVideos',
  ReviewVideosGuest = 'reviewVideosGuest',
  CreateTemporaryUser = 'createTemporaryUser',
  SignUpIgn = 'SignUpIgn',
  AlbumReminder = 'AlbumReminder',
}

type Route = {
  name: RouteName;
  path: string;
  exact?: boolean;
  pathParams?: string[];
  component?: React.ComponentType;
};

const PATH_PARAM_REGEX = /:[^/]+/gi;
/**
  Analyzes the path defined for `route` and
  returns a copy of the route with a new attribute
  `pathParams` which is a list of strings that correspond to the path params.

  @param {object} route - Object that represents a route.

  @return {object} updated route with the new attribute.

  @example

    determineRouteParams({ name: 'product', path: '/product/:id', component: ProductPage })
*/
const determineRouteParams = (route: Route): Route => {
  const newRoute = { ...route };
  const { path } = newRoute;
  // Extract the names of the parameters
  const pathMatch = path.match(PATH_PARAM_REGEX) || [];
  const pathParams = pathMatch.map((param) => param.slice(1));
  newRoute.pathParams = pathParams || [];
  return newRoute;
};

const routes = [
  {
    name: RouteName.Home,
    path: '/',
    exact: true,
  },
  {
    name: RouteName.AlbumNew,
    path: '/albums/new',
    exact: true,
  },
  {
    name: RouteName.AlbumDetail,
    path: '/albums/:id',
    exact: true,
  },
  {
    name: RouteName.AlbumDetailGuest,
    path: '/albums/share/:id',
    exact: true,
  },
  {
    name: RouteName.JoinVideoCallGuest,
    path: '/albums/:albumId/join/:videoCallToken',
  },
  {
    name: RouteName.Albums,
    path: '/albums',
    exact: true,
  },
  {
    name: RouteName.Contributors,
    path: '/contributors',
    exact: true,
  },
  {
    name: RouteName.ClipDetailGuest,
    path: '/clips/:albumId/:clipId',
    exact: true,
  },
  {
    name: RouteName.SignIn,
    path: '/signIn',
    exact: true,
  },
  {
    name: RouteName.SignUp,
    path: '/signUp',
    exact: true,
  },
  {
    name: RouteName.EmailWaitingConfirm,
    path: '/waitEmailConfirm',
    exact: true,
  },
  {
    name: RouteName.EmailVerifying,
    path: '/verifyEmail/:emailVerificationToken',
    exact: true,
  },
  {
    name: RouteName.ForgotPassword,
    path: '/forgotPassword',
    exact: true,
  },
  {
    name: RouteName.ResetPassword,
    path: '/resetPassword/:token',
    exact: true,
  },
  {
    name: RouteName.Account,
    path: '/account',
    exact: true,
  },
  {
    name: RouteName.Templates,
    path: '/templates',
    exact: true,
  },
  {
    name: RouteName.AgoraVideoCall,
    path: '/agoraVideoCall/:agoraAlbumId/:videoCallToken/:role',
    exact: true,
  },
  {
    name: RouteName.Store,
    path: '/store',
    exact: true,
  },
  {
    name: RouteName.SelfRecordingGuest,
    path: '/guest/:guestToken/albums/:albumId',
    exact: true,
  },
  {
    name: RouteName.ReviewVideosGuest,
    path: '/guest/:guestToken/reviewVideos',
    exact: true,
  },
  {
    name: RouteName.UnlinkedQrCode,
    path: '/unlinked-qr-code',
    exact: true,
  },
  {
    name: RouteName.NotFoundRedirect,
    path: '/not-found',
    exact: true,
  },
  {
    name: RouteName.ReviewVideos,
    path: '/reviewVideos',
    exact: true,
  },
  {
    name: RouteName.CreateTemporaryUser,
    path: '/createTemporaryUser',
    exact: true,
  },
  {
    name: RouteName.SignUpIgn,
    path: '/signUpInheritGuard',
    exact: true,
  },
  {
    name: RouteName.AlbumReminder,
    path: '/album-reminder',
    exact: true,
  },
  {
    name: RouteName.NotFound,
    path: '/*',
  },
].map(determineRouteParams);

export { determineRouteParams, history, RouteName, routes };
