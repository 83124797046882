/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/**
 * Utility function to concat classNames.
 *
 * Usage: classnames('css_class1', 'css_class1')
 *
 * Can be used with objects where the keys are css classes and the
 * values are booleans that decide if classes are active or not:
 *
 * Example: classnames('input', { 'input-error': has_errors })
 *
 * @param  {...any} args
 * @returns string
 */
import {
  AlbumTabs,
  QuestionsManagmentViewType,
  RecordingStatus,
  SubscriptionSchemeId,
  SubscriptionStatus,
  TimeUnit,
  UserTypeId,
} from 'common/enums';
import { screenSizes } from 'config/constants';
import { useMediaQuery } from 'hooks/use-media-query';
import { goToPage, RouteName } from 'routes';

type ClassnameObject = {
  [key: string]: string | boolean | number;
};

type Classname = ClassnameObject | string;

type HTMLValidationError = {
  [n: string]: string;
};

function classnames(...args: Classname[]): string {
  if (args.length === 1) {
    const [firstEntry] = args;
    if (firstEntry && typeof firstEntry === 'object') {
      /* firstEntry's keys whose value is truthy */
      const activeClasses = Object.entries(firstEntry)
        .filter(([, value]) => value)
        .map(([key]) => key);
      return activeClasses.join(' ');
    }
    return firstEntry;
  }
  return args
    .filter((entry) => !!entry)
    .map((value) => classnames(value))
    .join(' ');
}

const getQueryParamsObject = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryParamsObject: any = {};

  queryParams.forEach((value, key) => {
    queryParamsObject[key] = value;
  });

  return queryParamsObject;
};

const getQueryParam = (param: string) => {
  const prop = new URLSearchParams(window.location.search).get(param);

  return prop || null;
};

const checkValueAndGetEmptyError = (value: string) =>
  value.trim() === '' ? "shouldn't be empty" : '';

const isEmailValid = (emailAddress: string) => {
  const re =
    /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
  return emailAddress && re.test(emailAddress);
};

const isPasswordValid = (password: string) => {
  if (
    password.length < 8 ||
    !/[a-zA-Z]/.test(password) ||
    !/[0-9]/.test(password)
  )
    return false;

  return true;
};

const noEmptyFields = (
  data: MandatoryFieldsType,
  mandatoryFields?: MandatoryFieldsType,
) => {
  if (mandatoryFields) {
    for (const field in mandatoryFields) {
      if (!data[field] || data[field] === '') return false;
    }
  } else {
    for (const field in data) {
      if (data[field] === '') return false;
    }
  }

  return true;
};

const noErrors = (errors: MandatoryFieldsType) => {
  for (const field in errors) {
    if (errors[field] !== '') return false;
  }

  return true;
};

const isValidForm = (
  data: MandatoryFieldsType,
  mandatoryFields: MandatoryFieldsType,
  errors: MandatoryFieldsType,
) => noEmptyFields(data, mandatoryFields) && noErrors(errors);

const isAnyLoggedUser = (userLogged: UserType) => userLogged.id > 0;

const isOwner = (userLogged: UserType, album: AlbumType) =>
  isAnyLoggedUser(userLogged) && userLogged.id === album.owner.id;

const isContributor = (userLogged: UserType, album: AlbumType) =>
  isAnyLoggedUser(userLogged) &&
  !!album.contributors.find((contributor) => contributor.id === userLogged.id);

const hasAlbumAccess = (userLogged: UserType, album: AlbumType) =>
  isOwner(userLogged, album) || isContributor(userLogged, album);

const isOwnerOrClipOwner = (
  userLogged: UserType,
  album: AlbumType,
  clip: ClipType,
) =>
  isAnyLoggedUser(userLogged) &&
  (userLogged.id === album.owner.id ||
    !clip.createdByOwner ||
    clip.recordedBy === userLogged.id);

const calculateHoursAndMinutes = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  return { hours, minutes };
};

const getSortedClipsByRecordedAt = (clips: ClipType[]) =>
  clips.sort(
    (a: ClipType, b: ClipType) =>
      +new Date(a.recordedAt) - +new Date(b.recordedAt),
  );

const showTextEllipsis = (text: string, maxCharacteres: number) => {
  if (text.length < maxCharacteres) return text;

  return `${text.substring(0, maxCharacteres)}...`;
};

const copyToClipboard = async (copyMe: string) => {
  navigator.clipboard.writeText(copyMe);
};

const hasNotUserValidatedEmail = (user: UserType) => user.verified === false;

const getAllMediaQueries = () => {
  const result: Partial<MediaQueriesType> = {};

  Object.keys(screenSizes).forEach((size) => {
    result[size as keyof typeof screenSizes] = useMediaQuery(
      screenSizes[size as keyof typeof screenSizes],
    );
  });

  return result as MediaQueriesType;
};

const dateTimePassedIsLessThan = (date: Date, milliseconds: number) =>
  new Date().getTime() - new Date(date).getTime() < milliseconds;

const getIndexClipSelectedInList = (
  clipsList: ClipType[],
  actualClipSelected: ClipType,
) => clipsList.findIndex((clip: ClipType) => clip.id === actualClipSelected.id);

const isNotEmptyAndInvalidEmail = (email: string) =>
  email && !isEmailValid(email);

const checkEmailAndGetGenericError = (email: string) =>
  isNotEmptyAndInvalidEmail(email) ? 'Invalid email' : '';

const checkStateAndGetError = (state: string) =>
  state === '' ? 'You must select a state' : '';

const getPriceInfoBySubscription = (
  pricesBySubscription: SubscriptionDiscountedPriceType[],
  subSelected: SubscriptionTypeType,
): SubscriptionDiscountedPriceType =>
  pricesBySubscription.find((priceSub) => priceSub.id === subSelected.id)!;

const anyClipInProcess = (unansweredQuestions?: ClipType[]) => {
  if (!unansweredQuestions) return false;

  return unansweredQuestions.some(
    (clip: ClipType) => clip.recordingStatus === RecordingStatus.processing,
  );
};

const isFreeTrial = (album: AlbumType) =>
  album.subscriptionType === SubscriptionSchemeId.trial;

const isReadOnly = (album: AlbumType) =>
  album.subscriptionType === SubscriptionSchemeId.readOnly ||
  album.subscriptionType === SubscriptionSchemeId.readOnlyFree;

const isLegacy = (album: AlbumType) =>
  album.subscriptionType === SubscriptionSchemeId.legacy;

const isLifeTime = (album: AlbumType) =>
  album.subscriptionType === SubscriptionSchemeId.lifeTime;

const isRestrictedAlbum = (album: AlbumType) =>
  (!isFreeTrial(album) &&
    album.subscriptionStatus === SubscriptionStatus.paymentFailed) ||
  (!isFreeTrial(album) &&
    album.subscriptionStatus === SubscriptionStatus.subscriptionOverdue);

const isReadOnlySubOrRestrictedAlbum = (album: AlbumType) =>
  isReadOnly(album) || isRestrictedAlbum(album);

const isCanceledAlbum = (album: AlbumType) =>
  !isFreeTrial(album) &&
  album.subscriptionStatus === SubscriptionStatus.subscriptionCanceled;

const isLockedAlbum = (album: AlbumType) =>
  !isFreeTrial(album) &&
  album.subscriptionStatus === SubscriptionStatus.subscriptionEnded;

const totalAlbumQuestions = (album: AlbumType) => {
  const clips = album.clips?.length || 0;
  const unansweredQuestions = album.unansweredQuestions?.length || 0;

  return clips + unansweredQuestions;
};

const totalAlbumVideoClips = (album: AlbumType) => album.clips?.length || 0;

const hasContributor = (album: AlbumType) => album.contributors.length;

const getSubscriptionSchemeById = (
  subscriptionsScheme: SubscriptionTypeType[],
  id: SubscriptionSchemeId,
) =>
  subscriptionsScheme.find(
    (subcription: SubscriptionTypeType) => subcription.id === id,
  );

// TODO: Deprecate this function since the max clips amount comes in the album now
const getMaxQuestionsAllowedByAlbum = (
  album: AlbumType,
  subsScheme: SubscriptionTypeType[],
): number | undefined => {
  const scheme = getSubscriptionSchemeById(subsScheme, album.subscriptionType);

  return scheme?.permissions?.clipNumbers;
};

const getClipPriceByAlbum = (
  album: AlbumType,
  subsScheme: SubscriptionTypeType[],
): number => {
  const scheme = getSubscriptionSchemeById(subsScheme, album.subscriptionType);

  return scheme?.extraClipPrice ?? 0;
};

const getExtraCallTimeHourPriceAlbum = (
  album: AlbumType,
  subsScheme: SubscriptionTypeType[],
): number => {
  const scheme = getSubscriptionSchemeById(subsScheme, album.subscriptionType);

  return scheme?.extraVideoCallTimePrice ?? 0;
};

const getPermissionsByAlbum = (
  subsScheme: SubscriptionTypeType[],
  album: AlbumType,
): SubscriptionPermissionsType => {
  const scheme = getSubscriptionSchemeById(subsScheme, album.subscriptionType);

  return scheme!.permissions!;
};

const hasAlbumReachedLimitMessage = (
  album: AlbumType,
  tab: AlbumTabs,
): string => {
  if (
    [AlbumTabs.clips, AlbumTabs.addPrompts, AlbumTabs.videoCall].includes(tab)
  ) {
    const fullMessage = isFreeTrial(album)
      ? 'Your trial album is full'
      : 'Your album is full';
    const reachingLimitMessage = isFreeTrial(album)
      ? 'Your trial album is almost full'
      : 'Your album is almost full';

    if (totalAlbumVideoClips(album) >= album.maxClips) {
      return fullMessage;
    }
    if (album.maxClips - totalAlbumVideoClips(album) <= 3) {
      return reachingLimitMessage;
    }
  }

  return '';
};

const convertSecondsToTimeUnit = (
  totalSeconds: number,
  timeUnit: TimeUnit,
): number => {
  if (timeUnit === TimeUnit.milliseconds) {
    return totalSeconds * 1000;
  }

  if (timeUnit === TimeUnit.minutes) {
    return Math.floor(totalSeconds / 60);
  }

  if (timeUnit === TimeUnit.hours) {
    return Math.floor(totalSeconds / (60 * 60));
  }

  return totalSeconds;
};

const redictDependingFailingStatus = (user: UserType) => {
  if (hasNotUserValidatedEmail(user)) {
    goToPage(RouteName.EmailWaitingConfirm);
    return true;
  }

  return false;
};

const capitalizeFirstLetter = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1);

const getAlbumStatusLabel = (album: AlbumType) => {
  let withIcon = true;
  let statusValue = null;

  if (isRestrictedAlbum(album)) {
    statusValue = 'Restricted';
  } else if (isCanceledAlbum(album)) {
    statusValue = 'Canceled';
  } else if (isLockedAlbum(album)) {
    statusValue = 'Locked';
  } else if (isReadOnly(album)) {
    withIcon = false;
    statusValue = 'Read only';
  } else if (isFreeTrial(album)) {
    withIcon = false;
    statusValue = 'Trial';
  }

  return { withIcon, statusValue };
};

// Check if the number, order, or names of questions have changed
const questionsHaveChanged = (
  originalQuestions: QuestionType[],
  updatedQuestions: QuestionType[],
) => {
  if (originalQuestions.length !== updatedQuestions.length) return true;
  for (let i = 0; i < originalQuestions.length; i += 1) {
    if (originalQuestions[i].name !== updatedQuestions[i].name) return true;
  }
  return false;
};

const isEditingAlbum = (questionsManagmentView: QuestionsManagmentViewType) =>
  questionsManagmentView === QuestionsManagmentViewType.editAlbum;

const isEditingMyTemplates = (
  questionsManagmentView: QuestionsManagmentViewType,
) => questionsManagmentView === QuestionsManagmentViewType.myTemplates;

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const isSpecificBrowserVersion = (browser: string, version: string) =>
  // TODO: Typescript doesn't support `userAgentData` yet since it is still experimental
  // @ts-ignore
  window.navigator.userAgentData?.brands.find(
    (brand: { brand: string; version: any }) =>
      brand.brand.includes(browser) &&
      Number(brand.version) === Number(version),
  );

const isLowerBrowserVersion = (browser: string, version: string) =>
  // TODO: Typescript doesn't support `userAgentData` yet since it is still experimental
  // @ts-ignore
  window.navigator.userAgentData?.brands.find(
    (brand: { brand: string; version: any }) =>
      brand.brand.includes(browser) && Number(brand.version) < Number(version),
  );

// INFO: Includes Chrome & Chromium
const isChromeVersionWithIssues = () =>
  isSpecificBrowserVersion('Chrom', '115');

const formatRecordingTime = (time: number) => {
  const timeInSec = time % 60;
  const mins = Math.floor(time / 60);

  const timeInSecStr = timeInSec < 10 ? `0${timeInSec}` : timeInSec;
  const minsStr = mins < 10 ? `0${mins}` : mins;

  return `${minsStr}:${timeInSecStr}`;
};

const getClipNameOrAlbumTitle = (clipId: number, album: AlbumType) => {
  if (album.clips) {
    const clipName = album.clips.find((clip: ClipType) => clip.id === clipId);
    if (clipName) return clipName.name;
  }
  return album.title;
};

const canUpgradeAlbum = (
  album: AlbumType,
  subscriptionsScheme?: SubscriptionTypeType[],
) => {
  if (!subscriptionsScheme) return false;
  const permissions = getPermissionsByAlbum(subscriptionsScheme, album);
  return permissions && !!permissions.canSwitchTo;
};

const canBuyClips = (
  album: AlbumType,
  subscriptionsScheme?: SubscriptionTypeType[],
) => {
  if (!subscriptionsScheme) return false;
  const permissions = getPermissionsByAlbum(subscriptionsScheme, album);
  return permissions && !!permissions.buyExtraClips;
};

const canBuyExtraVideoCallTime = (
  album: AlbumType,
  subscriptionsScheme?: SubscriptionTypeType[],
) => {
  if (!subscriptionsScheme) return false;
  const permissions = getPermissionsByAlbum(subscriptionsScheme, album);
  return permissions && !!permissions.buyExtraVideoCallTime;
};

const formatProgressBarTime = (hours: number, minutes: number) => {
  let text = '';
  if (hours > 0 || minutes === 0) {
    text = `${text} ${hours} ${hours > 1 ? 'hours' : 'hour'}`;
  }
  if (minutes > 0) {
    text = `${text}${text.length > 0 ? ', ' : ''}${minutes} ${
      minutes > 1 ? 'minutes' : 'minute'
    }`;
  }
  return text;
};

const isExpiredDate = (date: Date) => new Date() > date;

const formatPrice = (price: number) => {
  const number = parseFloat(price.toString());
  if (number % 1 !== 0) {
    return number.toFixed(2);
  }
  return number;
};

const albumHasPublicClip = (alb: AlbumType) => {
  if (!alb?.clips?.length) return false;

  return alb.clips.find((clip: ClipType) => !clip.isPrivate);
};

const isTemporaryUser = (user: UserType) =>
  user.permissions?.id === UserTypeId.Temporary;

const userPermissionUpgrade = (user: UserType) =>
  !!user.permissions?.permissions.upgradeAlbums;

export {
  isChromeVersionWithIssues,
  isLowerBrowserVersion,
  isSpecificBrowserVersion,
  classnames,
  getQueryParamsObject,
  capitalizeFirstLetter,
  checkValueAndGetEmptyError,
  isEmailValid,
  isPasswordValid,
  noEmptyFields,
  noErrors,
  isValidForm,
  isOwner,
  isContributor,
  hasAlbumAccess,
  isOwnerOrClipOwner,
  isAnyLoggedUser,
  hasContributor,
  calculateHoursAndMinutes,
  getSortedClipsByRecordedAt,
  showTextEllipsis,
  copyToClipboard,
  hasNotUserValidatedEmail,
  redictDependingFailingStatus,
  getAllMediaQueries,
  dateTimePassedIsLessThan,
  getIndexClipSelectedInList,
  anyClipInProcess,
  isNotEmptyAndInvalidEmail,
  checkEmailAndGetGenericError,
  hasAlbumReachedLimitMessage,
  isRestrictedAlbum,
  getSubscriptionSchemeById,
  convertSecondsToTimeUnit,
  totalAlbumQuestions,
  totalAlbumVideoClips,
  getMaxQuestionsAllowedByAlbum,
  getPermissionsByAlbum,
  getClipNameOrAlbumTitle,
  isReadOnly,
  isLegacy,
  isFreeTrial,
  isCanceledAlbum,
  isLockedAlbum,
  isLifeTime,
  isEditingAlbum,
  isEditingMyTemplates,
  isReadOnlySubOrRestrictedAlbum,
  getPriceInfoBySubscription,
  getAlbumStatusLabel,
  questionsHaveChanged,
  sleep,
  checkStateAndGetError,
  formatRecordingTime,
  canUpgradeAlbum,
  formatProgressBarTime,
  isExpiredDate,
  getClipPriceByAlbum,
  getExtraCallTimeHourPriceAlbum,
  formatPrice,
  canBuyClips,
  canBuyExtraVideoCallTime,
  albumHasPublicClip,
  isTemporaryUser,
  userPermissionUpgrade,
  getQueryParam,
};

export type { HTMLValidationError };
