import { Modal } from 'common/modal';
import React from 'react';
import UploadVideosImage from 'assets/images/event-upload-videos.png';
import UploadDocumentsImage from 'assets/images/event-upload-documents.png';
import RecordImage from 'assets/images/event-record.png';
import InviteGuestsImage from 'assets/images/event-invite-guests.png';
import { classnames } from 'helpers/utils';
import styles from './pick-tutorials.module.scss';

enum EventReasonEnum {
  record = 'record',
  inviteGuests = 'inviteGuests',
  uploadVideos = 'uploadVideos',
  uploadDocuments = 'uploadDocuments',
}

type EventContent = {
  name: string;
  image: string;
  action: () => void;
};

type EventReason = {
  [key in EventReasonEnum]: EventContent;
};

const reasons: EventReason = {
  [EventReasonEnum.record]: {
    name: 'Record the event',
    image: RecordImage,
    action: () => {
      // Navigate to "Download the app"
    },
  },
  [EventReasonEnum.inviteGuests]: {
    name: 'Invite guests to add content to the album',
    image: InviteGuestsImage,
    action: () => {
      // Navigate to "Invite guests / contributors"
    },
  },
  [EventReasonEnum.uploadVideos]: {
    name: 'Upload videos',
    image: UploadVideosImage,
    action: () => {
      // Navigate to "Upload tutorial"
    },
  },
  [EventReasonEnum.uploadDocuments]: {
    name: 'Upload documents & images',
    image: UploadDocumentsImage,
    action: () => {
      // Navigate to "Upload tutorial"
    },
  },
};

type EventReasonModalProps = {};

const EventReasonModal: React.FC<EventReasonModalProps> = () => (
  <Modal contentClassName={styles.container} disableManualClosing>
    <div className={styles.content}>
      <div className="text__heading4__primary50">
        What event memories will go into this album?
      </div>
      <div className={classnames(styles.cards, styles.cards2columns)}>
        {Object.keys(reasons).map((reason) => (
          <button
            type="button"
            className={classnames(styles.card)}
            onClick={reasons[reason as EventReasonEnum].action}
          >
            <img
              alt="illustration"
              src={reasons[reason as EventReasonEnum].image}
            />
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral30',
                styles.cardTitle,
              )}
            >
              {reasons[reason as EventReasonEnum].name}
            </div>
          </button>
        ))}
      </div>
    </div>
  </Modal>
);

export { EventReasonModal };
