import { SVGProps, FunctionComponent, useState } from 'react';

import { CrossIcon, TagIcon } from 'assets/icons';
import { classnames } from 'helpers/utils';
import { ModalWarningDeleteDeal } from 'common/modal-warning-delete-deal';

import { trackCustomEvent } from 'helpers/analytics';
import styles from './discount-code-item.module.scss';

type DiscountCodeItemProps = {
  className?: string;
  Icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  deal: SubscriptionDealResponseType;
  deleteCouponCode?: (deal: SubscriptionDealResponseType) => void;
};

const DiscountCodeItem: FunctionComponent<DiscountCodeItemProps> = ({
  className = '',
  Icon = TagIcon,
  deal,
  deleteCouponCode,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onClickDelete = () => {
    trackCustomEvent('startRemoveDiscountCode', {
      discountCode: deal.id,
    });
    setShowDeleteModal(true);
  };

  return (
    <>
      <div className={classnames(styles.labelBox, className)}>
        <div
          className={classnames(styles.codeBox, {
            [styles.nonDeletable]: !deleteCouponCode,
          })}
        >
          <Icon className={styles.tagIcon} />
          <span className="text__body__semi__bold__overline__textNeutral30">
            {deal.name.toUpperCase()}
          </span>
          {deleteCouponCode && (
            <button
              aria-label="Close"
              className={styles.crossIcon}
              type="button"
              onClick={onClickDelete}
            >
              <CrossIcon />
            </button>
          )}
        </div>
      </div>
      {showDeleteModal && (
        <ModalWarningDeleteDeal
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() => {
            deleteCouponCode?.(deal);
            setShowDeleteModal(false);
          }}
          deal={deal}
        />
      )}
    </>
  );
};

export { DiscountCodeItem };
