import { ChevronIcon } from 'assets/icons';
import { Button, ButtonStyle } from 'common/button';
import React from 'react';
import styles from './help-button-message.module.scss';

type FaqAndTutorialsProps = {
  links: { title: string; url: string }[];
};

const FaqAndTutorials: React.FC<FaqAndTutorialsProps> = ({ links }) => (
  <>
    <div className="text__body__semi__bold__medium__textNeutral40">
      FAQ & Tutorials
    </div>
    {links.map((link) => (
      <Button
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        className={styles.link}
        onClick={() => window.open(link.url, '_blank')}
      >
        <ChevronIcon />
        {link.title}
      </Button>
    ))}
  </>
);

export { FaqAndTutorials };
