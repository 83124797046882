import { AppContext } from 'context';
import {
  onboardingActions,
  OnboardingContext,
} from 'context/onboarding-context';
import { OnboardingPhase } from 'context/onboarding-context/context-reducer';
import { useContext, useEffect } from 'react';

export const useTriggerOnboarding = () => {
  const {
    state: {
      data: { user },
    },
  } = useContext(AppContext);
  const { dispatch: onboardingDispatch } = useContext(OnboardingContext);

  useEffect(() => {
    if (user.id > 0 && user.verified && !user.firstVisitDisplayed) {
      onboardingDispatch({
        type: onboardingActions.SET_ONBOARDING_PHASE,
        onboardingPhase: OnboardingPhase.productTour,
      });
    }
  }, [user]);

  return {};
};
