import React, { useContext, useEffect, useReducer } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AppContext, appActions } from 'context';
import { classnames } from 'helpers/utils';
import { NotificationObject } from 'models/notificationObject';
import { templatesCountMaxLength } from 'config/constants';
import { cloneDeep } from 'lodash';
import { NavSections } from 'common/enums';
import { WarningBadge } from 'common/warning-badge';
import { Spinner } from 'common/spinner';
import { UserController } from 'networking/controllers/user-controller';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import { TemplatesInitialState, TemplatesReducer } from './templates-reducer';
import { TemplatesHeader } from './components/templates-header';
import { TemplatesListSection } from './components/templates-list-section';
import { NoTemplatesCreated } from './components/no-templates-created';
import { TemplateQuestionsSection } from './components/template-questions-section';
import { TemplateNameSection } from './components/template-name-section';
import styles from './templates.module.scss';

const Templates: React.FC = () => {
  const { dispatch: generalDispatch } = useContext(AppContext);
  const [templatesState, templatesDispatch] = useReducer(
    TemplatesReducer,
    TemplatesInitialState,
  );

  const { templatesList, isLoading } = templatesState;

  const selectFirstTemplateByDefault = (
    firstTemplate: PreBuiltQuestionsType,
  ) => {
    templatesDispatch({
      type: 'SELECT_TEMPLATE',
      selectedTemplate: firstTemplate,
    });
    templatesDispatch({
      type: 'CURRENT_NAME',
      templateName: firstTemplate.name,
    });
  };

  const fetchCustomTemplates = async (
    selectFirstOneByDefault: boolean = true,
  ) => {
    try {
      const data = await UserController.getCustomTemplates();
      const { templates } = data;

      templatesDispatch({ type: 'UPDATE_TEMPLATES', templatesList: templates });

      if (templates.length && selectFirstOneByDefault) {
        selectFirstTemplateByDefault(data.templates[0]);
      }
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error getting user templates',
        }),
      });
    } finally {
      templatesDispatch({ type: 'IS_LOADING', isLoading: false });
    }
  };

  useEffect(() => {
    generalDispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.templates,
    });
    fetchCustomTemplates();
  }, []);

  useEffect(() => {
    if (templatesList.length) {
      generalDispatch({
        type: appActions.SET_HELP_SECTION,
        helpSection: HelpInfoSections.templates_filled,
      });
    } else {
      generalDispatch({
        type: appActions.SET_HELP_SECTION,
        helpSection: HelpInfoSections.templates_empty,
      });
    }
  }, [templatesList]);

  const handleAddTemplate = async () => {
    try {
      const newTemplate = await UserController.addCustomTemplates();

      const templatesListUpdatedCopy = cloneDeep(templatesList);
      templatesListUpdatedCopy.push(newTemplate);

      templatesDispatch({
        type: 'UPDATE_TEMPLATES',
        templatesList: templatesListUpdatedCopy,
      });
      templatesDispatch({
        type: 'SELECT_TEMPLATE',
        selectedTemplate: newTemplate,
      });
      templatesDispatch({
        type: 'CURRENT_NAME',
        templateName: newTemplate.name,
      });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error creating template',
        }),
      });
    }
  };

  const showTemplatesMaxCountReachedLabel = () => (
    <WarningBadge
      className={styles.warningMaxReached}
      message={`${templatesCountMaxLength} out of ${templatesCountMaxLength} templates created`}
    />
  );

  const handleUpdateTemplateFromList = (
    updatedTemplate: PreBuiltQuestionsType,
  ) => {
    const templateListUpdated = templatesList.map(
      (template: PreBuiltQuestionsType) => {
        if (template.id === updatedTemplate.id) {
          return updatedTemplate;
        }

        return template;
      },
    );

    templatesDispatch({
      type: 'UPDATE_TEMPLATES',
      templatesList: templateListUpdated,
    });
  };

  const handleUpdateTemplateSelected = (
    updatedTemplate: PreBuiltQuestionsType,
  ) => {
    templatesDispatch({
      type: 'SELECT_TEMPLATE',
      selectedTemplate: updatedTemplate,
    });
  };

  const showContentView = () => (
    <>
      <div className={styles.templatesListColumn}>
        <TemplatesHeader
          templatesState={templatesState}
          className={styles.header}
          notifyAddTemplate={handleAddTemplate}
        />

        <div className={styles.divider} />

        <TemplatesListSection
          className={styles.templateList}
          templatesState={templatesState}
          templatesDispatch={templatesDispatch}
        />
        {templatesList.length >= templatesCountMaxLength &&
          showTemplatesMaxCountReachedLabel()}
      </div>
      <div className={styles.questionsColumn}>
        {templatesState.templatesList.length ? (
          <>
            <TemplateNameSection
              templatesState={templatesState}
              templatesDispatch={templatesDispatch}
              generalDispatch={generalDispatch}
              notifyUpdateTemplateFromList={handleUpdateTemplateFromList}
              notifyUpdateTemplateSelected={handleUpdateTemplateSelected}
            />
            <TemplateQuestionsSection
              key={templatesState.selectedTemplate.id}
              templatesState={templatesState}
              generalDispatch={generalDispatch}
              className={styles.questionsList}
              notifyUpdateTemplateFromList={handleUpdateTemplateFromList}
              notifyUpdateTemplateSelected={handleUpdateTemplateSelected}
            />
          </>
        ) : (
          <NoTemplatesCreated notifyAddTemplate={handleAddTemplate} />
        )}
      </div>
    </>
  );

  return (
    <div
      className={classnames(
        globalStyles.loggedMainContainer,
        styles.fullContent,
      )}
    >
      <div className={styles.templatesContent}>
        {!isLoading ? showContentView() : <Spinner />}
      </div>
    </div>
  );
};

export { Templates };
