import React, { ImgHTMLAttributes } from 'react';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { classnames } from 'helpers/utils';
import styles from './sign-header.module.scss';

type SignHeaderProps = {
  companyLogo?: ImgHTMLAttributes<HTMLImageElement>['src'];
};

const SignHeader: React.FC<SignHeaderProps> = ({ companyLogo }) => (
  <div
    className={classnames(styles.container, {
      [styles.logosAlignment]: !!companyLogo,
    })}
  >
    <div className={styles.logoAndText}>
      <Logo className={styles.logo} />
      <div className={styles.text}>
        <div className={styles.generational}>Generational</div>
        <div className={styles.story}>Story</div>
      </div>
    </div>
    {companyLogo && (
      <>
        <div className="text__heading6__surfaceLight50">&</div>{' '}
        <img className={styles.logo} src={companyLogo} alt="" />
      </>
    )}
  </div>
);

export { SignHeader };
