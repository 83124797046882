import React, { createContext, useReducer, ReactNode, Dispatch } from 'react';

import {
  OnboardingReducer,
  initialState,
  InitialStateType,
} from './context-reducer';

type OnboardingProviderProps = {
  children: ReactNode;
};

const OnboardingContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const OnboardingProvider = ({ children }: OnboardingProviderProps) => {
  const [state, dispatch] = useReducer(OnboardingReducer, initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <OnboardingContext.Provider value={{ state, dispatch }}>
      {children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingContext, OnboardingProvider };
