import { Modal } from 'common/modal';
import React from 'react';
import UploadImage from 'assets/images/journaling-upload.png';
import RecordingImage from 'assets/images/journaling-recording.png';
import JournalEntriesImage from 'assets/images/journaling-entries.png';
import { classnames } from 'helpers/utils';
import styles from './pick-tutorials.module.scss';

enum JournalsEnum {
  upload = 'upload',
  recording = 'recording',
  journalEntries = 'journalEntries',
}

type JournalContent = {
  name: string;
  image: string;
  action: () => void;
};

type Journals = {
  [key in JournalsEnum]: JournalContent;
};

const journals: Journals = {
  [JournalsEnum.upload]: {
    name: 'Upload documents, videos & images',
    image: UploadImage,
    action: () => {
      // Navigate to "Upload tutorial"
    },
  },
  [JournalsEnum.recording]: {
    name: 'Video recordings',
    image: RecordingImage,
    action: () => {
      // Navigate to "Record tutorial"
    },
  },
  [JournalsEnum.journalEntries]: {
    name: 'Type journal entries',
    image: JournalEntriesImage,
    action: () => {},
  },
};

type JournalingModalProps = {};

const JournalingModal: React.FC<JournalingModalProps> = () => (
  <Modal contentClassName={styles.container} disableManualClosing>
    <div className={styles.content}>
      <div className="text__heading4__primary50">
        How do you want to journal?
      </div>
      <div className="text__body__regular__medium__textNeutral30">
        Are you looking to preserve memories you’ve already created, record
        video responses to prompts or type journal entries?
      </div>
      <div className={styles.cards}>
        {Object.keys(journals).map((tutorial) => (
          <button
            type="button"
            className={classnames(styles.card)}
            onClick={() => {}}
          >
            {tutorial === JournalsEnum.journalEntries && (
              <div
                className={classnames(
                  styles.comingSoonLabel,
                  'text__body__semi__bold__overline__textNeutral30',
                )}
              >
                COMING SOON
              </div>
            )}
            <img
              alt="illustration"
              src={journals[tutorial as JournalsEnum].image}
            />
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral30',
                styles.cardTitle,
              )}
            >
              {journals[tutorial as JournalsEnum].name}
            </div>
          </button>
        ))}
      </div>
    </div>
  </Modal>
);

export { JournalingModal };
