import { Button, ButtonStyle } from 'common/button/button';
import { Modal } from 'common/modal/modal';
import { useContext } from 'react';
import { captureEvent } from 'posthog';
import { UserController } from 'networking/controllers/user-controller';
import { goToPage, RouteName } from 'routes';
import { appActions, AppContext } from 'context';
import { NotificationObject } from 'models/notificationObject';
import { NotificationType } from 'common/enums';
import { trackCustomEvent } from 'helpers/analytics';
import styles from './modal-reminder-access-denied.module.scss';

type ModalReminderAccessDeniedType = {
  onClose: () => void;
  email: string;
  emailMatches: boolean;
};

const ModalReminderAccessDenied = ({
  onClose,
  email,
  emailMatches,
}: ModalReminderAccessDeniedType) => {
  const { dispatch } = useContext(AppContext);

  const logOutUser = async () => {
    captureEvent('logout');
    trackCustomEvent('logOut');
    try {
      await UserController.signOutUser();
      goToPage(RouteName.SignIn);
      dispatch({ type: appActions.SESSION_EXPIRED });
    } catch (e) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Oh no',
          message: 'Something went wrong. Please try again.',
          type: NotificationType.Error,
        }),
      });
    }
  };

  const showMessage = (hadPreviousAccess: boolean) => {
    if (hadPreviousAccess)
      return (
        <div className="text__body__regular__medium__textNeutral40">
          You are currently logged in with the email <b>{email}</b>. You
          previously had access to this album but you have been removed from it
          so you won&apos;t be able to add content.
        </div>
      );
    return (
      <div className="text__body__regular__medium__textNeutral40">
        The link you entered was meant for the account tied to the email{' '}
        <b>${email}</b>. Please log in with that account to have proper access
        to the album.
      </div>
    );
  };

  return (
    <Modal
      title="You do not have access"
      onClose={onClose}
      contentClassName={styles.modalContent}
      className={styles.modal}
    >
      <div className="text__body__regular__medium__textNeutral40">
        {showMessage(emailMatches)}
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={logOutUser} buttonStyle={ButtonStyle.RedGhost}>
          Logout
        </Button>
      </div>
    </Modal>
  );
};

export { ModalReminderAccessDenied };
