import {
  ContributorsAction,
  ContributorsStateType,
} from 'pages/contributors/contributors-reducer';
import {
  classnames,
  getAlbumStatusLabel,
  isLockedAlbum,
  isRestrictedAlbum,
} from 'helpers/utils';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { RouteName, goToPage } from 'routes';
import { ChevronIcon } from 'assets/icons';
import { ReactComponent as Logo } from 'assets/images/logo.svg';
import { InfoLabel } from 'common/info-label';
import { AlbumTabs, InvitationStatusId } from 'common/enums';
import { cloneDeep } from 'lodash';
import { InvitationStatusInfo } from 'config/constants';
import { Dispatch, useCallback, useContext } from 'react';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { NotificationObject } from 'models/notificationObject';
import { AppContext, appActions } from 'context';
import styles from './contributors-rows.module.scss';

const contributorsListTitles: any = {
  contributorName: 'Contributor name',
  email: 'Email',
  backupContact: 'Backup contact',
  album: 'Album',
  actions: '',
};

type ContributorsRowsProps = {
  contributorsState: ContributorsStateType;
  contributorsDispatch: Dispatch<ContributorsAction>;
};

const ContributorsRows: React.FC<ContributorsRowsProps> = ({
  contributorsState,
  contributorsDispatch,
}) => {
  const { dispatch: generalDispatch } = useContext(AppContext);

  const { contributorsList } = contributorsState;

  const invitationAccepted = (contri: ContributorElementType) =>
    contri.invitationStatus === InvitationStatusId.accepted;

  const handleResendInvite = async (contriSelected: ContributorElementType) => {
    const contributorsCopy = cloneDeep(contributorsList);

    const contributorSelected = contributorsCopy.find(
      (contri: ContributorElementType) => contri.idAux === contriSelected.idAux,
    )!;

    try {
      const contributorResponse = await AlbumsController.resendAlbumInvitation(
        contributorSelected.invitationId,
      );

      contributorSelected.user.invitationResent = true;
      contributorSelected.user.invitationStatus =
        contributorResponse.invitationStatus;

      contributorsDispatch({
        type: 'UPDATE_CONTRIBUTORS',
        contributorsList: contributorsCopy,
      });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Resend invitation',
          message: "Invitation couldn't be sent",
        }),
      });
    }
  };

  const showDefaultPicture = () => (
    <div className={styles.defaultpicture}>
      <Logo className={styles.logo} />
    </div>
  );

  const showAlbumStatusLabel = (alb: AlbumType) => {
    const { withIcon, statusValue } = getAlbumStatusLabel(alb);

    return (
      statusValue && (
        <InfoLabel
          withIcon={withIcon}
          content={
            <p
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
              )}
            >
              {statusValue}
            </p>
          }
        />
      )
    );
  };

  const showInvitationStatus = (contri: ContributorElementType) => {
    const invitationStatusId: InvitationStatusId = contri.invitationStatus;

    const iconStyles =
      invitationStatusId === InvitationStatusId.rejected
        ? styles.dangerIconColor
        : '';

    return (
      invitationStatusId !== InvitationStatusId.accepted && (
        <InfoLabel
          className={iconStyles}
          content={
            <p
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
              )}
            >
              {InvitationStatusInfo[invitationStatusId].name}
            </p>
          }
        />
      )
    );
  };

  const showActionsAvailable = useCallback(
    (contri: ContributorElementType) => {
      const invitationStatusId: InvitationStatusId = contri.invitationStatus;

      if (
        invitationStatusId === InvitationStatusId.pending &&
        !contri.user.invitationResent
      ) {
        return (
          <Button
            buttonSize={ButtonSize.Tiny}
            buttonStyle={ButtonStyle.SecondaryGhost}
            className={styles.successButton}
            onClick={() => handleResendInvite(contri)}
          >
            Resend invite
          </Button>
        );
      }

      if (
        invitationStatusId === InvitationStatusId.pending &&
        contri.user.invitationResent
      ) {
        return (
          <span
            className={classnames(
              'text__body__regular__tiny__textNeutral20',
              styles.inviteSent,
            )}
          >
            {' '}
            Invite sent!
          </span>
        );
      }

      if (invitationStatusId === InvitationStatusId.rejected) {
        return (
          <div className={styles.rejectedButtons}>
            <Button
              buttonSize={ButtonSize.Tiny}
              buttonStyle={ButtonStyle.GreyGhost}
              className={styles.successButton}
              onClick={() => handleResendInvite(contri)}
            >
              Invite again
            </Button>
            <Button
              buttonSize={ButtonSize.Tiny}
              buttonStyle={ButtonStyle.RedGhost}
              className={styles.successButton}
              onClick={() => {
                /* TODO */
              }}
            >
              Remove
            </Button>
          </div>
        );
      }

      return null;
    },
    [contributorsList],
  );

  return (
    <div className={styles.contributorsRows}>
      <div className={styles.titles}>
        {Object.keys(contributorsListTitles).map((title) => (
          <div
            key={title}
            className={classnames(
              styles[title],
              'text__body__semi__bold__medium__textNeutral40',
            )}
          >
            {contributorsListTitles[title]}
          </div>
        ))}
      </div>
      <div
        className={classnames(
          styles.body,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        {contributorsList.map((contributor: ContributorElementType) => (
          <div key={contributor.idAux} className={styles.row}>
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral40',
                styles.contributorName,
              )}
            >
              {contributor.user.name}
            </div>

            <div className={classnames(styles.column, styles.email)}>
              {contributor.user.email}
              {!invitationAccepted(contributor) && (
                <>
                  {showInvitationStatus(contributor)}
                  {showActionsAvailable(contributor)}
                </>
              )}
            </div>

            <div className={classnames(styles.column, styles.backupContact)}>
              {contributor.user.backupName
                ? `${contributor.user.backupName} (${contributor.user.backupEmail})`
                : '-'}
            </div>

            <div className={classnames(styles.column, styles.album)}>
              <div className={styles.pictureRounded}>
                {contributor.album.coverThumbnailUrl ? (
                  <img
                    src={contributor.album.coverThumbnailUrl}
                    className={classnames(styles.picture, {
                      [styles.greyImage]:
                        isRestrictedAlbum(contributor.album) ||
                        isLockedAlbum(contributor.album),
                    })}
                    alt="logo"
                  />
                ) : (
                  showDefaultPicture()
                )}
              </div>
              <div>{contributor.album.title}</div>
              {showAlbumStatusLabel(contributor.album)}
            </div>

            <div className={classnames(styles.column, styles.actions)}>
              <Button
                className={styles.detailsButton}
                buttonStyle={ButtonStyle.PrimaryStroke}
                buttonSize={ButtonSize.Small}
                onClick={() => {
                  goToPage(
                    RouteName.AlbumDetail,
                    { id: contributor.album.id },
                    { tab: AlbumTabs.settings },
                  );
                }}
              >
                <span>Album</span>
                <span className={styles.chevronIcon}>
                  <ChevronIcon />
                </span>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { ContributorsRows };
