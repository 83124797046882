/* eslint-disable max-len */
import { AlertTriangleIcon, InfoIcon, LinkIcon } from 'assets/icons';

import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import {
  calculateHoursAndMinutes,
  canUpgradeAlbum,
  classnames,
  isFreeTrial,
  isOwner,
  isTemporaryUser,
} from 'helpers/utils';
import { ProgressBar } from 'common/progress-bar';
import { HelperText } from 'common/helper-text';
import { useContext, useEffect, useState } from 'react';
import { goToPage, RouteName } from 'routes';
import { AppContext, SensorSelectionContext } from 'context';
import {
  apiBaseURL,
  msMaxPercentageToAlertVideoCallTime,
} from 'config/constants';
import { InfoModal } from 'common/info-modal';
import {
  calculateWaitingRoomModalInstructions,
  calculateWaitingRoomPersistentInstructions,
} from 'common/sensor-permission-instructions';
import { useLoadSensorSelection } from 'hooks/use-load-sensor-selection';
import { CopyLink } from 'common/copy-link';
import { AlbumTabs } from 'common/enums';
import {
  VideoCallRole,
  useVideocallWebsocket,
} from 'hooks/use-videocall-websocket';
import { ModalWarning } from 'common/modal-warning';
import { WebcamPreview } from './webcam-preview';
import { WaitingRoomSensorSelection } from './waiting-room-sensor-selection';
import styles from './video-call-waiting-room.module.scss';

type VideoCallWaitingRoomProps = {
  album: AlbumType;
  upgradeAlbum?: () => void;
  handleTabChange?: (tabName: AlbumTabs) => void;
  isGuest?: boolean;
};

const VideoCallWaitingRoom: React.FC<VideoCallWaitingRoomProps> = ({
  album,
  upgradeAlbum,
  handleTabChange,
  isGuest = false,
}) => {
  const [showMediaErrorModal, setShowMediaErrorModal] =
    useState<boolean>(false);
  const [showRunOutClipsWarning, setShowRunOutClipsWarning] =
    useState<Boolean>(false);

  const [showModalSignUp, setShowModalSignUp] = useState<boolean>(false);
  const { state } = useContext(AppContext);

  const { getCallInformation, callInformation, online } = useVideocallWebsocket(
    album?.id || null,
    album.videoCallProps?.videoCallToken || null,
  );

  const videocallInterviewerName = callInformation?.videocallInterviewerName;
  const videocallRespondentName = callInformation?.videocallRespondentName;
  const videocallInterviewerId = callInformation?.videocallInterviewerId;
  const videocallRespondentId = callInformation?.videocallRespondentId;
  const videocallRespondentIsGuest =
    callInformation?.videocallRespondentIsGuest;

  const { user } = state.data;
  const { subscriptionsScheme } = state;

  const {
    sensorSelection: {
      microphone: { error: microphoneError },
      camera: { error: cameraError },
    },
  } = useContext(SensorSelectionContext);

  useLoadSensorSelection();
  useEffect(() => {
    if (online) {
      getCallInformation();
    }
  }, [online]);

  const missingFunctionality = !!microphoneError || !!cameraError;

  useEffect(() => {
    setShowMediaErrorModal(missingFunctionality);
  }, [missingFunctionality]);

  useEffect(() => {
    if (isTemporaryUser(user)) {
      if (
        album.videoCallProps?.usedCallTime === album.videoCallProps?.maxCallTime
      ) {
        setShowModalSignUp(true);
      } else if (album.clipAmount === album.maxClips) {
        setShowRunOutClipsWarning(true);
      }
    }
  }, []);

  const navigateToPlanInterview = handleTabChange
    ? () => handleTabChange(AlbumTabs.addPrompts)
    : () =>
        goToPage(
          RouteName.AlbumDetail,
          { id: album.id },
          { tab: AlbumTabs.addPrompts },
        );

  const managerHelperTextContent = () => (
    <span className="text__body__regular__tiny__textNeutral30">
      <span> Go to &nbsp;</span>
      <span className={styles.plainterviewText}>
        <button type="button" onClick={navigateToPlanInterview}>
          ‘Add prompts’
        </button>
      </span>
      <span>
        &nbsp; to select and order your questions. You can also choose custom
        recordings while on the call.
      </span>
    </span>
  );

  const warningContent = () => {
    const { hours, minutes } = calculateHoursAndMinutes(
      Number(album.videoCallProps!.maxCallTime),
    );
    if (isFreeTrial(album)) {
      return (
        <p className="text__body__regular__tiny__textNeutral30">
          Trial albums have access to {minutes} minutes of video call time. To
          access additional time, please upgrade your subscription
        </p>
      );
    }
    return (
      <p className="text__body__regular__tiny__textNeutral30">
        {hours} hours video recording time per album per subscription year is
        included. You can{' '}
        <button
          className={classnames(
            'text__body__regular__tiny__secondary50',
            styles.underLine,
          )}
          type="button"
        >
          purchase additional time
        </button>
        {canUpgradeAlbum(album, subscriptionsScheme) ? (
          <>
            {' or '}
            <button
              className={classnames(
                'text__body__regular__tiny__secondary50',
                styles.underLine,
              )}
              type="button"
              onClick={upgradeAlbum}
            >
              upgrade your subscription.
            </button>
          </>
        ) : (
          '.'
        )}
      </p>
    );
  };

  const showRunningOutWarning = () => (
    <HelperText
      icon={<AlertTriangleIcon className={styles.alertRunningOutTimeIcon} />}
      content={warningContent()}
      className={styles.runningOutWarning}
    />
  );

  const showTime = (hours: number, minutes: number) => (
    <div
      className={classnames(
        styles.hoursAndMinutes,
        'text__body__regular__small__textNeutral40',
      )}
    >
      {hours > 0 && (
        <div>
          <span>{hours}</span>
          <span>&nbsp;</span>
          <span>{hours > 1 || hours === 0 ? 'hours' : 'hour'}</span>
        </div>
      )}

      {hours > 0 && <span>,&nbsp; </span>}

      <div>
        <span>{minutes}</span>
        <span>&nbsp;</span>
        <span> minutes </span>
      </div>
    </div>
  );

  const showProgressBar = (alb: AlbumType) => {
    const usedCallTime = Number(alb.videoCallProps!.usedCallTime);
    const maxCallTime = Number(alb.videoCallProps!.maxCallTime);

    const videoPercentageUsed = (100 * usedCallTime!) / maxCallTime!;

    const remainingCallTime = maxCallTime - usedCallTime;
    const { hours: usedHours, minutes: usedMinutes } =
      calculateHoursAndMinutes(usedCallTime);
    const { hours: remainingHours, minutes: remainingMinutes } =
      calculateHoursAndMinutes(remainingCallTime);

    return (
      <div className={styles.progressSection}>
        <div
          className={classnames(
            styles.progressTitle,
            'text__body__regular__overline__textNeutral30',
          )}
        >
          album’s ANNUAL video call time
        </div>
        <div>
          <ProgressBar
            percentageUsed={videoPercentageUsed}
            reachingLimit={
              videoPercentageUsed > msMaxPercentageToAlertVideoCallTime
            }
          />
        </div>
        <div className={classnames(styles.progressTimes)}>
          <span className={styles.usedTime}>
            <span className="text__body__regular__small__textNeutral30">
              Used time:&nbsp;
            </span>
            {showTime(usedHours, usedMinutes)}
          </span>

          <span className={styles.maxTime}>
            <span className="text__body__regular__small__textNeutral30">
              {' '}
              Remaining:&nbsp;
            </span>
            {showTime(remainingHours, remainingMinutes)}
          </span>
        </div>

        {user.permissions?.permissions.upgradeAlbums &&
          videoPercentageUsed > msMaxPercentageToAlertVideoCallTime &&
          showRunningOutWarning()}
      </div>
    );
  };

  const persistentInstructions = calculateWaitingRoomPersistentInstructions(
    microphoneError,
    cameraError,
  );

  const [errorModalHeader, errorModalContent] =
    calculateWaitingRoomModalInstructions(microphoneError, cameraError);
  const showMediaErrorModalComponent = () => (
    <InfoModal
      className={styles.modalMediaError}
      title="Camera & Mic"
      content={
        <div className={styles.modalMediaErrorContent}>
          <HelperText
            icon={<AlertTriangleIcon className={styles.modalMediaErrorIcon} />}
            content={
              <span className="text__body__regular__large__textNeutral50">
                {errorModalHeader}
              </span>
            }
          />
          <p>{errorModalContent}</p>
        </div>
      }
      onClose={() => setShowMediaErrorModal(false)}
    />
  );

  const showNotAllowWarning = () => (
    <div className="text__body__regular__tiny__textNeutral30">
      You can’t join the call without a working camera and mic.
    </div>
  );

  const showInterviewTipSection = () => (
    <div className={styles.interviewTipSection}>
      <div
        className={classnames(
          styles.interviewTipTitle,
          'text__body__regular__overline__textNeutral30',
        )}
      >
        interview tip
      </div>

      <HelperText
        icon={<InfoIcon className={styles.textHelpIcon} />}
        content={managerHelperTextContent()}
      />
    </div>
  );

  const showCallMembersInfo = (
    currentUserIsGuest: boolean,
    interviewer?: string,
    respondent?: string,
    respondentIsGuest?: boolean,
  ) => {
    if (interviewer && respondent)
      return (
        <div
          className={classnames(
            styles.callMembersInfo,
            'text__body__regular__medium__textNeutral30',
          )}
        >
          <span className="text__body__semi__bold__medium__primary50">
            {interviewer}
          </span>{' '}
          and{' '}
          <span className="text__body__semi__bold__medium__primary50">
            {respondent}
          </span>{' '}
          are already in this call.
        </div>
      );

    if (interviewer) {
      return (
        <div
          className={classnames(
            styles.callMembersInfo,
            'text__body__regular__medium__textNeutral30',
          )}
        >
          <span className="text__body__semi__bold__medium__primary50">
            {interviewer}
          </span>{' '}
          {`has already joined the call as interviewer. ${
            currentUserIsGuest
              ? ''
              : 'If you join as interviewer they will become the respondent.'
          }`}
        </div>
      );
    }

    return (
      <div
        className={classnames(
          styles.callMembersInfo,
          'text__body__regular__medium__textNeutral30',
        )}
      >
        <span className="text__body__semi__bold__medium__primary50">
          {respondent}
        </span>{' '}
        {`has already joined the call as respondent. ${
          respondentIsGuest
            ? ''
            : 'If you join as respondent they will become the interviewer.'
        }`}
      </div>
    );
  };

  const showCallDescription = () => {
    if (isGuest) {
      return (
        <p className={classnames('text__body__regular__small__textNeutral30')}>
          Join as{' '}
          <span className="text__body__semi__bold__small__textNeutral30">
            respondent
          </span>{' '}
          to answer the questions.
        </p>
      );
    }

    if (videocallRespondentIsGuest) {
      return (
        <p className={classnames('text__body__regular__small__textNeutral30')}>
          Join as{' '}
          <span className="text__body__semi__bold__small__textNeutral30">
            interviewer
          </span>{' '}
          to lead the interview.
        </p>
      );
    }

    return (
      <p className={classnames('text__body__regular__small__textNeutral30')}>
        Join as{' '}
        <span className="text__body__semi__bold__small__textNeutral30">
          interviewer
        </span>{' '}
        if you want to lead the interview, or join as{' '}
        <span className="text__body__semi__bold__small__textNeutral30">
          respondent
        </span>{' '}
        to answer the questions. Send the{' '}
        <span className="text__body__semi__bold__small__textNeutral30">
          invite link
        </span>{' '}
        to invite a guest to join the call.
      </p>
    );
  };

  const showRunOutClipsWarningComponent = () => (
    <ModalWarning
      title="Sign up to save your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            You’ve run out of free trial clips! Please complete the sign up
            process to be able to save your clips and access them later,
            download or share them, as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowRunOutClipsWarning(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  const showModalSignUpVideoCallComponent = () => (
    <ModalWarning
      title="Sign up to save your recordings"
      content={
        <div className={styles.modalSignUpInfo}>
          <div className="text__body__regular__medium__textNeutral40">
            You’ve run out of video call time! Please complete the sign up
            process to be able to save your clips and access them later,
            download or share them, as well as access the rest of our features.
          </div>
          <div className="text__body__regular__medium__textNeutral40">
            Don’t worry, your first album is{' '}
            <span className="text__body__semi__bold__medium__textNeutral40">
              free
            </span>{' '}
            for a month, and signing up only takes 2 minutes!
          </div>
        </div>
      }
      successButtonText="Sign up"
      closeFn={() => setShowModalSignUp(false)}
      successFn={() => goToPage(RouteName.SignUp)}
      successStyleButton={ButtonStyle.PrimaryFilled}
    />
  );

  const showContent = () => {
    const { maxCallTime, usedCallTime } = album.videoCallProps!;
    const timeLimitReached = usedCallTime! >= maxCallTime!;
    const joinCallDisabled =
      !album.videoCallProps?.videoCallToken ||
      !!microphoneError ||
      !!cameraError ||
      timeLimitReached ||
      (!!videocallInterviewerId &&
        (!!videocallRespondentId || videocallRespondentIsGuest));
    const joinAsRespondentDisabled =
      joinCallDisabled ||
      videocallRespondentIsGuest ||
      (!isGuest && !user.permissions?.permissions.joinVideocallAsRespondent);

    return (
      <>
        <div
          className={classnames(
            styles.mainTitle,
            'text__body__regular__overline__textNeutral30',
          )}
        >
          camera & audio preview
        </div>
        <div className={styles.body}>
          <div className={styles.cameraColumn}>
            <div className={styles.cameraBody}>
              <WebcamPreview className={styles.cameraStyles} />
            </div>
            <div className={styles.instructionsContainer}>
              <HelperText
                icon={
                  missingFunctionality ? (
                    <AlertTriangleIcon
                      className={styles.instructionsHelpIcon}
                    />
                  ) : (
                    <InfoIcon className={styles.instructionsHelpIcon} />
                  )
                }
                content={persistentInstructions}
              />
            </div>
            <div className={styles.sensorSelectionContainer}>
              <WaitingRoomSensorSelection />
            </div>
          </div>

          <div className={styles.joinColumn}>
            <div className={styles.joinTitleAndButtons}>
              <div className={styles.joinTitle}>
                <span className={classnames('text__heading4__textNeutral40')}>
                  Joining video call
                </span>
                {/* TODO: For now, we use <CopyLink /> component. Previously we were using <SendLinkToContributor />  */}
                <CopyLink
                  buttonStyle={ButtonStyle.PrimaryGhost}
                  buttonSize={ButtonSize.Small}
                  className={styles.copyLinkButton}
                  path={`${apiBaseURL}/albums/${album.id}/join/${album
                    .videoCallProps?.videoCallToken!}`}
                >
                  <LinkIcon className={styles.linkIcon} />
                  <div className={styles.linkText}> Invite link </div>
                </CopyLink>
              </div>

              {showCallDescription()}

              {(videocallInterviewerName ||
                videocallRespondentName ||
                videocallRespondentIsGuest) &&
                showCallMembersInfo(
                  isGuest,
                  videocallInterviewerName,
                  videocallRespondentName,
                  videocallRespondentIsGuest,
                )}

              <div className={styles.buttons}>
                <Button
                  buttonSize={ButtonSize.Medium}
                  buttonType={ButtonType.Submit}
                  disabled={joinCallDisabled || isGuest}
                  onClick={() => {
                    goToPage(RouteName.AgoraVideoCall, {
                      agoraAlbumId: album.id,
                      videoCallToken: album.videoCallProps?.videoCallToken!,
                      role: VideoCallRole.INTERVIEWER,
                    });
                  }}
                >
                  Join as interviewer
                </Button>
                <Button
                  buttonStyle={ButtonStyle.PrimaryStroke}
                  buttonSize={ButtonSize.Medium}
                  disabled={joinAsRespondentDisabled}
                  onClick={() => {
                    goToPage(RouteName.AgoraVideoCall, {
                      agoraAlbumId: album.id,
                      videoCallToken: album.videoCallProps?.videoCallToken!,
                      role: VideoCallRole.RESPONDENT,
                    });
                  }}
                  tooltip={
                    !isGuest &&
                    !user.permissions?.permissions.joinVideocallAsRespondent
                  }
                  tooltipContent="Available after sign up"
                  tooltipId="join-as-respondent-tooltip"
                >
                  Join as respondent
                </Button>
              </div>
            </div>

            {!isOwner(user, album) && cameraError && showNotAllowWarning()}

            {!isGuest && showProgressBar(album)}

            {!isGuest && showInterviewTipSection()}
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {showRunOutClipsWarning && showRunOutClipsWarningComponent()}
      {showMediaErrorModal && showMediaErrorModalComponent()}
      {showModalSignUp && showModalSignUpVideoCallComponent()}
      <div className={styles.fullcontent}>{showContent()}</div>
    </>
  );
};

export { VideoCallWaitingRoom };
