import { Modal } from 'common/modal';
import { useEffect } from 'react';
import { msToRecallMeEndpoint } from 'config/constants';
import { SubscriptionStatus } from 'common/enums';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { PaymentInProgress } from 'common/payment-in-progress';
import styles from './modal-processing-album-payment.module.scss';

type ModalProcessingAlbumPaymentProps = {
  title: string;
  albumResponse: AlbumType;
  disableManualClosing?: boolean;
  closeFn: (album: AlbumType) => void;
  errorFn: () => void;
};

const ModalProcessingAlbumPayment: React.FC<
  ModalProcessingAlbumPaymentProps
> = ({ title, albumResponse, disableManualClosing, closeFn, errorFn }) => {
  const verifyAlbumSubscriptionStatus = async () => {
    try {
      const album = await AlbumsController.getAlbum(albumResponse.id);
      const { subscriptionStatus } = album;

      if (
        subscriptionStatus === SubscriptionStatus.subscriptionActive ||
        subscriptionStatus === SubscriptionStatus.paymentFailed
      ) {
        closeFn(album);
      }
    } catch (err: any) {
      errorFn();
    }
  };

  useEffect(() => {
    const timer: NodeJS.Timeout = setInterval(() => {
      verifyAlbumSubscriptionStatus();
    }, msToRecallMeEndpoint);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal
      className={styles.processingContent}
      title={title}
      disableManualClosing={disableManualClosing}
    >
      <PaymentInProgress />
    </Modal>
  );
};

export { ModalProcessingAlbumPayment };
