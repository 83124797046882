import React, {
  useState,
  useEffect,
  ChangeEvent,
  useContext,
  FormEvent,
} from 'react';
import { goToPage, RouteName } from 'routes';
import { UserController } from 'networking/controllers/user-controller';
import { LocalStorageApi } from 'helpers/local-storage';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { Line } from 'common/line';
import { Spinner } from 'common/spinner';
import { Input } from 'common/input';
import {
  classnames,
  hasNotUserValidatedEmail,
  isEmailValid,
  noEmptyFields,
  noErrors,
  redictDependingFailingStatus,
} from 'helpers/utils';
import { EyeIcon } from 'assets/icons';
import { Notification } from 'common/notification';
import { UserSignIn } from 'models/user-sign-in';
import { AppContext } from 'context';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { SignHeader } from 'common/sign-header';
import { Footer, FooterType } from 'common/footer';
import { errorsCode, isBeta } from 'config/constants';
import { captureEvent } from 'posthog';
import CypressIds from 'cypressIds';
import { trackCustomEvent } from 'helpers/analytics';
import { history } from '../../routes/routes';
import styles from './sign-in.module.scss';

type Mandatory = {
  email: string;
  password: string;
};

const mandatoryFieldsErrors: Mandatory = {
  email: '',
  password: '',
};

const SignIn: React.FC = () => {
  const [userState, setUserState] = useState<UserSignInType>(new UserSignIn());
  const [errors, setErrors] = useState<MandatoryFieldsType>(
    mandatoryFieldsErrors,
  );
  const [inputPassword, setInputPassword] = useState<boolean>(true);
  const [fetching, setFetching] = useState(true);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showError, setShowError] = useState(false);

  const { state } = useContext(AppContext);

  const previousUrl = LocalStorageApi.get('previous-url');

  const auth = async () => {
    if (state.logged) {
      const wasRedirected = redictDependingFailingStatus(state.data.user);

      if (!wasRedirected) {
        goToPage(RouteName.Albums);
      }

      return;
    }

    setFetching(true);

    try {
      const data = await UserController.me();

      const wasRedirected = redictDependingFailingStatus(data.user);

      if (!wasRedirected) {
        goToPage(RouteName.Albums);
      }
    } catch (e) {
      setShowSignIn(true);
    } finally {
      setFetching(false);
    }
  };

  const getEmailQueryParam = () =>
    new URLSearchParams(window.location.search).get('email') || null;

  useEffect(() => {
    auth();

    const contributorEmail = getEmailQueryParam();

    if (contributorEmail) {
      setUserState((prevUserState) => ({
        ...prevUserState,
        email: contributorEmail,
      }));
    }

    return () => {
      if (!hasNotUserValidatedEmail(state.data.user)) {
        LocalStorageApi.set('previous-url', '');
      }
    };
  }, []);

  const addBackEndErrorsToFields = (backEndErrors: MandatoryFieldsType) => {
    const errorsCopy = { ...errors };

    Object.keys(errorsCopy).forEach((field) => {
      errorsCopy[field] = backEndErrors[field]
        ? backEndErrors[field].toUpperCase()
        : '';
    });

    setErrors(errorsCopy);
  };

  const validateField = <T extends keyof Mandatory>(field: T) => {
    const emptyFieldMessage =
      userState[field].trim() === ''
        ? `${field.toUpperCase()} shouldn't be empty`
        : '';
    setErrors((prevState) => ({
      ...prevState,
      [field]: emptyFieldMessage.toUpperCase(),
    }));

    if (field === 'email') {
      const emailMessage = !isEmailValid(userState[field])
        ? 'invalid email'
        : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: emailMessage.toUpperCase(),
      }));
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });

    const { email, password } = userState;

    if (!noErrors(errors) || !noEmptyFields({ email, password })) {
      return;
    }

    setFetching(true);

    try {
      const data = await UserController.signInUser(userState);

      trackCustomEvent('logIn');

      const wasRedirected = redictDependingFailingStatus(data.user);

      if (!wasRedirected) {
        if (previousUrl) {
          history.push(previousUrl);
        } else {
          goToPage(RouteName.Albums);
        }
      }
    } catch (err: any) {
      const errorCredentials = err?.errors?.find(
        (e: any) => e.errorCode === errorsCode.credentialsError,
      );

      if (errorCredentials) {
        const objectErrors = {
          email: ' ',
          password: 'Incorrect email or password.',
        };

        addBackEndErrorsToFields!(objectErrors);
      } else {
        setShowError(true);
      }
      setFetching(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserState((prevUserState) => ({ ...prevUserState, [name]: value }));
  };

  const showSignUpButton = () => (
    <>
      <Line />
      <div className={styles.footer}>
        <p className="text__body__regular__small__textNeutral30">
          {' '}
          Don’t have an account?{' '}
        </p>

        <Button
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          buttonSize={ButtonSize.Small}
          onClick={() => goToPage(RouteName.SignUp)}
          dataCy={CypressIds.goToSignUp}
        >
          Sign up
        </Button>
      </div>
    </>
  );

  const onForgotPassword = () => {
    captureEvent('forgotPassword');
    trackCustomEvent('startForgotPassword');
    goToPage(RouteName.ForgotPassword);
  };

  const showSignInView = () => (
    <form
      noValidate
      onSubmit={onSubmit}
      className={globalStyles.signBoxContent}
    >
      <div className={styles.elements}>
        <SignHeader />
        <div
          className={classnames(
            styles.titleView,
            'text__title1__textNeutral40',
            'mb-15',
          )}
        >
          Log in
        </div>

        <div className="mb-6">
          <Input
            id="email"
            name="email"
            label="Email"
            placeholder="email@example.com"
            value={userState.email}
            type="email"
            errorMessage={errors.email}
            onChange={handleChange}
            onBlur={() => validateField('email')}
            dataCy={CypressIds.emailSignin}
          />
        </div>
        <div>
          <Input
            id="password"
            name="password"
            label="Password"
            placeholder="password"
            value={userState.password}
            type={inputPassword ? 'password' : 'text'}
            errorMessage={errors.password}
            withIconEnd={
              <EyeIcon onClick={() => setInputPassword(!inputPassword)} />
            }
            onChange={handleChange}
            onBlur={() => validateField('password')}
            dataCy={CypressIds.passwordSignin}
          />
        </div>
        <Button
          buttonStyle={ButtonStyle.PrimaryGhostLink}
          buttonSize={ButtonSize.Small}
          className={classnames(styles.forgotLink)}
          onClick={() => onForgotPassword()}
        >
          Forgot your password?
        </Button>

        <Button
          className={classnames(
            styles.loginButton,
            'my-20',
            fetching ? 'noClick fixSizeFetching' : '',
          )}
          buttonType={ButtonType.Submit}
          buttonSize={ButtonSize.Medium}
          dataCy={CypressIds.loginButton}
        >
          {fetching ? (
            <Spinner color="white" className="spinner-inside-button" />
          ) : (
            <> Log in </>
          )}
        </Button>

        {!isBeta && showSignUpButton()}
      </div>
    </form>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <div className={globalStyles.signMainContainer}>
      {showSignIn ? (
        <>
          {showError && showErrorNotification()}
          {showSignInView()}
          <Footer footerType={FooterType.NoSignedUser} />
        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export { SignIn };
