import React, { useEffect } from 'react';
import {
  Route,
  Router as VendorRouter,
  Switch,
  useLocation,
} from 'react-router-dom';

import posthog from 'posthog-js';
import { trackPageView } from 'helpers/analytics';
import { history, RouteName, routes } from './routes';
import { ScrollToTop } from './scroll-to-top';

type RouteConfig = {
  name: RouteName;
  component: React.ComponentType;
};

type RouterProps = {
  routeConfig: RouteConfig[];
};

const zipRouteData = (routeData: RouteConfig[]) =>
  routeData.map((data) => {
    const route = routes.find((r) => r.name === data.name);
    return {
      ...data,
      ...route,
    };
  });

const renderRoutes = (routeData: RouteConfig[]) =>
  zipRouteData(routeData).map((data) => (
    <Route key={data.path} exact path={data.path} component={data.component} />
  ));

// Navigation tracking must be done inside of the Router component
const NavigationTracker = () => {
  const location = useLocation();

  useEffect(() => {
    posthog.capture('$pageview');
    trackPageView(location.pathname);
  }, [location]);

  return null;
};

const Router = (props: RouterProps) => (
  <VendorRouter history={history}>
    <NavigationTracker />
    <ScrollToTop>
      <Switch>{renderRoutes(props.routeConfig)}</Switch>
    </ScrollToTop>
  </VendorRouter>
);

export { Router };
