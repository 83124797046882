import { analyticsKey } from 'config/constants';

const trackUserId = (userId?: number) => {
  if (analyticsKey && window.gtag) {
    window.gtag('config', analyticsKey, {
      user_id: userId,
    });
  }
};

const trackPageView = (pathName: string) => {
  if (analyticsKey && window.gtag) {
    window.gtag('config', analyticsKey, {
      page_path: pathName,
    });
  }
};

const trackCustomEvent = <T extends keyof AnalyticsEvent>(
  event: T,
  eventParams: AnalyticsEvent[T] | {} = {},
) => {
  if (analyticsKey && window.gtag) {
    window.gtag('event', event, {
      ...eventParams,
    });
  }
};

type AnalyticsEvent = {
  // Login / Sign up
  startSignUp: {};
  completeSignUp: {};
  logIn: {};
  logOut: {};
  startForgotPassword: {};
  sendPasswordResetEmail: {};
  // Album creation
  startAlbumCreation: {
    step: 'home' | 'account';
  };
  startAlbumCreationCancel: {};
  confirmAlbumCreationCancel: {};
  createAlbumNext: {};
  applyDiscountCode: {
    step: 'createAlbum' | 'account';
    discountCode: string;
  };
  startRemoveDiscountCode: {
    discountCode: string;
  };
  confirmRemoveDiscountCode: {
    discountCode: string;
  };
  startAddContributor: {
    step: 'createAlbum' | 'aboutAlbum';
  };
  confirmAddContributor: {
    step: 'createAlbum' | 'aboutAlbum';
    contributorName: string;
    contributorEmail: string;
  };
  startRemoveContributor: {
    step: 'createAlbum' | 'aboutAlbum';
  };
  confirmRemoveContributor: {
    step: 'createAlbum' | 'aboutAlbum';
    contributorName: string;
    contributorEmail: string;
  };
  createNewAlbum: {};
  startAddPaymentMethod: {
    step: 'createAlbum' | 'account';
  };
  cancelAddPaymentMethod: {};
  confirmAddPaymentMethod: {};
  confirmAlbumPayment: {};
  cancelAlbumPayment: {};
  // Album details
  copyLinkToAlbum: {};
  openAlbumMenu: {};
  startEditAlbum: {};
  confirmEditAlbum: {};
  startDeleteAlbum: {};
  confirmDeleteAlbum: {};
  startRequestAlbumDownload: {};
  confirmRequestAlbumDownload: {};
  selectAlbumTab: {
    tab: 'clips' | 'planInterview' | 'videoCall' | 'recordUpload' | 'about';
  };
  startUpgradeAlbum: {};
  confirmUpgradeAlbum: {};
  // Clips
  startEditClip: {
    step: 'clipDetail' | 'clipMenu';
  };
  confirmEditClip: {};
  attachClipImage: {};
  makeClipPrivate: {
    step: 'clipDetail' | 'clipMenu';
  };
  makeClipPublic: {
    step: 'clipDetail' | 'clipMenu';
  };
  turnClipIntoAudioOnly: {
    step: 'clipDetail' | 'clipMenu';
  };
  turnClipIntoVideo: {
    step: 'clipDetail' | 'clipMenu';
  };
  copyClipLink: {
    step: 'clipDetail' | 'clipMenu';
  };
  startSendClipByEmail: {};
  confirmSendClipByEmail: { email: string };
  downloadClip: {
    step: 'clipDetail' | 'clipMenu';
  };
  startDeleteClip: {};
  confirmDeleteClip: {};
};

export { trackUserId, trackPageView, trackCustomEvent };
