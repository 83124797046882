import { Modal } from 'common/modal';
import React from 'react';
import { classnames } from 'helpers/utils';
import styles from './pick-tutorials.module.scss';
import { TutorialContent } from './pick-tutorials-modal';

type SelectedTutorialsModalProps = {
  selectedTutorials: TutorialContent[];
};

const SelectedTutorialsModal: React.FC<SelectedTutorialsModalProps> = ({
  selectedTutorials,
}) => (
  <Modal contentClassName={styles.container} disableManualClosing>
    <div className={styles.content}>
      <div className="text__heading4__primary50">
        Okay! Where do you want to start?
      </div>
      <div className={classnames(styles.cards, styles.cards3columns)}>
        {selectedTutorials.map((tutorial) => (
          <button
            type="button"
            className={classnames(styles.card)}
            onClick={tutorial.action}
          >
            <img alt="illustration" src={tutorial.image} />
            <div
              className={classnames(
                'text__body__semi__bold__medium__textNeutral30',
                styles.cardTitle,
              )}
            >
              {tutorial.name}
            </div>
          </button>
        ))}
      </div>
    </div>
  </Modal>
);

export { SelectedTutorialsModal };
