/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  ReactNode,
  useMemo,
} from 'react';
import { Spinner } from 'common/spinner';
import { appActions, AppContext } from 'context';
import { AlbumsController } from 'networking/controllers/albums-controller';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import {
  classnames,
  getAllMediaQueries,
  getIndexClipSelectedInList,
  copyToClipboard,
  anyClipInProcess,
  isRestrictedAlbum,
  getSubscriptionSchemeById,
  convertSecondsToTimeUnit,
  isFreeTrial,
  isOwner,
  isContributor,
  getAlbumStatusLabel,
  isReadOnlySubOrRestrictedAlbum,
  isReadOnly,
  getPermissionsByAlbum,
  isChromeVersionWithIssues,
  totalAlbumVideoClips,
  canUpgradeAlbum,
  hasAlbumReachedLimitMessage,
  albumHasPublicClip,
  userPermissionUpgrade,
  isTemporaryUser,
  isAnyLoggedUser,
} from 'helpers/utils';
import { Album } from 'models/album';
import { useClickOutside } from 'hooks/click-outside';
import { useParams } from 'react-router-dom';
import { UsersIcon, MailIcon, UserIcon } from 'assets/icons';
import { goToPage, RouteName } from 'routes';
import { ModalWarning } from 'common/modal-warning';
import { VideoClip } from 'common/video-clip';
import { InfoModal } from 'common/info-modal';
import { Menu } from 'common/menu';
import { NotificationObject } from 'models/notificationObject';
import {
  ImageSupportedExtensions,
  apiBaseURL,
  confirmationMsgLetUploadVideo,
  errorsCodeInPayments,
  mbAlbumCoverMaxSize,
  msToRecallAlbumDetailEndpoint,
  storeLinks,
} from 'config/constants';
import { getRouteFor } from 'routes/route-helpers';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { AllClipsList } from 'common/all-clips-list';
import {
  AlbumTabs,
  Breakpoints,
  ClipActions,
  NavSections,
  NotificationType,
  PaymentType,
  SubscriptionSchemeId,
  TimeUnit,
} from 'common/enums';
import { Clip } from 'models/clip';
import { AlbumLimitedConditionWarning } from 'common/album-limited-condition-warning';
import { AllPaymentProcess } from 'common/all-payment-process';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { useFeatureFlags } from 'hooks/use-feature-flags';
import { captureEvent } from 'posthog';
import { InfoLabel } from 'common/info-label';
import { QrCodesDetailsModal } from 'common/qr-codes-details-modal';
import { QrCodesExplanationModal } from 'common/qr-codes-explanation-modal';
import { AudioClip } from 'common/audio-clip';
import { FileUploader } from 'common/file-uploader';
import { AudioPlayer } from 'common/audio-player';
import { SelfRecordingContext } from 'context/self-recording-context';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { AlbumLimitedConditionWarningType } from 'common/album-limited-condition-warning/album-limited-condition-warning';
import { ModalCardDeclined } from 'common/modal-card-declined';
import { AddPaymentMethodModal } from 'common/add-payment-method-modal';
import { BuyClipsProcess } from 'common/buy-clips-process';
import { ModalTemporaryUser } from 'common/modal-record-email/modal-temporary-user';
import { useMediaQuery } from 'hooks/use-media-query';
import { trackCustomEvent } from 'helpers/analytics';
import { ModalEditAlbum } from 'common/modal-edit-album';
import { InviteToRecordProcess } from 'common/invite-to-record-process';
import {
  HelpInfoLinks,
  HelpInfoSections,
} from 'context/app-context/context-reducer';
import { ModalEditClip } from './components/modal-edit-clip';
import { PlanInterview } from './components/plan-interview';
import { ClipData } from './components/clip-data';
import { VideoCallWaitingRoom } from './components/video-call-waiting-room';
import { AboutAlbum } from './components/about-album';
import styles from './album-detail.module.scss';
import {
  AlbumTab,
  UpgradeAlbumTab,
  AlbumTabMenu,
} from './components/album-tab';
import { RequestAlbumDownloadModal } from './components/request-album-download-modal';
import { RecordUpload } from './components/record-upload';
import { OptionMenuType } from './components/album-tab/album-tab-menu';

type ParamType = {
  id: string;
};

const SHOW_PLAYLISTS = false;

enum RecordOptions {
  selfRecording = 'Record myself',
  videoCall = 'Record on video call',
  invite = 'Invite someone to record',
}

const AlbumDetail: React.FC = () => {
  const openUpgradeAlbum = !!new URLSearchParams(window.location.search).get(
    'upgradeAlbum',
  );

  const getTabByQueryParam = () => {
    const selectedTab = new URLSearchParams(window.location.search).get(
      'tab',
    ) as AlbumTabs;
    if (Object.values(AlbumTabs).includes(selectedTab)) {
      if (
        [AlbumTabs.selfRecording, AlbumTabs.videoCall].includes(selectedTab)
      ) {
        return AlbumTabs.recordAnswers;
      }
      return selectedTab;
    }

    return AlbumTabs.clips;
  };

  const getRecordOptionByQueryParam = () => {
    const selectedTab = new URLSearchParams(window.location.search).get(
      'tab',
    ) as AlbumTabs;
    switch (selectedTab) {
      case AlbumTabs.recordAnswers:
      case AlbumTabs.selfRecording:
        return RecordOptions.selfRecording;
      case AlbumTabs.videoCall:
        return RecordOptions.videoCall;
      default:
        return null;
    }
  };

  const getClipByQueryParam = () => {
    const clipId = new URLSearchParams(window.location.search).get('clipId');

    return clipId ? +clipId : null;
  };

  const { dispatch, state } = useContext(AppContext);
  const {
    state: { isRecording, isUploading, uploadMode },
    dispatch: dispatchSelfRecording,
  } = useContext(SelfRecordingContext);
  const [album, setAlbum] = useState<AlbumType>(new Album());

  const [selectedTab, setSelectedTab] =
    useState<AlbumTabs>(getTabByQueryParam());
  const [clipSelected, setClipSelected] = useState<Clip>(new Clip());

  const [fetching, setFetching] = useState(true);

  const [showDeleteAlbumModal, setShowDeleteAlbumModal] = useState(false);
  const [showRequestAlbumDownloadModal, setShowRequestAlbumDownloadModal] =
    useState(false);
  const [showEditAlbumModal, setShowEditAlbumModal] = useState(false);
  const [showEditClipModal, setShowEditClipModal] = useState(false);
  const [isThereAPublicClip, setIsThereAPublicClip] = useState(false);
  const [showQrCodesModal, setShowQrCodesModal] = useState(false);
  const [showQrCodesExplanationModal, setShowQrCodesExplanationModal] =
    useState(false);
  const [showLeavePageModal, setShowLeavePageModal] = useState(false);
  const [showModalLeaveTemporary, setShowModalLeaveTemporary] = useState(false);
  const [leavePageCallback, setLeavePageCallback] = useState<
    (() => void) | undefined
  >(undefined);
  const [showClipsListEnlarged, setShowClipsListEnlarged] = useState(false);

  const [clipsOrder, setClipsOrder] = useState<ClipType[] | null>(null);

  const [autoPlayingVideo, setAutoPlayingVideo] = useState(false);

  const [showAlbumOptions, setShowAlbumOptions] = useState(false);

  const [showLearnMoreSubscriptionModal, setShowLearnMoreSubscriptionModal] =
    useState(false);
  const [startPaymentProcess, setStartPaymentProcess] = useState(false);

  const [clipsBeingProccesed, setClipsBeingProccesed] = useState<
    boolean | 'initial'
  >('initial');
  const containerAlbumOptionsRef = useRef(null);
  const clipIdUpload = useRef<number | null>(getClipByQueryParam());

  const [showBuyClips, setShowBuyClipsModal] = useState<boolean>(false);

  const [showCardDeclinedModal, setShowCardDeclinedModal] = useState(false);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [showModalSignUp, setModalSignUp] = useState(false);

  const [recordOptionSelected, setRecordOptionSelected] =
    useState<RecordOptions | null>(getRecordOptionByQueryParam());

  const [showInviteToRecordProcess, setShowInviteToRecordProcess] =
    useState(false);

  const {
    subscriptionsScheme,
    data: { user },
  } = state;

  const setApiError = useHandleApiError(() => {
    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({ show: true }),
    });
  });

  useClickOutside(containerAlbumOptionsRef, () => setShowAlbumOptions(false));

  const mediaQueries = getAllMediaQueries();

  const screenLowerXL = useMediaQuery(`(max-width: ${Breakpoints.xl}px)`);

  const auxId = useRef<number>(1000);

  const tabsRef = useRef<HTMLDivElement>(null);

  const { id: albumIdParam } = useParams<ParamType>();

  const { albumDownloadEnabled } = useFeatureFlags();

  const maxQuestionAllowed = album.maxClips;

  const albumPermissions =
    subscriptionsScheme && album.subscriptionType
      ? getPermissionsByAlbum(subscriptionsScheme, album)
      : undefined;

  const showAlbumDownload = useMemo(() => {
    if (!albumDownloadEnabled || !album.clips) return false;

    // Disable download if there are no clips with recordings attached
    return album.clips?.filter((clip: ClipType) => clip.videoUrl).length > 0;
  }, [albumDownloadEnabled, album.clips]);

  const fetchAlbum = async (shouldUpdateClipSelected: boolean = true) => {
    try {
      const response = await AlbumsController.getAlbum(+albumIdParam);

      if (response.clips?.length) {
        if (shouldUpdateClipSelected || response.clips.length === 1) {
          const firstClip = response.clips[0];
          setClipSelected(firstClip);
        }
      }

      setAlbum(response);
    } catch (err: any) {
      goToPage(RouteName.AlbumDetailGuest, { id: albumIdParam });
    } finally {
      setFetching(false);
    }
  };

  const handleTabClose = (event: any) => {
    if ([AlbumTabs.upload, AlbumTabs.recordAnswers].includes(selectedTab)) {
      /* eslint-disable-next-line no-param-reassign, no-return-assign */
      return (event.returnValue = confirmationMsgLetUploadVideo);
    }
    return null;
  };

  const handleClickQrIcon = () => {
    setShowQrCodesModal(true);
  };

  const startUpgradeProcess = () => {
    setStartPaymentProcess(true);
    trackCustomEvent('startUpgradeAlbum');
  };

  useEffect(() => {
    if (isTemporaryUser(user)) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.returnValue = '';
        setShowModalLeaveTemporary(true);
        event.preventDefault();
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
    return () => {};
  }, []);

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.albums,
    });
    fetchAlbum();
  }, []);

  useEffect(() => {
    if (state.refetchAlbum) {
      fetchAlbum();
      dispatch({
        type: appActions.SET_REFETCH_ALBUM,
        refetchAlbum: false,
      });
    }
  }, [state.refetchAlbum]);

  useEffect(() => {
    fetchAlbum();
  }, [selectedTab]);

  useEffect(() => {
    if (album.id !== -1) {
      dispatch({
        type: appActions.SET_CURRENT_ALBUM,
        currentAlbum: album,
      });
    }

    return () => {
      dispatch({
        type: appActions.SET_CURRENT_ALBUM,
      });
    };
  }, [album]);

  useEffect(() => {
    if (isChromeVersionWithIssues()) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Problems with your browser version',
          message:
            'Your browser might experience issues playing back videos. Consider updating your browser to get the best experience.',
          type: NotificationType.Error,
          limitTimeMs: 15000,
        }),
      });
    }
  }, []);

  const albumDataExists = () => album.id > -1;

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY; // position of scroll in y
      const windowHeight = window.innerHeight;

      if (scrollPos + windowHeight > 1200) {
        setShowClipsListEnlarged(true);
      } else {
        setShowClipsListEnlarged(false);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);
    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [selectedTab]);

  useEffect(() => {
    setIsThereAPublicClip(albumHasPublicClip(album));

    if (clipIdUpload.current && album.id > 0) {
      const newClipSelected = album.clips?.find(
        (clip: ClipType) => clip.id === clipIdUpload.current,
      );

      if (newClipSelected) {
        setClipSelected(newClipSelected);
      }
    }

    if (clipsBeingProccesed === 'initial' && albumDataExists()) {
      if (anyClipInProcess(album.unansweredQuestions)) {
        setClipsBeingProccesed(true);
      } else {
        setClipsBeingProccesed(false);
      }
    }

    // For final clips update
    if (
      clipsBeingProccesed === true &&
      !anyClipInProcess(album.unansweredQuestions)
    ) {
      setClipsBeingProccesed(false);
    }
  }, [album]);

  useEffect(() => {
    if (albumDataExists() && openUpgradeAlbum) {
      setStartPaymentProcess(true);
    }
  }, [albumDataExists()]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAnyLoggedUser(user)) {
      if (clipsBeingProccesed === true) {
        timer = setInterval(() => {
          fetchAlbum(false);
        }, msToRecallAlbumDetailEndpoint);
      }

      // Final clips update
      if (clipsBeingProccesed === false) {
        // Wait 12 seconds before fetch the album to ensure the thumbnail is created
        timer = setTimeout(() => {
          fetchAlbum(false);
        }, msToRecallAlbumDetailEndpoint * 4);
      }

      return () => {
        clearInterval(timer);
      };
    }
    return () => {};
  }, [clipsBeingProccesed]);

  const assignNewSelectedClipAfterFinishing = (
    clipList: ClipType[],
    indexClipSelected: number,
  ) => {
    if (indexClipSelected !== clipList.length - 1) {
      setAutoPlayingVideo(true);

      const newSelectedClip = clipList[indexClipSelected + 1];
      setClipSelected(newSelectedClip);
    } else {
      setAutoPlayingVideo(false);
    }
  };

  const assignClipSelected = (
    deleteClipSelected: boolean,
    indexClipDeleted?: number,
  ) => {
    if (album.clips) {
      if (deleteClipSelected && indexClipDeleted) {
        if (indexClipDeleted === album.clips.length - 1) {
          setClipSelected(album.clips[0]);
        } else {
          setClipSelected(album.clips[indexClipDeleted + 1]);
        }
      } else {
        setClipSelected(clipSelected);
      }
    }
  };

  const handleDeleteClip = async (clipIdToDelete: number) => {
    try {
      const deleteClipSelected = clipIdToDelete === clipSelected.id;

      const indexClipDeleted = await album.clips?.findIndex(
        (item) => item.id === clipIdToDelete,
      );

      await AlbumsController.deleteClip(clipIdToDelete);

      await fetchAlbum();

      assignClipSelected(deleteClipSelected, indexClipDeleted);
    } catch (err: any) {
      setApiError(err);
    }
  };

  const updateClipEditedInAlbum = (clipResponse: ClipType): void => {
    const clipsUpdated = album.clips!.map((clip: ClipType) => {
      if (clip.id === clipResponse.id) {
        return clipResponse;
      }

      return clip;
    });

    setAlbum({ ...album, clips: clipsUpdated });
  };

  const handleToggleAudioOnly = async (clipToChange: ClipType) => {
    try {
      const { name, description, isPrivate } = clipToChange;

      const requestData: ClipEditRequestType = {
        name,
        description,
        isPrivate,
        isAudioOnly: !clipToChange.isAudioOnly,
      };

      const clipResponse = await AlbumsController.editClip(
        clipToChange.id,
        requestData,
      );

      updateClipEditedInAlbum(clipResponse);

      if (clipToChange.id === clipSelected.id) {
        setClipSelected(clipResponse);
      }
    } catch (err: any) {
      setApiError(err);
    }
  };

  const handleTogglePrivate = async (clipToChange: ClipType) => {
    try {
      const { name, description, isAudioOnly } = clipToChange;

      const requestData: ClipEditRequestType = {
        name,
        description,
        isAudioOnly,
        isPrivate: !clipToChange.isPrivate,
      };

      const clipResponse = await AlbumsController.editClip(
        clipToChange.id,
        requestData,
      );

      updateClipEditedInAlbum(clipResponse);

      if (clipToChange.id === clipSelected.id) {
        setClipSelected(clipResponse);
      }
    } catch (err: any) {
      setApiError(err);
    }
  };

  const deleteAlbumAction = async () => {
    try {
      await AlbumsController.deleteAlbum(+albumIdParam);
      goToPage(RouteName.Albums);
    } catch (err: any) {
      setApiError(err);
    }
  };

  const handleEditClip = () => {
    setShowEditClipModal(true);
    setAutoPlayingVideo(false);
  };

  const handleDeleteAlbumModal = () => {
    setShowDeleteAlbumModal(true);
    setAutoPlayingVideo(false);
    trackCustomEvent('startDeleteAlbum');
  };

  const handleClipAction = (
    action: ClipActions,
    clip: ClipType,
    origin: 'clipDetail' | 'clipMenu',
    extraData?: ClipExtraDataType,
  ) => {
    const analyticEventParams = { step: origin };
    switch (action) {
      case 'select':
        setClipSelected(clip);
        if (screenLowerXL) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (extraData?.autoPlaying) {
          setAutoPlayingVideo(true);
        }
        break;
      case 'delete':
        trackCustomEvent('confirmDeleteClip');
        handleDeleteClip(clip.id);
        break;
      case 'togglePrivate': {
        const event = clip.isPrivate ? 'makeClipPublic' : 'makeClipPrivate';
        handleTogglePrivate(clip);
        trackCustomEvent(event, analyticEventParams);
        break;
      }
      case 'toggleAudioOnly': {
        const event = clip.isAudioOnly
          ? 'turnClipIntoVideo'
          : 'turnClipIntoAudioOnly';
        handleToggleAudioOnly(clip);
        trackCustomEvent(event, analyticEventParams);
        break;
      }
      case 'editClip':
        setClipSelected(clip);
        trackCustomEvent('startEditClip', analyticEventParams);
        handleEditClip();
        break;
      default:
        throw new Error('Unhandled action type');
    }
  };

  const deleteModalTitle = () => <div> If you delete this album: </div>;

  const deleteModalTransferText = () => (
    <div>
      {' '}
      If you prefer to transfer this album to the contributor or another owner,
      please contact support.{' '}
    </div>
  );

  const deleteStandardOrReadOnlyBodyText = () => (
    <>
      {deleteModalTitle()}

      <ul className={styles.proItemsDeleteModal}>
        <li> The album cannot be recovered. </li>
        <li> You will lose any remaining time on your paid subscription. </li>
        <li> Contributors will lose access to their album. </li>
        <li>
          {' '}
          If there are QR codes associated with the album/clip, they will no
          longer be operational.{' '}
        </li>
      </ul>

      {deleteModalTransferText()}
    </>
  );

  const deleteFreeTrialBodyText = () => (
    <>
      {deleteModalTitle()}

      <ul className={styles.proItemsDeleteModal}>
        <li> The album cannot be recovered. </li>
        <li> The contributor will lose access to their album. </li>
      </ul>
    </>
  );

  const deleteLegacyBodyText = () => (
    <>
      {deleteModalTitle()}

      <ul className={styles.proItemsDeleteModal}>
        <li> The album cannot be recovered. </li>
        <li> You will lose any remaining time on your paid subscription. </li>
        <li> Contributors will lose access to their album. </li>
        <li>
          {' '}
          If there are QR codes associated with the album/clip, they will no
          longer be operational.{' '}
        </li>
      </ul>

      {deleteModalTransferText()}
    </>
  );

  const deleteAlbumModalContent = () => {
    let bodyText;

    switch (album.subscriptionType) {
      case SubscriptionSchemeId.standard:
        bodyText = deleteStandardOrReadOnlyBodyText();
        break;
      case SubscriptionSchemeId.trial:
        bodyText = deleteFreeTrialBodyText();
        break;
      case SubscriptionSchemeId.legacy:
        bodyText = deleteLegacyBodyText();
        break;
      case SubscriptionSchemeId.readOnly:
        bodyText = deleteStandardOrReadOnlyBodyText();
        break;
      case SubscriptionSchemeId.lifeTime:
        bodyText = deleteStandardOrReadOnlyBodyText();
        break;
      default:
        break;
    }

    return bodyText;
  };

  const showDeleteAlbumModalComponent = () => (
    <ModalWarning
      title="Deleting album"
      content={deleteAlbumModalContent()}
      successButtonText="Delete album"
      successStyleButton={ButtonStyle.RedFilled}
      closeFn={() => setShowDeleteAlbumModal(false)}
      successFn={() => {
        deleteAlbumAction();
        trackCustomEvent('confirmDeleteAlbum');
      }}
    />
  );

  const handleSuccessEditAlbumModal = () => {
    setShowEditAlbumModal(false);
    fetchAlbum();
    trackCustomEvent('confirmEditAlbum');
  };

  const handleSuccessEditClipModal = (clipResponse: ClipType) => {
    setShowEditClipModal(false);
    updateClipEditedInAlbum(clipResponse);
    setClipSelected(clipResponse);
    trackCustomEvent('confirmEditClip');
  };

  const handleSuccessEditQuestions = (unsQuestionsUpdated: QuestionType[]) => {
    setAlbum((prevState) => ({
      ...prevState,
      unansweredQuestions: unsQuestionsUpdated,
    }));

    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        noMessage: true,
        type: NotificationType.Success,
      }),
    });
  };

  const showEditAlbumModalComponent = () => (
    <ModalEditAlbum
      onClose={() => setShowEditAlbumModal(false)}
      onSuccess={handleSuccessEditAlbumModal}
      album={album}
    />
  );

  const handleCloseEditClipModal = () => {
    setShowEditClipModal(false);
  };

  const showEditClipModalComponent = () => (
    <ModalEditClip
      onClose={() => handleCloseEditClipModal()}
      onSuccess={(clipEdited: ClipType) =>
        handleSuccessEditClipModal(clipEdited)
      }
      clip={clipSelected}
    />
  );

  const handleModifyAlbumSubscription = async (
    newTier: SubscriptionSchemeId,
  ) => {
    try {
      await AlbumsController.modifyAlbumSubscription(album.id, newTier);
      return album;
    } catch (err: any) {
      setApiError(err);
      const isPaymentError = err?.errors?.some(
        (error: any) => error.errorCode === errorsCodeInPayments.cardDeclined,
      );
      if (isPaymentError) {
        setShowCardDeclinedModal(true);
      }
      return null;
    }
  };

  const handleError = () => {
    setStartPaymentProcess(false);

    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Modify Album',
        message: 'Subscription failed.',
        type: NotificationType.Error,
      }),
    });
  };

  const handleSuccessPayment = async () => {
    setStartPaymentProcess(false);

    dispatch({
      type: appActions.NOTIFICATION,
      notification: new NotificationObject({
        show: true,
        title: 'Modify Album',
        message: 'Subscription modified successful.',
        type: NotificationType.Success,
      }),
    });

    fetchAlbum();

    trackCustomEvent('confirmUpgradeAlbum');
  };

  const showAllPaymentProcessComponent = () => (
    <AllPaymentProcess
      albumReceived={album}
      paymentType={PaymentType.ModifyAlbumSubscription}
      successPaymentTextBody="Your subscription has been successfully modified."
      failedPaymentTextBody="Your subscription could not be modified. Please review your payment method and try again."
      notifyProcessConfirmation={(newTier?: SubscriptionSchemeId) =>
        handleModifyAlbumSubscription(newTier!)
      }
      cancelPaymentProcess={() => setStartPaymentProcess(false)}
      successPaymentProcess={handleSuccessPayment}
      notifyError={handleError}
      failedPaymentProcess={() => {
        setStartPaymentProcess(false);
      }}
    />
  );

  const handleEditAlbumModal = () => {
    setAutoPlayingVideo(false);
    setShowEditAlbumModal(true);
    trackCustomEvent('startEditAlbum');
  };

  const showDeleteAlbumOption = () => (
    <button
      type="button"
      onClick={() => handleDeleteAlbumModal()}
      className="text__body__regular__medium__textNeutral30"
    >
      Delete album
    </button>
  );

  const showEditAlbumOption = () => (
    <button
      type="button"
      onClick={() => handleEditAlbumModal()}
      className={classnames('text__body__regular__medium__textNeutral30')}
    >
      Edit album
    </button>
  );

  const showQrCodesOption = () => (
    <button
      type="button"
      onClick={handleClickQrIcon}
      className={classnames(
        styles.copyLinkOption,
        'text__body__regular__medium__textNeutral30',
      )}
    >
      QR Code(s)
    </button>
  );

  const showCopyLinkOption = () => (
    <button
      type="button"
      disabled={!isThereAPublicClip}
      onClick={
        isTemporaryUser(user)
          ? () => setModalSignUp(true)
          : () => {
              copyToClipboard(
                `${apiBaseURL}${getRouteFor(RouteName.AlbumDetailGuest, {
                  id: album.id,
                })}`,
              );
              trackCustomEvent('copyLinkToAlbum');
            }
      }
      className={classnames(
        styles.copyLinkOption,
        'text__body__regular__medium__textNeutral30',
      )}
    >
      Copy link to album
    </button>
  );

  const showDownloadAlbumOption = () => (
    <button
      type="button"
      onClick={
        isTemporaryUser(user)
          ? () => setModalSignUp(true)
          : () => {
              setShowRequestAlbumDownloadModal(true);
              trackCustomEvent('startRequestAlbumDownload');
            }
      }
      className="text__body__regular__medium__textNeutral30"
    >
      Request download
    </button>
  );

  const getOnlyQuestionTypeProps = (clips: ClipType[] = []): QuestionType[] =>
    clips.map((clip) => {
      auxId.current += 1;

      return {
        id: clip.id,
        name: clip.name,
        auxId: auxId.current.toString(),
        createdBy: clip.createdBy,
        recordedBy: clip.recordedBy,
        createdByOwner: clip.createdByOwner,
        recordedByOwner: clip.recordedByOwner,
        recordingStatus: clip.recordingStatus,
      } as QuestionType;
    });

  const albumOptionsMenu = () => (
    <Menu width={styles.albumOptionsWidth}>
      {showQrCodesOption()}
      {showCopyLinkOption()}
      {isOwner(user, album) && !isReadOnly(album) && showEditAlbumOption()}
      {isOwner(user, album) &&
        user.permissions?.permissions.deleteAlbums &&
        showDeleteAlbumOption()}
      {showAlbumDownload && showDownloadAlbumOption()}
    </Menu>
  );

  const showAlbumOptionsButton = () => (
    <div className={styles.albumDots}>
      <div
        role="button"
        tabIndex={0}
        ref={containerAlbumOptionsRef}
        key={album.id}
        className={classnames(globalStyles.threeDots, styles.dotsColor)}
        onClick={() => {
          setShowAlbumOptions(!showAlbumOptions);
          trackCustomEvent('openAlbumMenu');
        }}
      >
        {showAlbumOptions && albumOptionsMenu()}
      </div>
    </div>
  );
  const showWarningProcessingClips = () => (
    <AlbumLimitedConditionWarning
      title="Your video call is being processed"
      content={
        <div className="text__body__regular__medium__textNeutral40">
          You will be able to see and modify your clips once they finish
          uploading.
        </div>
      }
      type={AlbumLimitedConditionWarningType.Info}
    />
  );

  const moveSelectedTab = (queryParams: ParamsType) => {
    if (queryParams.tab === AlbumTabs.upload) {
      dispatchSelfRecording({
        type: selfRecordingActions.setUploadMode,
        uploadMode: true,
      });
    }
    const newUrl = getRouteFor(
      RouteName.AlbumDetail,
      { id: album.id },
      queryParams,
    );
    window.history.pushState({ path: newUrl }, '', newUrl);
    const tab = [AlbumTabs.videoCall, AlbumTabs.selfRecording].includes(
      queryParams.tab,
    )
      ? AlbumTabs.recordAnswers
      : queryParams.tab;
    setSelectedTab(tab);
  };

  const handleTabSelected = (queryParams: ParamsType) => {
    captureEvent('selectAlbumTab', { tab: queryParams.tab });
    trackCustomEvent('selectAlbumTab', { tab: queryParams.tab });
    const shouldDisplayWarning =
      [AlbumTabs.upload, AlbumTabs.recordAnswers].includes(selectedTab) &&
      ![AlbumTabs.upload, AlbumTabs.recordAnswers].includes(queryParams.tab) &&
      (isRecording || isUploading);
    if (shouldDisplayWarning) {
      setShowLeavePageModal(true);
      setLeavePageCallback(() => () => moveSelectedTab(queryParams));
    } else {
      moveSelectedTab(queryParams);
    }
  };

  const recordOptions: OptionMenuType[] = [
    {
      name: RecordOptions.selfRecording,
      icon: <UserIcon />,
      handleOptionSelected: () => {
        dispatchSelfRecording({
          type: selfRecordingActions.setUploadMode,
          uploadMode: false,
        });
        setRecordOptionSelected(RecordOptions.selfRecording);
        handleTabSelected({ tab: AlbumTabs.selfRecording });
      },
    },
    {
      name: RecordOptions.videoCall,
      icon: <UsersIcon />,
      handleOptionSelected: () => {
        setRecordOptionSelected(RecordOptions.videoCall);
        handleTabSelected({ tab: AlbumTabs.videoCall });
      },
    },
    {
      name: RecordOptions.invite,
      icon: <MailIcon />,
      handleOptionSelected: () => {
        setShowInviteToRecordProcess(true);
      },
    },
  ];

  const showEmptyPage = () => (
    <>
      {anyClipInProcess(album.unansweredQuestions) &&
        showWarningProcessingClips()}
      <div className={styles.emptyPage}>
        <div className={styles.emptyPageTexts}>
          <p
            className={classnames(
              'text__heading4__textNeutral30',
              styles.headingEmpty,
            )}
          >
            Let&apos;s get started!
          </p>
          <p className="text__body__regular__medium__textNeutral30">
            Start with{' '}
            <Button
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              buttonSize={ButtonSize.Medium}
              onClick={() => handleTabSelected({ tab: AlbumTabs.addPrompts })}
            >
              interview planning
            </Button>{' '}
            to select questions, or go to{' '}
            <Button
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              buttonSize={ButtonSize.Medium}
              onClick={() => handleTabSelected({ tab: AlbumTabs.upload })}
            >
              record / upload
            </Button>{' '}
            to jump straight into recording your history. Not sure what to do
            next? Click the Help button below for guidance!
          </p>
          <p className="text__body__regular__medium__textNeutral30">
            Download the Generational Story app to plan and record on the go.
            <br />
            Available now for{' '}
            <Button
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              onClick={() => window.open(storeLinks.appleStore, '_blank')}
            >
              iOS
            </Button>{' '}
            and{' '}
            <Button
              buttonStyle={ButtonStyle.PrimaryGhostLink}
              onClick={() => window.open(storeLinks.androidStore, '_blank')}
            >
              Android.
            </Button>
          </p>
        </div>
      </div>
    </>
  );

  const handleOnEndedClip = () => {
    let clipsListCopy = [...album.clips!];
    if (clipsOrder) {
      clipsListCopy = clipsOrder;
    }

    const clipSelectedIndex = getIndexClipSelectedInList(
      clipsListCopy,
      clipSelected,
    );
    assignNewSelectedClipAfterFinishing(clipsListCopy, clipSelectedIndex!);
  };

  const handleOnPlayClip = () => {
    setAutoPlayingVideo(true);
  };

  const getAlbumStatus = (alb: AlbumType) => {
    const { withIcon, statusValue } = getAlbumStatusLabel(alb);

    return (
      statusValue && (
        <InfoLabel
          withIcon={withIcon}
          content={
            <p
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
                styles.status,
              )}
            >
              {statusValue}
            </p>
          }
        />
      )
    );
  };

  const shouldShowAlbumOptions = () => {
    if (!isRestrictedAlbum(album)) {
      if (
        isOwner(user, album) ||
        (isContributor(user, album) && mediaQueries.md)
      ) {
        return true;
      }
    }

    return false;
  };

  const showTabs = () => (
    <div ref={tabsRef} className={styles.allTabs}>
      <AlbumTab
        selectedTab={selectedTab}
        tab={AlbumTabs.clips}
        handleTabSelected={handleTabSelected}
      />
      <AlbumTab
        selectedTab={selectedTab}
        tab={AlbumTabs.addPrompts}
        handleTabSelected={handleTabSelected}
        disabled={isRestrictedAlbum(album)}
      />
      <AlbumTabMenu
        selectedTab={selectedTab}
        tab={AlbumTabs.recordAnswers}
        disabled={
          isRestrictedAlbum(album) || !albumPermissions?.addOrRecordClips
        }
        menuOptions={recordOptions}
        scrollContainerRef={tabsRef}
      />
      {SHOW_PLAYLISTS && (
        <AlbumTab
          selectedTab={selectedTab}
          tab={AlbumTabs.playlists}
          disabled={isRestrictedAlbum(album)}
          handleTabSelected={handleTabSelected}
        />
      )}
      <AlbumTab
        selectedTab={selectedTab}
        tab={AlbumTabs.upload}
        handleTabSelected={handleTabSelected}
        disabled={
          isRestrictedAlbum(album) || !albumPermissions?.addOrRecordClips
        }
      />
      <AlbumTab
        selectedTab={selectedTab}
        tab={AlbumTabs.settings}
        handleTabSelected={handleTabSelected}
        disabled={!state.data.user.permissions?.permissions.albumAboutPage}
      />
      {isOwner(user, album) &&
        canUpgradeAlbum(album, subscriptionsScheme) &&
        !isTemporaryUser(user) && (
          <UpgradeAlbumTab
            onClick={startUpgradeProcess}
            showSecondaryColor={maxQuestionAllowed === album.clipAmount}
          />
        )}
      {shouldShowAlbumOptions() && showAlbumOptionsButton()}
      <div>{getAlbumStatus(album)}</div>
    </div>
  );

  const handleRemovePicture = async () => {
    const requestData: ClipEditRequestType = {
      customThumbnailSignedId: null,
      isAudioOnly: clipSelected.isAudioOnly,
      name: clipSelected.name,
      description: clipSelected.description,
      isPrivate: clipSelected.isPrivate,
    };
    try {
      const response = await AlbumsController.editClip(
        clipSelected.id,
        requestData,
      );
      updateClipEditedInAlbum(response);
      setClipSelected(response);
    } catch (err: any) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again.',
        }),
      });
    }
  };

  const handleImageUpload = async ({ signedId: idImage }: FileResponseType) => {
    const requestData: ClipEditRequestType = {
      customThumbnailSignedId: idImage,
      isAudioOnly: clipSelected.isAudioOnly,
      name: clipSelected.name,
      description: clipSelected.description,
      isPrivate: clipSelected.isPrivate,
    };
    try {
      trackCustomEvent('attachClipImage');
      const response = await AlbumsController.editClip(
        clipSelected.id,
        requestData,
      );
      updateClipEditedInAlbum(response);
      setClipSelected(response);
    } catch (err: any) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Something went wrong',
          message: 'Please try again.',
        }),
      });
    }
  };
  const showAudioOption = () =>
    clipSelected.customThumbnail ? (
      <AudioClip
        videoUrl={clipSelected.videoUrl}
        image={clipSelected.customThumbnail}
        alt={clipSelected.name}
      />
    ) : (
      <div className={styles.fileUploaderContainer}>
        {!isReadOnlySubOrRestrictedAlbum(album) && (
          <div className={styles.fileUploader}>
            <FileUploader
              uploadingMessage="Uploading photo"
              supportedExtensions={ImageSupportedExtensions}
              maxFileSizeInMb={mbAlbumCoverMaxSize}
              onLoading={() => {}}
              onSuccess={handleImageUpload}
              onError={() => {}}
              onCancel={() => {}}
              message="Drag and drop a related image to your clip to help tell your story or"
            />
          </div>
        )}
        <AudioPlayer videoUrl={clipSelected.videoUrl} />
      </div>
    );
  const showClipsBody = () => (
    <div className={styles.clipsSection}>
      <div className={styles.clipVideoAndDescription}>
        {clipSelected.isAudioOnly ? (
          showAudioOption()
        ) : (
          <VideoClip
            autoPlaying={autoPlayingVideo}
            clipSelected={clipSelected}
            notifyOnEnded={() => handleOnEndedClip()}
            notifyOnPlay={() => handleOnPlayClip()}
          />
        )}
        <ClipData
          album={album}
          clipSelected={clipSelected}
          notifyClipAction={(action: ClipActions, clip: ClipType) => {
            handleClipAction(action, clip, 'clipDetail');
          }}
          notifyShowQrCodesExplanation={() =>
            setShowQrCodesExplanationModal(true)
          }
          handleImageUpload={handleImageUpload}
          handleRemovePicture={handleRemovePicture}
        />
      </div>
      <div
        className={
          showClipsListEnlarged ? styles.clipsListEnlarged : styles.clipsList
        }
      >
        <AllClipsList
          album={album}
          clipSelected={clipSelected}
          notifyClipAction={(
            action: ClipActions,
            clip: ClipType,
            extraData?: ClipExtraDataType,
          ) => {
            handleClipAction(action, clip, 'clipMenu', extraData);
          }}
          notifyStopClip={() => setAutoPlayingVideo(false)}
          notifyChangeClipOrder={(clips: ClipType[]) => setClipsOrder(clips)}
        />
      </div>
    </div>
  );

  const showPlanInterviewBody = () => (
    <div className={styles.interviewSection}>
      <PlanInterview
        album={album}
        maxQuestionsAllowed={album.maxClips}
        unansweredQuestions={getOnlyQuestionTypeProps(
          album.unansweredQuestions,
        )}
        notifySucess={(UnasQuestionsUpdated) =>
          handleSuccessEditQuestions(UnasQuestionsUpdated)
        }
        handleTabSelected={handleTabSelected}
      />
    </div>
  );

  const showVideoCallWaitingRoomBody = () => (
    <div className={styles.videoCallSection}>
      <VideoCallWaitingRoom
        album={album}
        upgradeAlbum={() => setStartPaymentProcess(true)}
        handleTabChange={(tabName: AlbumTabs) =>
          handleTabSelected({ tab: tabName })
        }
      />
    </div>
  );

  const warningFreeAlbumReachedLimitContent = (contributor: boolean) => {
    if (contributor) {
      return (
        <span
          className={classnames(
            'text__body__regular__medium__textNeutral40',
            styles.bodyDescription,
          )}
        >
          Contact the album owner to upgrade their subscription to keep
          recording.
        </span>
      );
    }
    return (
      <div>
        <Button
          buttonStyle={ButtonStyle.SecondaryGhostLink}
          buttonSize={ButtonSize.Medium}
          onClick={startUpgradeProcess}
        >
          Upgrade to a subscription
        </Button>
        <span
          className={classnames(
            'text__body__regular__medium__textNeutral40',
            styles.bodyDescription,
          )}
        >
          {' '}
          to increase your limit.{' '}
        </span>
      </div>
    );
  };

  const warningFreeAlbumAlmostFullContent = (
    totalClips: number,
    clipsLimit: number,
    contributor: boolean,
  ) => (
    <div>
      <span
        className={classnames(
          'text__body__regular__medium__textNeutral40',
          styles.bodyDescription,
        )}
      >
        You’ve recorded {totalClips} out of {clipsLimit} available clips.{' '}
      </span>
      {contributor ? (
        <span
          className={classnames(
            'text__body__regular__medium__textNeutral40',
            styles.bodyDescription,
          )}
        >
          Contact the album owner to upgrade their subscription to keep
          recording.
        </span>
      ) : (
        <>
          <Button
            buttonStyle={ButtonStyle.PrimaryGhostLink}
            buttonSize={ButtonSize.Medium}
            onClick={startUpgradeProcess}
          >
            Upgrade to a subscription
          </Button>
          <span
            className={classnames(
              'text__body__regular__medium__textNeutral40',
              styles.bodyDescription,
            )}
          >
            {' '}
            to increase your limit.{' '}
          </span>
        </>
      )}
    </div>
  );

  const warningAlbumAlmostFullContent = (
    alb: AlbumType,
    warningType: AlbumLimitedConditionWarningType,
    contributor: boolean,
    subsScheme?: SubscriptionTypeType[],
  ) => (
    <div>
      <span
        className={classnames(
          'text__body__regular__medium__textNeutral40',
          styles.bodyDescription,
        )}
      >
        You’ve recorded {totalAlbumVideoClips(alb)} out of {alb.maxClips}{' '}
        available clips.{' '}
      </span>
      {contributor ? (
        <span
          className={classnames(
            'text__body__regular__medium__textNeutral40',
            styles.bodyDescription,
          )}
        >
          Contact the album owner to purchase extra clips or upgrade their
          subscription for extra storage.
        </span>
      ) : (
        <>
          <span
            className={classnames(
              'text__body__regular__medium__textNeutral40',
              styles.bodyDescription,
            )}
          >
            Get extra storage by{' '}
          </span>
          <Button
            buttonStyle={
              warningType === AlbumLimitedConditionWarningType.Alert
                ? ButtonStyle.SecondaryGhostLink
                : ButtonStyle.PrimaryGhostLink
            }
            buttonSize={ButtonSize.Medium}
            onClick={() => {
              setShowBuyClipsModal(true);
            }}
          >
            purchasing extra clips
          </Button>
          {canUpgradeAlbum(alb, subsScheme) && (
            <>
              {' '}
              <span
                className={classnames(
                  'text__body__regular__medium__textNeutral40',
                  styles.bodyDescription,
                )}
              >
                {' '}
                or{' '}
              </span>
              <Button
                buttonStyle={
                  warningType === AlbumLimitedConditionWarningType.Alert
                    ? ButtonStyle.SecondaryGhostLink
                    : ButtonStyle.PrimaryGhostLink
                }
                buttonSize={ButtonSize.Medium}
                onClick={startUpgradeProcess}
              >
                upgrading your subscription
              </Button>
            </>
          )}
          <span
            className={classnames(
              'text__body__regular__medium__textNeutral40',
              styles.bodyDescription,
            )}
          >
            .
          </span>
        </>
      )}
    </div>
  );

  const warningRestrictedAlbumContent = () => (
    <div>
      <p className={classnames('text__body__regular__small__textNeutral40')}>
        Your album’s payment method couldn’t be charged and your album has been
        restricted to view only.
      </p>

      <div>
        <Button
          buttonStyle={ButtonStyle.SecondaryGhostLink}
          buttonSize={ButtonSize.Small}
          onClick={() => {
            goToPage(RouteName.Account, {}, { 'add-payment-method': true });
          }}
        >
          Update your payment method
        </Button>
        <span
          className={classnames('text__body__regular__small__textNeutral40')}
        >
          &nbsp; to restore your album.
        </span>
      </div>
    </div>
  );

  const showWarningAlbumLimitedCondition = (
    message: string,
    content: ReactNode | string,
    type: AlbumLimitedConditionWarningType,
  ) => (
    <AlbumLimitedConditionWarning
      title={message}
      content={content}
      type={type}
    />
  );

  const readOnlyContent = () => (
    <span
      className={classnames(
        'text__body__regular__medium__textNeutral40',
        styles.bodyDescription,
      )}
    >
      <Button
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        buttonSize={ButtonSize.Medium}
        onClick={startUpgradeProcess}
      >
        Upgrade your subscription
      </Button>{' '}
      to add new clips to your album and access all of Generational Story’s
      features.
    </span>
  );

  const verifyAlbumConditionWarning = (alb: AlbumType, tab: AlbumTabs) => {
    let message;
    const warningType =
      totalAlbumVideoClips(alb) >= alb.maxClips
        ? AlbumLimitedConditionWarningType.Alert
        : AlbumLimitedConditionWarningType.Info;

    if (!userPermissionUpgrade(user)) {
      return null;
    }

    if (isRestrictedAlbum(alb)) {
      message = 'Your album has been restricted to view only';
      return showWarningAlbumLimitedCondition(
        message,
        warningRestrictedAlbumContent(),
        AlbumLimitedConditionWarningType.Alert,
      );
    }
    if (isReadOnly(alb)) {
      message = 'This is a read only album';
      return showWarningAlbumLimitedCondition(
        message,
        readOnlyContent(),
        AlbumLimitedConditionWarningType.Info,
      );
    }

    message = hasAlbumReachedLimitMessage(alb, tab) || '';
    const warningContentFreeTrial =
      warningType === AlbumLimitedConditionWarningType.Alert
        ? warningFreeAlbumReachedLimitContent(isContributor(user, album))
        : warningFreeAlbumAlmostFullContent(
            totalAlbumVideoClips(alb),
            alb.maxClips,
            isContributor(user, album),
          );

    if (message) {
      return showWarningAlbumLimitedCondition(
        message,
        isFreeTrial(alb)
          ? warningContentFreeTrial
          : warningAlbumAlmostFullContent(
              alb,
              warningType,
              isContributor(user, album),
              subscriptionsScheme,
            ),
        warningType,
      );
    }

    return null;
  };

  const showAboutAlbumBody = () => (
    <AboutAlbum
      album={album}
      refetchAlbum={fetchAlbum}
      handleTabChange={(tabName: AlbumTabs) =>
        handleTabSelected({ tab: tabName })
      }
    />
  );

  const showRecordUploadBody = () => (
    <RecordUpload
      album={album}
      setShowLearnMoreSubscriptionModal={setShowLearnMoreSubscriptionModal}
      upgradeAction={() => setStartPaymentProcess(true)}
      refetchAlbum={fetchAlbum}
    />
  );

  useEffect(() => {
    let helpSection: HelpInfoSections | undefined;

    switch (selectedTab) {
      case AlbumTabs.clips:
        helpSection = album.clips?.length
          ? HelpInfoSections.album_clips
          : HelpInfoSections.album_clips_empty;
        break;
      case AlbumTabs.addPrompts:
        helpSection = album.unansweredQuestions?.length
          ? HelpInfoSections.add_prompts_filled
          : HelpInfoSections.add_prompts_empty;
        break;
      case AlbumTabs.recordAnswers:
        if (recordOptionSelected === RecordOptions.videoCall) {
          helpSection = HelpInfoSections.video_call;
        } else if (recordOptionSelected === RecordOptions.selfRecording) {
          helpSection = album.unansweredQuestions?.length
            ? HelpInfoSections.record_filled
            : HelpInfoSections.record_empty;
        }
        break;
      case AlbumTabs.settings:
        helpSection = HelpInfoSections.album_settings;
        break;
      case AlbumTabs.upload:
        helpSection = album.unansweredQuestions?.length
          ? HelpInfoSections.upload_filled
          : HelpInfoSections.upload_empty;
        break;
      default:
        break;
    }

    dispatch({
      type: appActions.SET_HELP_SECTION,
      helpSection,
    });
  }, [selectedTab, album]);

  const showBodyContent = () => (
    <>
      {verifyAlbumConditionWarning(album, selectedTab)}

      {AlbumTabs.clips === selectedTab &&
        (album.clips?.length ? showClipsBody() : showEmptyPage())}
      {AlbumTabs.addPrompts === selectedTab && showPlanInterviewBody()}
      {AlbumTabs.recordAnswers === selectedTab &&
        recordOptionSelected === RecordOptions.videoCall &&
        showVideoCallWaitingRoomBody()}
      {AlbumTabs.recordAnswers === selectedTab &&
        recordOptionSelected === RecordOptions.selfRecording &&
        showRecordUploadBody()}
      {AlbumTabs.settings === selectedTab && showAboutAlbumBody()}
      {AlbumTabs.upload.includes(selectedTab) && showRecordUploadBody()}
    </>
  );

  const learnMoreSubcriptionBodyModal = (
    subSchemes: SubscriptionTypeType[],
  ) => {
    const trialScheme = getSubscriptionSchemeById(
      subSchemes,
      SubscriptionSchemeId.trial,
    );

    const { permissions } = trialScheme!;
    const usersCallTimeInMinutes = convertSecondsToTimeUnit(
      permissions?.usersCallTime!,
      TimeUnit.minutes,
    );

    return (
      <div className={styles.learMoreSubscriptionBody}>
        <p>
          <span className="text__body__regular__medium__textNeutral40">
            {' '}
            Trial albums allow you to access in person recording,{' '}
          </span>
          <span className="text__body__semi__bold__medium__textNeutral40">
            {`${usersCallTimeInMinutes} minutes of video call time `}
          </span>
          <span className="text__body__regular__medium__textNeutral40">
            {' '}
            for remote recording and up to{' '}
          </span>
          <span className="text__body__semi__bold__medium__textNeutral40">
            {`${album.maxClips} clips`}
          </span>
          .
        </p>
        <p>
          Upgrade your album to access extra video call time for remote
          recording and clips.
        </p>
      </div>
    );
  };

  const showLearnMoreSubscriptionModalComponent = () => (
    <InfoModal
      title="Subscription"
      content={learnMoreSubcriptionBodyModal(subscriptionsScheme!)}
      onClose={() => setShowLearnMoreSubscriptionModal(false)}
    />
  );

  // ---------------------------- //
  // INFO: This block of code is a workaround to listen the clicks on the navbar buttons
  // in order to display the warning modal if the user is recording.

  const handleClick = (event: MouseEvent) => {
    const shouldDisplayWarning =
      [AlbumTabs.upload, AlbumTabs.recordAnswers].includes(selectedTab) &&
      !showLeavePageModal &&
      (isRecording || isUploading);
    const button = event.currentTarget as HTMLButtonElement;
    if (shouldDisplayWarning && button) {
      event.stopPropagation();
      setLeavePageCallback(() => () => button.click());
      setShowLeavePageModal(true);
    }
  };

  useEffect(() => {
    const links: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
      '#navbar-fixed button',
    );

    links.forEach((link) => link.addEventListener('click', handleClick));
    return () => {
      links.forEach((link) => {
        link.removeEventListener('click', handleClick);
      });
    };
  }, [isRecording, isUploading, selectedTab, showLeavePageModal]);

  useEffect(
    () => () => {
      if (isRecording)
        dispatchSelfRecording({
          type: selfRecordingActions.setIsRecording,
          isRecording: false,
        });
      if (isUploading)
        dispatchSelfRecording({
          type: selfRecordingActions.setIsUploading,
          isUploading: false,
        });
    },
    [isRecording, isUploading],
  );

  // ---------------------------- //

  useEffect(() => {
    dispatch({
      type: appActions.SET_HELP_LINKS,
      helpLinks: {
        [HelpInfoLinks.goToRecord]: () => {
          moveSelectedTab({ tab: AlbumTabs.recordAnswers });
          setRecordOptionSelected(RecordOptions.selfRecording);
          if (uploadMode) {
            dispatchSelfRecording({
              type: selfRecordingActions.setUploadMode,
              uploadMode: false,
            });
          }
        },
        [HelpInfoLinks.goToUpload]: () =>
          moveSelectedTab({ tab: AlbumTabs.upload }),
        [HelpInfoLinks.goToAddPrompts]: () =>
          moveSelectedTab({ tab: AlbumTabs.addPrompts }),
        [HelpInfoLinks.inviteCollaborators]: () =>
          setShowInviteToRecordProcess(true),
      },
    });
  }, [album]);

  return (
    <>
      {showCardDeclinedModal && (
        <ModalCardDeclined
          onClose={() => setShowCardDeclinedModal(false)}
          openPaymentMethodModal={() => setShowPaymentMethodModal(true)}
        />
      )}
      {showPaymentMethodModal && (
        <AddPaymentMethodModal
          notifyClose={() => {
            setShowPaymentMethodModal(false);
          }}
          notifySuccess={() => {
            setShowPaymentMethodModal(false);
            setShowCardDeclinedModal(false);
          }}
        />
      )}
      {showBuyClips && (
        <BuyClipsProcess
          album={album}
          onFinishProcess={() => setShowBuyClipsModal(false)}
          refetchAlbum={fetchAlbum}
        />
      )}
      {showDeleteAlbumModal && showDeleteAlbumModalComponent()}
      {showRequestAlbumDownloadModal && (
        <RequestAlbumDownloadModal
          album={album}
          onClose={() => setShowRequestAlbumDownloadModal(false)}
        />
      )}
      {showEditAlbumModal && showEditAlbumModalComponent()}
      {showEditClipModal && showEditClipModalComponent()}
      {showLearnMoreSubscriptionModal &&
        showLearnMoreSubscriptionModalComponent()}
      {startPaymentProcess && showAllPaymentProcessComponent()}
      {showQrCodesModal && (
        <QrCodesDetailsModal
          qrCodes={album.qrCodes}
          onClose={() => setShowQrCodesModal(false)}
          album={album}
        />
      )}
      {showQrCodesExplanationModal && (
        <QrCodesExplanationModal
          onClose={() => setShowQrCodesExplanationModal(false)}
        />
      )}
      {showModalSignUp && (
        <ModalWarning
          title="Sign up to share or download your recordings"
          content={
            <div className={styles.modalSignUpInfo}>
              <div className="text__body__regular__medium__textNeutral40">
                Please complete the sign up process to share or download your
                clips, as well as access the rest of our features.
              </div>
              <div className="text__body__regular__medium__textNeutral40">
                Don’t worry, your first album is{' '}
                <span className="text__body__semi__bold__medium__textNeutral40">
                  free
                </span>{' '}
                for a month, and signing up only takes 2 minutes!
              </div>
            </div>
          }
          successButtonText="Sign up"
          closeFn={() => setModalSignUp(false)}
          successFn={() => goToPage(RouteName.SignUp)}
          successStyleButton={ButtonStyle.PrimaryFilled}
        />
      )}

      {showLeavePageModal && (
        <ModalWarning
          title="Leaving page"
          content={
            <div className="text__body__regular__medium__textNeutral40">
              Are you sure you want to leave this page? If you leave your
              {isRecording ? ' recording' : ' upload'} will be cancelled.
            </div>
          }
          successButtonText={`Leave & cancel ${
            isRecording ? 'recording' : 'upload'
          }`}
          successStyleButton={ButtonStyle.RedFilled}
          closeFn={() => setShowLeavePageModal(false)}
          successFn={() => {
            dispatchSelfRecording({
              type: selfRecordingActions.cleanState,
            });
            setShowLeavePageModal(false);
            leavePageCallback?.();
          }}
        />
      )}
      {showModalLeaveTemporary && (
        <ModalTemporaryUser onClose={() => setShowModalLeaveTemporary(false)} />
      )}
      {showInviteToRecordProcess && (
        <InviteToRecordProcess
          handleClose={() => setShowInviteToRecordProcess(false)}
        />
      )}
      <div
        className={classnames(
          globalStyles.loggedMainContainer,
          styles.mainContainer,
        )}
      >
        <div className={styles.tabContent}>{showTabs()}</div>

        <div className={classnames(styles.bodyContent)}>
          {!fetching && subscriptionsScheme ? showBodyContent() : <Spinner />}
        </div>
      </div>
    </>
  );
};

export { AlbumDetail };
