import { AlbumTabs } from 'common/enums';
import { classnames } from 'helpers/utils';
import styles from './album-tab.module.scss';

type AlbumTabProps = {
  tab: AlbumTabs;
  selectedTab: AlbumTabs;
  disabled?: boolean;
  handleTabSelected: (queryParams: ParamsType) => void;
};

const AlbumTab = ({
  tab,
  selectedTab,
  disabled = false,
  handleTabSelected,
}: AlbumTabProps) => (
  <button
    type="button"
    disabled={disabled}
    className={classnames(
      styles.albumTab,
      {
        [styles.disabledTab]: disabled,
        [styles.tabSelected]: selectedTab === tab,
      },
      styles.tabButton,
      'text__button__medium__textNeutral30',
    )}
    onClick={() => handleTabSelected({ tab })}
  >
    {tab}
  </button>
);

type UpgradeAlbumTabProps = {
  onClick: () => void;
  showSecondaryColor: boolean;
};

const UpgradeAlbumTab = ({
  onClick,
  showSecondaryColor = false,
}: UpgradeAlbumTabProps) => (
  <button
    type="button"
    className={classnames(
      'text__button__medium__textNeutral30',
      styles.albumTab,
      styles.tabButton,
      styles.upgradeTab,
      {
        [styles.upgradeTabWarning]: showSecondaryColor,
      },
    )}
    onClick={onClick}
  >
    Upgrade album
  </button>
);

export { AlbumTab, UpgradeAlbumTab };
