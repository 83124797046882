import React from 'react';
import { Modal } from 'common/modal';
import { Button, ButtonStyle } from 'common/button';
import { NewWindowIcon } from 'assets/icons';
import { RouteName, goToPage } from 'routes';
import styles from './no-templates-created-modal.module.scss';

type NoTemplatesCreatedModalProps = {
  onClose: () => void;
};

const NoTemplatesCreatedModal: React.FC<NoTemplatesCreatedModalProps> = ({
  onClose,
}) => (
  <Modal title="Add prompts" onClose={() => onClose()}>
    <span className="text__title1__textNeutral30">No templates created </span>
    <span className="text__body__regular__medium__textNeutral30">
      Create a new template to make your interview planning process easier!
    </span>
    <Button
      className={styles.createTemplate}
      buttonStyle={ButtonStyle.PrimaryFilled}
      onClick={() => {
        goToPage(RouteName.Templates);
      }}
    >
      <span> Create templates</span>
      <NewWindowIcon />
    </Button>
  </Modal>
);

export { NoTemplatesCreatedModal };
