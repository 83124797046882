import { ChangeEvent, Dispatch, useRef } from 'react';
import { Input } from 'common/input';
import { HelperText } from 'common/helper-text';
import { classnames, getSubscriptionSchemeById } from 'helpers/utils';
import { InfoIcon, PlusIcon } from 'assets/icons';
import { ContributorBox } from 'common/contributor-box';
import { AlbumCover } from 'common/album-cover';
import { cloneDeep } from 'lodash';
import { Contributor } from 'models/contributor';
import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import {
  updateContributorErrorsByField,
  checkTitleAndGetError,
  removeEmailsAlreadyAddedErrors,
} from 'helpers/new-album-helper';
import { InitialStateType } from 'context/app-context/context-reducer';
import CypressIds from 'cypressIds';
import { trackCustomEvent } from 'helpers/analytics';
import { AlbumNewStateType, NewAlbumAction } from '../../album-new-reducer';
import styles from './step-information.module.scss';

type StepInformationProps = {
  generalState: InitialStateType;
  newAlbumState: AlbumNewStateType;
  newAlbumDispatch: Dispatch<NewAlbumAction>;
};

const StepInformation: React.FC<StepInformationProps> = ({
  generalState,
  newAlbumState,
  newAlbumDispatch,
}) => {
  const idContributorAux = useRef(1);

  const { album, errors } = newAlbumState;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    newAlbumDispatch({
      type: 'ALBUM_INPUT',
      field: e.target.name,
      value: e.target.value,
    });
  };

  const handleIsLoading = (isLoading: boolean) => {
    newAlbumDispatch({
      type: 'IS_LOADING',
      isLoading,
    });
  };

  const handleImageUpload = (signedId: string | undefined) => {
    newAlbumDispatch({
      type: 'UPLOAD_IMAGE',
      coverSignedId: signedId,
    });
  };

  const addNewContributor = (
    errs: NewAlbumErrorsType,
    contributors: ContributorType[],
  ) => {
    const contributorsCopy = cloneDeep(contributors);

    contributorsCopy.push(
      new Contributor({
        idContributorAux: idContributorAux.current,
        name: '',
        email: '',
      }),
    );

    const newErrorsList = cloneDeep(errs);
    newErrorsList.contributors.push({
      idContributorAux: idContributorAux.current,
      name: '',
      email: '',
    });

    idContributorAux.current += 1;
    newAlbumDispatch({
      type: 'UPDATE_CONTRIBUTORS',
      contributors: contributorsCopy,
    });
    newAlbumDispatch({ type: 'UPDATE_ERRORS', errors: newErrorsList });
  };

  const deleteContributorFromArray = (
    contributors: ContributorType[],
    contDeleted: ContributorType,
  ) => {
    const contributorsCopy = cloneDeep(contributors);

    const contributorsUpdated = contributorsCopy.filter(
      (contri: ContributorType) =>
        contri.idContributorAux !== contDeleted.idContributorAux,
    );

    return contributorsUpdated;
  };

  const deleteContributorFromErrorsArray = (
    errs: NewAlbumErrorsType,
    contributorsList: ContributorType[],
    contDeleted: ContributorType,
  ) => {
    const contributorsErrorsFiltered = errs.contributors.filter(
      (contributorErrors: ContributorType) =>
        contributorErrors.idContributorAux !== contDeleted.idContributorAux,
    );

    const newContributorsErrorsList = removeEmailsAlreadyAddedErrors(
      contributorsList,
      contributorsErrorsFiltered,
    );

    return newContributorsErrorsList;
  };

  const handleDeleteContributor = (
    contributors: ContributorType[],
    errs: NewAlbumErrorsType,
    contDeleted: ContributorType,
  ) => {
    trackCustomEvent('confirmRemoveContributor', {
      step: 'createAlbum',
      contributorEmail: contDeleted.email,
      contributorName: contDeleted.name,
    });

    const newContributorList = deleteContributorFromArray(
      contributors,
      contDeleted,
    );
    newAlbumDispatch({
      type: 'UPDATE_CONTRIBUTORS',
      contributors: newContributorList,
    });

    const newContributorsErrorsList = deleteContributorFromErrorsArray(
      errs,
      newContributorList,
      contDeleted,
    );

    newAlbumDispatch({
      type: 'UPDATE_ERRORS',
      errors: {
        ...newAlbumState.errors,
        contributors: newContributorsErrorsList,
      },
    });
  };

  const handleValidateContributor = (
    errs: NewAlbumErrorsType,
    currentContributor: ContributorType,
    field: string,
    newValue: string,
  ) => {
    const contributorsErrorsUpdated = updateContributorErrorsByField(
      errs,
      album.contributors,
      currentContributor,
      field,
      newValue,
    );

    newAlbumDispatch({
      type: 'UPDATE_ERRORS',
      errors: {
        ...newAlbumState.errors,
        contributors: contributorsErrorsUpdated,
      },
    });
  };

  const handleValidateTitle = (newValue: string) => {
    const titleErrorUpdated = checkTitleAndGetError(newValue);

    newAlbumDispatch({
      type: 'UPDATE_ERRORS',
      errors: { ...newAlbumState.errors, title: titleErrorUpdated },
    });
  };

  const handleUpdateContributor = (
    contributors: ContributorType[],
    cont: ContributorType,
    field: string,
    newValue: string,
  ) => {
    trackCustomEvent('startAddContributor', { step: 'createAlbum' });

    const contributorsCopy = cloneDeep(contributors);

    const contributorUpdated = contributorsCopy.find(
      (contri: ContributorType) =>
        contri.idContributorAux === cont.idContributorAux,
    )!;

    contributorUpdated[field as 'name' | 'email'] = newValue;

    newAlbumDispatch({
      type: 'UPDATE_CONTRIBUTORS',
      contributors: contributorsCopy,
    });
  };

  const showAddContributorButton = (alb: AlbumNewRequestType) => (
    <Button
      className={styles.addContributorButton}
      buttonStyle={ButtonStyle.PrimaryStroke}
      buttonSize={ButtonSize.Small}
      buttonType={ButtonType.Button}
      onClick={() => addNewContributor(newAlbumState.errors, alb.contributors)}
    >
      <PlusIcon className={styles.plusIcon} />
      <span> Add a contributor </span>
    </Button>
  );

  const getContributorErrors = (
    contriErrors: ContributorErrorsType[],
    idContributor: number,
  ) => {
    const currentContributorError = contriErrors.find(
      (con: ContributorType) => con.idContributorAux === idContributor,
    );

    return currentContributorError!;
  };

  const canAddMoreContributors = () => {
    const { id } = newAlbumState.subscriptionSelected;

    const subSelectedInfo = getSubscriptionSchemeById(
      generalState.subscriptionsScheme!,
      id,
    );
    const maxContributorsLengthAllowed =
      subSelectedInfo!.permissions?.contributors;

    return album.contributors.length < maxContributorsLengthAllowed!;
  };

  const showContributorsList = (
    alb: AlbumNewRequestType,
    errs: NewAlbumErrorsType,
  ) => (
    <div className={styles.contributorsContainer}>
      {alb.contributors.map((cont: ContributorType) => {
        const contriErrors = getContributorErrors(
          errs.contributors,
          cont.idContributorAux!,
        );

        return (
          <ContributorBox
            key={cont.idContributorAux}
            contributor={cont}
            contributorErrors={contriErrors}
            notifyChange={(field: string, newValue: string) => {
              handleUpdateContributor(alb.contributors, cont, field, newValue);
            }}
            notifiyDelete={() => {
              handleDeleteContributor(alb.contributors, errs, cont);
            }}
            checkValidation={(field: string, newValue: string) => {
              handleValidateContributor(errs, cont, field, newValue);
            }}
          />
        );
      })}
    </div>
  );

  return (
    <>
      <div className="mb-10 text__title2__textNeutral40">Album information</div>
      <div className={styles.infoAndContributorsColumns}>
        <div className={styles.infoColum}>
          <Input
            id="title"
            name="title"
            label="Album name"
            value={album.title}
            placeholder="Album name"
            onChange={onChange}
            errorMessage={errors.title}
            onBlur={(e: ChangeEvent<HTMLInputElement>) => {
              handleValidateTitle(e.target.value);
            }}
            dataCy={CypressIds.albumName}
          />

          <div className={styles.albumCover}>
            <AlbumCover
              setIsLoading={handleIsLoading}
              setImage={handleImageUpload}
            />
          </div>
        </div>
        <div className={styles.contributorsColum}>
          <div className={styles.title}>
            <span className="text__body__semi__bold__medium__textNeutral40">
              Contributor
            </span>
            <span className="text__body__regular__medium__textNeutral20">
              (Optional)
            </span>
          </div>
          <HelperText
            content="Contributors have the ability to add, edit and remove questions, record clips and join video calls for remote interviews. When you add a contributor, an invitation to join this album will be sent to their inbox. You can resend it from the album details."
            icon={<InfoIcon className={classnames(styles.infoIcon)} />}
            className={styles.helperText}
          />

          {canAddMoreContributors() && showAddContributorButton(album)}
          {showContributorsList(album, errors)}
        </div>
      </div>
    </>
  );
};

export { StepInformation };
