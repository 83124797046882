import React from 'react';
import ReactDOM from 'react-dom';

import { AppProvider, SensorSelectionProvider } from 'context';
import { initializeSentry } from 'sentry';
import { AppPostHogProvider } from 'posthog';
import { App } from 'app';

import 'index.scss';
import { SelfRecordingProvider } from 'context/self-recording-context/context';
import { StoreProvider } from 'context/store-context/context';
import { OnboardingProvider } from 'context/onboarding-context';

initializeSentry();

ReactDOM.render(
  <React.StrictMode>
    <AppPostHogProvider>
      <AppProvider>
        <OnboardingProvider>
          <SensorSelectionProvider>
            <SelfRecordingProvider>
              <StoreProvider>
                <App />
              </StoreProvider>
            </SelfRecordingProvider>
          </SensorSelectionProvider>
        </OnboardingProvider>
      </AppProvider>
    </AppPostHogProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
