/* eslint-disable max-len */
import { PaginationSerializer } from './pagination-serializer';
import { AlbumsSerializer } from './albums-serializer';

class ContributorSerializer {
  static serializeContributorsQueryParams(
    params: ContributorsQueryParamsSerialized,
  ): ContributorsQueryParamsRaw {
    return {
      search: params.search,
      sort_by: params.sortBy?.id as string,
      page_num: params.pageNum,
      page_size: params.pageSize,
      accepted: params.accepted,
      pending: params.pending,
      rejected: params.rejected,
      active: params.active,
      restricted: params.restricted,
      locked: params.locked,
    };
  }

  static deSerializeContributorsPagination(
    response: ContributorsPaginationRaw,
  ): ContributorsPaginationSerialized {
    return {
      data: response.data.map((elem: ContributorElementRaw) => ({
        invitationStatus: elem.invitation_status,
        invitationId: elem.invitation_id,
        user: AlbumsSerializer.deSerializeContributorInfo(elem.user),
        album: AlbumsSerializer.deSerializeAlbum(elem.album),
      })),
      pagination: PaginationSerializer.deSerializePagination(
        response.pagination,
      ),
    };
  }

  static deSerializeInvitation(
    response: ContributorInvitationRaw,
  ): ContributorInvitationSerialized {
    return {
      invitationId: response.invitation_id,
    };
  }
}

export { ContributorSerializer };
