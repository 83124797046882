import { PaymentSerializer } from './payment-serializer';
import { SubscriptionDealSerializer } from './subscription-deal-serializer';

class UserSerializer {
  static deSerializeAllDataUser(data: AllDataUserRaw): AllDataUserSerialized {
    return {
      csrfToken: data.csrf_token,
      user: {
        id: data.user.id,
        name: data.user.name,
        email: data.user.email,
        companyName: data.user.company_name,
        recordingTips: data.user.recording_tips,
        stripeClientSecret: data.user.stripe_client_secret,
        backupEmail: data.user.backup_email,
        backupName: data.user.backup_name,
        country: data.user.country,
        state: data.user.state,
        verified: data.user.verified,
        firstAlbumPaymentProcessed:
          data.user.first_album_payment_processed || false,
        paymentMethods:
          data.user.payment_methods.map(
            PaymentSerializer.deSerializePaymentMethod,
          ) || [],
        subscriptionDeals: data.user.subscription_deals
          ? data.user.subscription_deals.map(
              SubscriptionDealSerializer.deSerializeSubscriptionDealResponse,
            )
          : [],
        permissions: data.user.permissions
          ? this.deserializeUserPermissions(data.user.permissions)
          : undefined,
        trialAlbumId: data.user.temporary_trial_album_id,
        firstVisitDisplayed: data.user.first_visit_displayed,
      },
    };
  }

  static deserializeUserPermissions(
    data: UserPermissionsRaw,
  ): UserPermissionsType {
    return {
      id: data.id,
      permissions: {
        albumAboutPage: data.permissions.album_about_page,
        contributorsManagement: data.permissions.contributors_management,
        createAlbums: data.permissions.create_albums,
        deleteAlbums: data.permissions.delete_albums,
        paymentInformationManagement:
          data.permissions.payment_information_management,
        personalInformationManagement:
          data.permissions.personal_information_management,
        reminderManagement: data.permissions.reminder_management,
        resetPassword: data.permissions.reset_password,
        storeManagement: data.permissions.store_management,
        templatesManagement: data.permissions.templates_management,
        upgradeAlbums: data.permissions.upgrade_albums,
        uploadVideos: data.permissions.upload_videos,
        changeVideocallRole: data.permissions.change_videocall_role,
        inviteGuest: data.permissions.invite_guest,
        joinVideocallAsRespondent:
          data.permissions.join_videocall_as_respondent,
        updateTemporaryUser: data.permissions.update_temporary_user,
      },
    };
  }

  static deSerializeUtmProperty(
    utmProperty: UtmProperties,
  ): UtmPropertiesDeSerialized | null {
    switch (utmProperty) {
      case 'utm_source':
        return 'utmSource';
      case 'utm_medium':
        return 'utmMedium';
      case 'utm_campaign':
        return 'utmCampaign';
      case 'utm_term':
        return 'utmTerm';
      case 'utm_content':
        return 'utmContent';
      default:
        return null;
    }
  }

  static serializeSignUpTemporary(user: UserSignUpSerialized): UserSignUpRaw {
    return {
      name: user.name,
      email: user.email.trim(),
      company_name: user.companyName,
      password: user.password,
      temporary_user_token: user.temporaryUserToken,
      coupon_codes: user.couponCodes,
      captcha_token: user.captchaToken,
    };
  }

  static serializeSignUp(user: UserSignUpSerialized): SignUpRaw {
    return {
      user: {
        name: user.name,
        email: user.email.trim(),
        company_name: user.companyName,
        password: user.password,
        token: user.token,
        coupon_codes: user.couponCodes,
        captcha_token: user.captchaToken,
      },
      utm: {
        source: user.utmProperties.utmSource,
        medium: user.utmProperties.utmMedium,
        campaign: user.utmProperties.utmCampaign,
        term: user.utmProperties.utmTerm,
        content: user.utmProperties.utmContent,
      },
    };
  }

  static serializeSignIn(signIn: UserSignInSerialized): UserSignInRaw {
    return {
      email: signIn.email.trim(),
      password: signIn.password,
    };
  }

  static serializeResetPassword(
    data: ResetPasswordSerialized,
  ): ResetPasswordRaw {
    return {
      new_password: data.newPassword,
      token: data.token,
    };
  }

  static serializeUser(data: UserType): UserUpdateRaw {
    return {
      user: {
        name: data.name,
        email: data.email.trim(),
        company_name: data.companyName,
        recording_tips: data.recordingTips,
        state: data.state,
        backup_name: data.backupName,
        backup_email: data.backupEmail?.trim(),
        country: data.country,
        first_visit_displayed: data.firstVisitDisplayed,
      },
    };
  }

  static deSerializeUser(data: UserRaw): UserType {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      companyName: data.company_name,
      recordingTips: data.recording_tips,
      stripeClientSecret: data.stripe_client_secret,
      backupEmail: data.backup_email,
      backupName: data.backup_name,
      country: data.country,
      state: data.state,
      verified: data.verified,
      firstAlbumPaymentProcessed: data.first_album_payment_processed,
      paymentMethods:
        data.payment_methods.map(PaymentSerializer.deSerializePaymentMethod) ||
        [],
      subscriptionDeals: data.subscription_deals
        ? data.subscription_deals.map(
            SubscriptionDealSerializer.deSerializeSubscriptionDealResponse,
          )
        : [],
      permissions: data.permissions
        ? this.deserializeUserPermissions(data.permissions)
        : undefined,
      firstVisitDisplayed: data.first_visit_displayed,
    };
  }

  static serializeVerificationEmail(
    data: VerifyEmailSerialized,
  ): VerifyEmailRaw {
    return {
      email: data.email.trim(),
      email_verification_token: data.emailVerificationToken,
    };
  }

  static serializeChangePassword(
    data: ChangePasswordRequestSerialized,
  ): ChangePasswordRequestRaw {
    return {
      password: data.password,
      new_password: data.newPassword,
    };
  }

  static serializeAddPaymentMethod(
    data: AddPaymentMethodRequestSerialized,
  ): AddPaymentMethodRequestRaw {
    return {
      payment_method_id: data.PaymentMethodId,
    };
  }

  static deSerializeTemporaryUserData(data: UserRaw): UserSerialized {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      companyName: data.company_name,
      recordingTips: data.recording_tips,
      stripeClientSecret: data.stripe_client_secret,
      backupEmail: data.backup_email,
      backupName: data.backup_name,
      country: data.country,
      state: data.state,
      verified: data.verified,
      firstAlbumPaymentProcessed: data.first_album_payment_processed || false,
      paymentMethods:
        data.payment_methods?.map(PaymentSerializer.deSerializePaymentMethod) ||
        [],
      subscriptionDeals: data.subscription_deals
        ? data.subscription_deals.map(
            SubscriptionDealSerializer.deSerializeSubscriptionDealResponse,
          )
        : [],
      permissions: data.permissions
        ? this.deserializeUserPermissions(data.permissions)
        : undefined,
      trialAlbumId: data.temporary_trial_album_id,
      firstVisitDisplayed: data.first_visit_displayed,
    };
  }

  static serializeTemporaryUserEmail(email: string) {
    return {
      reminder_temporary_email: email,
    };
  }
}

export { UserSerializer };
