import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { AppContext, appActions } from 'context';
import { classnames } from 'helpers/utils';
import { NotificationObject } from 'models/notificationObject';
import { NavSections, NotificationType } from 'common/enums';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import { PersonalInformation } from './components/personal-information';
import { BackupInformation } from './components/backup-information/backup-information';
import { PaymentMethodCard } from './components/payment-method-card';
import { DiscountCodesCards } from './components/discount-codes-cards';
import { SubscriptionsTable } from './components/subscriptions-table';
import { AccountInitialState, AccountReducer } from './account-reducer';
import styles from './account.module.scss';
import { ContributorTable } from './components/contributor-table/contributor-table';

const Account: React.FC = () => {
  const openAddPaymentMethodModal = !!new URLSearchParams(
    window.location.search,
  ).get('add-payment-method');

  const { state: generalState, dispatch: generalDispatch } =
    useContext(AppContext);
  const [accountState, accountDispatch] = useReducer(
    AccountReducer,
    AccountInitialState,
  );

  const [fetchingAlbums, setFetchingAlbums] = useState(false);
  const [fetchingContributorAlbums, setFetchingContributorAlbums] =
    useState<boolean>(false);

  const didMount = useRef(false);
  const concatNextAlbums = useRef(false);
  const concatNextAlbumsContributor = useRef(false);

  const fetchAlbums = async () => {
    try {
      const { data, pagination } = await AlbumsController.getAlbums(
        accountState.queryParams,
      );

      const newAlbums = data;

      accountDispatch({ type: 'UPDATE_ALBUMS', albums: newAlbums });
      accountDispatch({ type: 'UPDATE_PAGINATION', pagination });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Albums Information',
          message: 'Albums Information fails',
          type: NotificationType.Error,
        }),
      });
    } finally {
      setFetchingAlbums(false);
    }
  };

  const fetchContributorAlbums = async () => {
    try {
      const { data, pagination } = await AlbumsController.getAlbums(
        accountState.queryParamsContributor,
      );

      const newAlbums = data;

      accountDispatch({ type: 'UPDATE_ALBUMS_CONTRIBUTOR', albums: newAlbums });
      accountDispatch({ type: 'UPDATE_PAGINATION_CONTRIBUTOR', pagination });
    } catch (err: any) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Albums Information',
          message: 'Albums Information fails',
          type: NotificationType.Error,
        }),
      });
    } finally {
      setFetchingContributorAlbums(false);
    }
  };

  useEffect(() => {
    generalDispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.account,
    });
    generalDispatch({
      type: appActions.SET_HELP_SECTION,
      helpSection: HelpInfoSections.account_settings,
    });
    fetchAlbums();
    fetchContributorAlbums();
  }, []);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    setFetchingContributorAlbums(true);
    fetchContributorAlbums();
  }, [accountState.queryParamsContributor]);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    setFetchingAlbums(true);
    fetchAlbums();
  }, [accountState.queryParams]);

  useEffect(() => {
    accountDispatch({
      type: 'UPDATE_USER_NEW_DATA',
      userNewData: generalState.data.user,
    });
  }, [generalState.data.user]);

  const handleGetMoreAlbums = (page: number) => {
    const newPageNum = page;
    concatNextAlbums.current = true;

    const newQueryParamsCopy = { ...accountState.queryParams };
    newQueryParamsCopy.pageNum = newPageNum;

    accountDispatch({
      type: 'UPDATE_QUERY_PARAMS',
      queryParams: newQueryParamsCopy,
    });
  };

  const handleGetMoreContributorAlbums = (page: number) => {
    const newPageNum = page;
    concatNextAlbumsContributor.current = true;

    const newQueryParamsCopy = { ...accountState.queryParamsContributor };
    newQueryParamsCopy.pageNum = newPageNum;

    accountDispatch({
      type: 'UPDATE_QUERY_PARAMS_CONTRIBUTOR',
      queryParams: newQueryParamsCopy,
    });
  };

  return (
    <div className={globalStyles.loggedMainContainer}>
      <div
        className={classnames(
          styles.accountTitle,
          'text__heading4__textNeutral40',
        )}
      >
        {' '}
        Account{' '}
      </div>
      <div className={styles.accountContent}>
        <div className={styles.personalAndBackupInfoContainer}>
          <PersonalInformation
            className={classnames(
              globalStyles.infoCard,
              styles.personalInfoCard,
            )}
            generalDispatch={generalDispatch}
            accountDispatch={accountDispatch}
            generalState={generalState}
            accountState={accountState}
          />

          <BackupInformation
            className={classnames(globalStyles.infoCard, styles.backupInfoCard)}
            generalDispatch={generalDispatch}
            accountDispatch={accountDispatch}
            generalState={generalState}
            accountState={accountState}
          />
        </div>
        <div className={styles.payMethodDiscountAndTable}>
          <div className={styles.payMethodAndDiscount}>
            <PaymentMethodCard
              className={classnames(
                globalStyles.infoCard,
                styles.paymentMethodCard,
              )}
              generalState={generalState}
              openUpdatePayMethodModalAutomatically={openAddPaymentMethodModal}
              titleText="Payment Method"
              subtitleText="Albums you own will be charged to this payment method."
            />
            <DiscountCodesCards
              className={classnames(
                globalStyles.infoCard,
                styles.discountCodesCard,
              )}
              generalDispatch={generalDispatch}
              accountDispatch={accountDispatch}
              generalState={generalState}
              accountState={accountState}
            />
          </div>
          <div className={styles.subscriptionsTable}>
            <SubscriptionsTable
              className={classnames(
                globalStyles.infoCard,
                styles.discountCodesCard,
              )}
              accountState={accountState}
              fetchingAlbums={fetchingAlbums}
              notifyGetMoreAlbums={(page: number) => handleGetMoreAlbums(page)}
            />
          </div>
          <ContributorTable
            className={classnames(
              globalStyles.infoCard,
              styles.contributorTable,
            )}
            accountState={accountState}
            fetchingAlbums={fetchingContributorAlbums}
            notifyGetMoreAlbums={(page: number) =>
              handleGetMoreContributorAlbums(page)
            }
          />
        </div>
      </div>
    </div>
  );
};

export { Account };
