import React, { ChangeEvent, useEffect, useState } from 'react';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Notification } from 'common/notification';
import {
  classnames,
  getQueryParamsObject,
  isPasswordValid,
  noEmptyFields,
  noErrors,
} from 'helpers/utils';
import { Input } from 'common/input';
import { EyeIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonType } from 'common/button';
import { Spinner } from 'common/spinner';
import { UserController } from 'networking/controllers/user-controller';
import { goToPage, RouteName } from 'routes';
import { SignHeader } from 'common/sign-header';
import inheritGuardLogo from 'assets/images/inherit-guard-logo.png';
import { LocalStorageApi } from 'helpers/local-storage';
import { history } from 'routes/routes';
import styles from './sign-up-ign.module.scss';

type ParamType = {
  token: string;
  email: string;
};

type MandatoryResetFields = {
  password: string;
  repeatPassword: string;
};

const mandatoryFieldsErrors: MandatoryResetFields = {
  password: '',
  repeatPassword: '',
};

const SignUpIgn: React.FC = () => {
  const { email, token }: ParamType = getQueryParamsObject();

  const [state, setState] = useState({ password: '', repeatPassword: '' });
  const [errors, setErrors] = useState<MandatoryResetFields>(
    mandatoryFieldsErrors,
  );
  const [inputPassword, setInputPassword] = useState<boolean>(true);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState<boolean>(true);
  const [showError, setShowError] = useState(false);
  const [fetching, setFetching] = useState(false);
  const previousUrl = LocalStorageApi.get('previous-url');

  const validateField = <T extends keyof MandatoryResetFields>(field: T) => {
    let emptyFieldMessage = '';

    emptyFieldMessage =
      state[field].trim() === ''
        ? `${field.toUpperCase()} shouldn't be empty`
        : '';
    setErrors((prevState) => ({
      ...prevState,
      [field]: emptyFieldMessage.toUpperCase(),
    }));

    if (emptyFieldMessage) return;

    if (field === 'password') {
      const passwordMessage = !isPasswordValid(state[field])
        ? 'Invalid password. It must have at least 8 characters, one letter and one number.'
        : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: passwordMessage.toUpperCase(),
      }));

      if (passwordMessage) return;
    }

    if (field === 'repeatPassword') {
      const repeatMessage =
        state.password !== state.repeatPassword ? 'passwords do not match' : '';
      setErrors((prevState) => ({
        ...prevState,
        [field]: repeatMessage.toUpperCase(),
      }));
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = e;

    setState((current) => ({
      ...current,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    Object.keys(mandatoryFieldsErrors).forEach((field) => {
      validateField(field as keyof typeof mandatoryFieldsErrors);
    });

    if (!noErrors(errors)) {
      return;
    }

    setFetching(true);

    const resetPwdData: ResetPasswordType = {
      newPassword: state.password,
      token,
    };

    const signInData: UserSignInSerialized = {
      email,
      password: state.password,
    };

    try {
      await UserController.resetPassword(resetPwdData);
      await UserController.signInUser(signInData);

      if (previousUrl) {
        history.push(previousUrl);
      } else {
        goToPage(RouteName.Albums);
      }
    } catch (e) {
      setShowError(true);
    } finally {
      setFetching(false);
    }
  };

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  const showContentView = () => {
    const { password, repeatPassword } = state;

    return (
      <div className={styles.elements}>
        <div className={globalStyles.signBoxContent}>
          <SignHeader companyLogo={inheritGuardLogo} />
          <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
            {' '}
            Create Password{' '}
          </div>
          <div
            className={classnames(
              'text__body__regular__small__textNeutral30',
              'my-7',
            )}
          >
            Welcome to Generational Story. To get started, please create a
            password for your account to access your complimentary album from
            Inherit-Guard Network.
          </div>

          <div>
            <Input
              id="password"
              name="password"
              label="Password"
              placeholder="password"
              value={password}
              type={inputPassword ? 'password' : 'text'}
              errorMessage={errors.password}
              withIconEnd={
                <EyeIcon onClick={() => setInputPassword(!inputPassword)} />
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              onBlur={() => validateField('password')}
            />
          </div>

          <div>
            <Input
              id="repeatPassword"
              name="repeatPassword"
              label="Repeat password"
              placeholder="password"
              value={repeatPassword}
              type={inputPasswordRepeat ? 'password' : 'text'}
              errorMessage={errors.repeatPassword}
              withIconEnd={
                <EyeIcon
                  onClick={() => setInputPasswordRepeat(!inputPasswordRepeat)}
                />
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
              onBlur={() => validateField('repeatPassword')}
            />
          </div>
          <Button
            className={classnames(
              styles.confirmButton,
              fetching ? 'noClick fixSizeFetching' : '',
            )}
            onClick={() => handleSubmit()}
            buttonSize={ButtonSize.Medium}
            buttonType={ButtonType.Submit}
            disabled={!noEmptyFields({ password, repeatPassword })}
          >
            {fetching ? (
              <Spinner color="white" className="spinner-inside-button" />
            ) : (
              <> Confirm </>
            )}
          </Button>
        </div>
      </div>
    );
  };

  useEffect(
    () => () => {
      LocalStorageApi.set('previous-url', '');
    },
    [],
  );

  return (
    <div className={globalStyles.signMainContainer}>
      {showError && showErrorNotification()}
      {showContentView()}
    </div>
  );
};

export { SignUpIgn };
