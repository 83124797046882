/* eslint-disable max-len */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';

import { PlusIcon } from 'assets/icons';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { Notification } from 'common/notification';
import { Menu, PositionType } from 'common/menu';
import { WarningBadge } from 'common/warning-badge';
import { useMediaQuery } from 'hooks/use-media-query';
import { Breakpoints, QuestionsManagmentViewType } from 'common/enums';
import { NotificationObject } from 'models/notificationObject';
import { AppContext, appActions } from 'context';
import {
  classnames,
  isEditingAlbum,
  isEditingMyTemplates,
  isReadOnly,
  isReadOnlySubOrRestrictedAlbum,
} from 'helpers/utils';
import { cloneDeep } from 'lodash';
import { UserController } from 'networking/controllers/user-controller';
import { RouteName, goToPage } from 'routes';
import { useClickOutside } from 'hooks/click-outside';
import { ModalWarning } from 'common/modal-warning';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { AlbumsController } from 'networking/controllers/albums-controller';
import { HelpInfoLinks } from 'context/app-context/context-reducer';
import { PreBuiltQuestionsModal } from './components/pre-built-questions-modal';
import { NoTemplatesCreatedModal } from './components/no-templates-created-modal';
import { QuestionsList } from './components/questions-list';
import { CustomQuestionsModal } from './components/custom-questions-modal';
import styles from './interview-script.module.scss';

type InterviewScriptProps = {
  album?: AlbumType;
  maxQuestionsAllowed: number;
  clips?: ClipType[];
  unansQuestions?: QuestionType[];
  title?: string;
  questionsManagmentView: QuestionsManagmentViewType;
  setInterviewScript: Function;
  notifySaveNewQuestionsAndRedirect?: (
    pendingQuestionsToSave: QuestionType[],
  ) => Promise<void>;
};

const InterviewScript: React.FC<InterviewScriptProps> = ({
  album,
  maxQuestionsAllowed,
  setInterviewScript,
  unansQuestions = [],
  clips = [],
  title,
  questionsManagmentView,
  notifySaveNewQuestionsAndRedirect,
}) => {
  const {
    dispatch,
    state: {
      data: { user },
    },
  } = useContext(AppContext);

  const [showTemplatesModal, setShowTemplatesModal] = useState(false);
  const [showNoTemplatesCreatedModal, setShowNoTemplatesCreatedModal] =
    useState(false);

  const [showSuggestedQuestionsModal, setShowSuggestedQuestionsModal] =
    useState(false);
  const [showCustomQuestionsModal, setShowCustomQuestionsModal] =
    useState(false);

  const [templateList, setTemplateList] = useState<PreBuiltQuestionsType[]>([]);
  const [suggestedQuestionsList, setSuggestedQuestionsList] = useState<
    PreBuiltQuestionsType[]
  >([]);

  const [showRedirectModal, setShowRedirectModal] = useState<boolean>(false);

  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuRef = useRef(null);
  const auxRef = useRef<number>(0);
  const pendingQuestionsToSave = useRef<QuestionType[]>([]);
  const templateIds = useRef<string[]>([]);

  const [selectedQuestions, setSelectedQuestions] = useState<QuestionType[]>(
    [],
  );
  const [createdQuestions, setCreatedQuestions] = useState<QuestionType[]>([]);
  const [questionList, setQuestionList] =
    useState<QuestionType[]>(unansQuestions);

  const [remainingQuestions, setRemainingQuestions] = useState(
    maxQuestionsAllowed - clips.length,
  );

  const mobile = useMediaQuery(`(max-width: ${Breakpoints.xs}px)`);

  const setApiError = useHandleApiError(() => {
    setShowError(true);
  });

  useClickOutside(menuRef, () => setIsMenuOpen(false));

  const fetchDefaultQuestions = async () => {
    try {
      const { suggestedQuestions } =
        await AlbumsController.getDefaultQuestions();

      setSuggestedQuestionsList(suggestedQuestions);
    } catch (error: any) {
      setApiError(error);
    }
  };

  const containsQuestion = (q: QuestionType, list: QuestionType[]) => {
    const includesQuestion = list.some((e) => e.auxId === q.auxId);
    return includesQuestion;
  };

  const updateSelectedQuestionsOnList = () => {
    const newQuestions = selectedQuestions.filter(
      (q) => !containsQuestion(q, questionList),
    );
    setQuestionList((prevState) => [...prevState, ...newQuestions]);
  };

  useEffect(() => {
    fetchDefaultQuestions();
  }, []);

  useEffect(() => {
    if (isEditingAlbum(questionsManagmentView)) {
      setQuestionList(unansQuestions);
    }
  }, [unansQuestions]);

  useEffect(() => {
    updateSelectedQuestionsOnList();
  }, [selectedQuestions]);

  useEffect(() => {
    setInterviewScript(questionList, templateIds.current);
    setRemainingQuestions(
      maxQuestionsAllowed - clips.length - questionList.length,
    );

    templateIds.current = [];
  }, [questionList, clips]);

  const canKeepAddingQuestions = () => {
    // Album plan interview tab
    if (isEditingAlbum(questionsManagmentView)) {
      if (!isReadOnlySubOrRestrictedAlbum(album!)) return true;
    }

    // Templates view
    if (isEditingMyTemplates(questionsManagmentView)) {
      if (remainingQuestions > 0) return true;
    }

    return false;
  };

  const addQuestionsToList = (newQuestions: QuestionType[]) => {
    setQuestionList((prevState) => [...prevState, ...newQuestions]);
  };

  const updateCustomQuestions = (qs: QuestionType[]) => {
    const customQuestions = qs.map((question: QuestionType) => {
      auxRef.current += 1;
      return { ...question, auxId: `cq-${auxRef.current}`, isNew: true };
    });

    setCreatedQuestions(customQuestions);
    addQuestionsToList(customQuestions);
  };

  const getSelectedQuestionsFromTemplate = (
    template: PreBuiltQuestionsType,
  ) => {
    const filteredQuestions: QuestionType[] = [];

    template.questions.forEach((q: QuestionType) => {
      if (q.isSelected) {
        auxRef.current += 1;
        filteredQuestions.push({
          ...q,
          auxId: `t-${auxRef.current}`,
          isNew: true,
        });
      }
    });

    return filteredQuestions;
  };

  const getTemplateIds = (templastesList: PreBuiltQuestionsType[]) => {
    const idList: string[] = [];

    templastesList.forEach((template: PreBuiltQuestionsType) => {
      const anySelectedQuestion = template.questions.some(
        (question: QuestionType) => question.isSelected,
      );

      if (anySelectedQuestion) idList.push(template.id);
    });

    return idList;
  };

  const getAllSelectedQuestionsInTemplates = (
    templastesList: PreBuiltQuestionsType[],
  ) => {
    let allSelectedQuestions: QuestionType[] = [];

    templastesList.forEach((template: PreBuiltQuestionsType) => {
      const selectedQ = getSelectedQuestionsFromTemplate(template);

      allSelectedQuestions = allSelectedQuestions.concat(selectedQ);
    });

    return allSelectedQuestions;
  };

  const updateQuestionsFromTemplates = (
    templastesList: PreBuiltQuestionsType[],
  ) => {
    const allSelectedQuestionsInTemplates =
      getAllSelectedQuestionsInTemplates(templastesList);

    templateIds.current = getTemplateIds(templastesList);

    setQuestionList((prevState) => [
      ...prevState,
      ...allSelectedQuestionsInTemplates,
    ]);
  };

  const updateQuestionsFromSuggestedElements = (
    suggestedElementList: PreBuiltQuestionsType[],
  ) => {
    let allSelectedQuestions: QuestionType[] = [];

    suggestedElementList.forEach((template: PreBuiltQuestionsType) => {
      const selectedQ = getSelectedQuestionsFromTemplate(template);

      allSelectedQuestions = allSelectedQuestions.concat(selectedQ);
    });

    setQuestionList((prevState) => [...prevState, ...allSelectedQuestions]);
  };

  const removeFromList = (list: QuestionType[], q: QuestionType) =>
    list.filter((i) => i.auxId !== q.auxId);

  const deleteQuestion = (q: QuestionType) => {
    setQuestionList(removeFromList(questionList, q));
    if (containsQuestion(q, selectedQuestions)) {
      setSelectedQuestions(removeFromList(selectedQuestions, q));
    }
    if (containsQuestion(q, createdQuestions)) {
      setCreatedQuestions(removeFromList(createdQuestions, q));
    }
  };

  const editQuestion = (q: QuestionType) => {
    if (containsQuestion(q, selectedQuestions)) {
      setSelectedQuestions(removeFromList(selectedQuestions, q));
    }

    setQuestionList((prevState) =>
      prevState.map((e) => {
        if (e.auxId === q.auxId) {
          return { ...e, name: q.name };
        }
        return e;
      }),
    );
  };

  const showErrorNotification = () => (
    <div className={styles.error}>
      <Notification handleClose={() => setShowError(false)} />
    </div>
  );

  const hasQuestions = () => questionList.length > 0;

  const fetchCustomTemplates = async () => {
    try {
      const data = await UserController.getCustomTemplates();
      const { templates } = data;

      const existingQuestionsNameFlat = questionList.map(
        (question: QuestionType) => question.name,
      );

      templates.forEach((template: PreBuiltQuestionsType) => {
        template.questions.forEach((question: QuestionType) => {
          if (existingQuestionsNameFlat.includes(question.name)) {
            // eslint-disable-next-line no-param-reassign
            question.alreadyAdded = true;
          }
        });
      });

      setTemplateList(templates);

      if (templates && templates.length) {
        setShowTemplatesModal(true);
      } else {
        setShowNoTemplatesCreatedModal(true);
      }
    } catch (err: any) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error getting custom templates',
        }),
      });
    }
  };

  const handleShowCustomTemplates = async () => {
    await fetchCustomTemplates();
  };

  const showMenu = () => (
    <Menu width={styles.menu} position={PositionType.BottomLeft}>
      <button
        type="button"
        onClick={() => setShowSuggestedQuestionsModal(true)}
        className={classnames(
          'text__body__regular__medium__textNeutral30',
          styles.copyLinkOption,
        )}
      >
        Add suggested prompt
      </button>
      <hr />
      <button
        type="button"
        onClick={() => setShowCustomQuestionsModal(true)}
        className={classnames('text__body__regular__medium__textNeutral30')}
      >
        Type custom prompt
      </button>
      {isEditingAlbum(questionsManagmentView) &&
        user.permissions?.permissions.templatesManagement && (
          <>
            <hr />
            <button
              type="button"
              onClick={() => handleShowCustomTemplates()}
              className={classnames(
                'text__body__regular__medium__textNeutral30',
              )}
            >
              My templates
            </button>
          </>
        )}
    </Menu>
  );

  const showCollapsedButton = () => (
    <div className={styles.disabledContainer}>
      <div
        role="button"
        ref={menuRef}
        className={
          !canKeepAddingQuestions()
            ? classnames(
                ...[
                  styles.disabledCollapsedButton,
                  'text__body__semi__bold__small__textNeutral20',
                ],
              )
            : classnames(
                ...[
                  styles.collapsedButton,
                  'text__body__semi__bold__small__primary50',
                ],
              )
        }
        onClick={() => setIsMenuOpen((prevState) => !prevState)}
        aria-hidden="true"
        tabIndex={0}
      >
        <PlusIcon />
        Add prompts
        {isMenuOpen && showMenu()}
      </div>
    </div>
  );

  const reorderQuestions = (
    list: QuestionType[],
    startIndex: number,
    endIndex: number,
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const orderedList = reorderQuestions(
      questionList,
      source.index,
      destination.index,
    );

    setQuestionList(orderedList);
    setInterviewScript(orderedList);
  };

  const getSuggestedElementsWithAddedLabel = () => {
    const suggestedQuestionsListCopy = cloneDeep(suggestedQuestionsList);
    const existingQuestionsNameFlat = questionList.map(
      (question: QuestionType) => question.name,
    );

    suggestedQuestionsListCopy.forEach(
      (suggestedElement: PreBuiltQuestionsType) => {
        suggestedElement.questions.forEach((question: QuestionType) => {
          if (existingQuestionsNameFlat.includes(question.name)) {
            // eslint-disable-next-line no-param-reassign
            question.alreadyAdded = true;
          }
        });
      },
    );

    return suggestedQuestionsListCopy;
  };

  const concatAllSelectedQuestionFromAllTemplates = (
    templatesList: PreBuiltQuestionsType[],
  ) => {
    let allSelectedQuestions: QuestionType[] = [];

    templatesList.forEach((template: PreBuiltQuestionsType) => {
      const selectedQ = getSelectedQuestionsFromTemplate(template);

      allSelectedQuestions = allSelectedQuestions.concat(selectedQ);
    });

    return allSelectedQuestions;
  };

  const handleModalRedirectToTemplatesView = (
    templatesList: PreBuiltQuestionsType[],
  ) => {
    const newUnansweredQuestions =
      concatAllSelectedQuestionFromAllTemplates(templatesList);
    const existingUnansweredQuestions = unansQuestions;

    pendingQuestionsToSave.current = existingUnansweredQuestions.concat(
      newUnansweredQuestions,
    );
    setShowRedirectModal(true);
  };

  const handleSaveQuestionsAndRedirect = async () => {
    try {
      await notifySaveNewQuestionsAndRedirect!(pendingQuestionsToSave.current);
      goToPage(RouteName.Templates);
    } catch (err: any) {
      dispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Templates Information',
          message: 'Error saving questions',
        }),
      });
    }
  };

  const showQuestionList = () => (
    <QuestionsList
      album={album}
      isEditingAlbum={isEditingAlbum(questionsManagmentView)}
      questions={questionList}
      deleteQuestion={deleteQuestion}
      editQuestion={editQuestion}
      handleDragEnd={onDragEnd}
    />
  );

  const showSelectTemplateModalComponent = () => (
    <PreBuiltQuestionsModal
      title="Add templates"
      subTitle="Templates"
      questionsManagmentView={questionsManagmentView}
      showModifyPreBuiltElements
      preBuiltQuestonsList={templateList}
      remainingQuestionsCount={remainingQuestions}
      maxQuestionsAllowed={maxQuestionsAllowed}
      onClose={() => setShowTemplatesModal(false)}
      onAddQuestionsFromPreBuiltElementList={(
        preBuiltElementList: PreBuiltQuestionsType[],
      ) => {
        updateQuestionsFromTemplates(preBuiltElementList);
      }}
      notifyShowModalRedirectToTemplatesView={(
        preBuiltElementList: PreBuiltQuestionsType[],
      ) => handleModalRedirectToTemplatesView(preBuiltElementList)}
    />
  );

  const showRedirectModalComponent = () => (
    <ModalWarning
      title="Redirecting to templates"
      content="You are about to be redirected to your templates page. Do you want to add the selected questions to your album first?"
      successButtonText="Add & redirect"
      successStyleButton={ButtonStyle.PrimaryFilled}
      closeButtonText="Discard questions & redirect"
      closeStyleButton={ButtonStyle.GreyGhost}
      closeFn={() => {
        goToPage(RouteName.Templates);
      }}
      successFn={() => {
        handleSaveQuestionsAndRedirect();
      }}
    />
  );

  const showSuggestedQuestionsModalComponent = () => (
    <PreBuiltQuestionsModal
      title="Add prompts"
      subTitle="Suggested prompts"
      questionsManagmentView={questionsManagmentView}
      showModifyPreBuiltElements={false}
      preBuiltQuestonsList={getSuggestedElementsWithAddedLabel()}
      remainingQuestionsCount={remainingQuestions}
      maxQuestionsAllowed={maxQuestionsAllowed}
      onClose={() => setShowSuggestedQuestionsModal(false)}
      onAddQuestionsFromPreBuiltElementList={(
        templates: PreBuiltQuestionsType[],
      ) => {
        updateQuestionsFromSuggestedElements(templates);
      }}
    />
  );

  const showNoTemplatesCreatedModalComponent = () => (
    <NoTemplatesCreatedModal
      onClose={() => setShowNoTemplatesCreatedModal(false)}
    />
  );

  const showCustomQuestionsModalComponent = () => (
    <CustomQuestionsModal
      questionsManagmentView={questionsManagmentView}
      onClose={() => setShowCustomQuestionsModal(false)}
      onAddQuestions={(addedQuestions: QuestionType[]) => {
        updateCustomQuestions(addedQuestions);
      }}
      remainingQuestions={remainingQuestions}
    />
  );

  const noQuestionsSection = () => (
    <div className={styles.emptyContainer}>
      <p className="text__title2__textNeutral30">
        Your interview script is empty
      </p>
      <p className="text__body__regular__medium__textNeutral30">
        Select curated prompts and topic categories, type in custom prompts of
        your own or choose from your templates.
      </p>
      <div className={styles.buttonContainer}>
        <Button
          buttonSize={mobile ? ButtonSize.Tiny : ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryStroke}
          onClick={() => setShowSuggestedQuestionsModal(true)}
          className={styles.button}
        >
          Suggested prompts
        </Button>
        <Button
          buttonSize={mobile ? ButtonSize.Tiny : ButtonSize.Small}
          buttonStyle={ButtonStyle.PrimaryStroke}
          onClick={() => setShowCustomQuestionsModal(true)}
          className={styles.button}
        >
          Custom prompt
        </Button>
        {isEditingAlbum(questionsManagmentView) && (
          <Button
            buttonSize={mobile ? ButtonSize.Tiny : ButtonSize.Small}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={() => handleShowCustomTemplates()}
            className={styles.button}
            disabled={!user.permissions?.permissions.templatesManagement}
            tooltip={!user.permissions?.permissions.templatesManagement}
            tooltipContent="Available after sign up"
            tooltipId="my-templates-tooltip"
          >
            My templates
          </Button>
        )}
      </div>
    </div>
  );

  const shouldShowQuestionButtons = () => {
    // Templates view
    if (isEditingMyTemplates(questionsManagmentView)) return true;

    // Plan interview tab
    if (isEditingAlbum(questionsManagmentView) && !isReadOnly(album!))
      return true;

    return false;
  };

  const showWarningBadge = () => {
    if (canKeepAddingQuestions()) return null;

    return (
      <WarningBadge
        className={classnames(styles.warningMessage, {
          invisible:
            isEditingAlbum(questionsManagmentView) && isReadOnly(album!),
        })}
        message="You’ve reached the limit of questions per album."
      />
    );
  };

  useEffect(() => {
    dispatch({
      type: appActions.SET_HELP_LINKS,
      helpLinks: {
        [HelpInfoLinks.pickPrompts]: () => setShowSuggestedQuestionsModal(true),
        [HelpInfoLinks.typePrompts]: () => setShowCustomQuestionsModal(true),
        [HelpInfoLinks.chooseTemplate]: async () => {
          await handleShowCustomTemplates();
        },
      },
    });
  }, []);

  return (
    <>
      {showTemplatesModal && showSelectTemplateModalComponent()}
      {showNoTemplatesCreatedModal && showNoTemplatesCreatedModalComponent()}
      {showCustomQuestionsModal && showCustomQuestionsModalComponent()}
      {showSuggestedQuestionsModal && showSuggestedQuestionsModalComponent()}
      {showRedirectModal && showRedirectModalComponent()}
      {showError && showErrorNotification()}

      <>
        <div className={styles.header}>
          <div className={styles.questionCounter}>
            <p className={classnames('text__title2__textNeutral40')}>
              <span>{title}</span>
              <span
                className={classnames({
                  invisible: isEditingAlbum(questionsManagmentView),
                })}
              >
                {` (${
                  questionList.length + clips.length
                }/${maxQuestionsAllowed})`}
              </span>
            </p>
          </div>
          <div className={styles.actionableHeader}>
            <div className={styles.hideMobile}>{showWarningBadge()}</div>

            {hasQuestions() && showCollapsedButton()}
          </div>
        </div>
        <div className={styles.showMobile}>{showWarningBadge()}</div>
        <div className={styles.unansweredQuestions}>
          {isEditingAlbum(questionsManagmentView) &&
            !hasQuestions() &&
            !shouldShowQuestionButtons() && (
              <div className={styles.emptyEditUnanswered}>
                <p className="text__title2__textNeutral30">
                  No questions left.
                </p>
              </div>
            )}
          {!hasQuestions() &&
            shouldShowQuestionButtons() &&
            noQuestionsSection()}

          {showQuestionList()}
        </div>
      </>
    </>
  );
};

export { InterviewScript };
