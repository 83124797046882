import { posthogHost, posthogKey } from 'config/constants';
import { LocalStorageApi } from 'helpers/local-storage';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

const isImpersonating = () => {
  // get impersonating query parameter
  const urlParams = new URLSearchParams(window.location.search);
  const impersonating =
    urlParams.get('is_impersonating') ||
    LocalStorageApi.get('is_impersonating');

  if (impersonating) {
    // set impersonating cookie
    LocalStorageApi.set('is_impersonating', true);
  }

  return !!impersonating;
};

// If we are impersonating, we don't want to send events to PostHog
if (!isImpersonating() && posthogKey) {
  posthog.init(posthogKey, {
    api_host: posthogHost,
  });
}

export const AppPostHogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => <PostHogProvider client={posthog}>{children}</PostHogProvider>;

export const identifyUser = (userId: number) =>
  posthog.identify(userId as unknown as string);

type PostHogEvent = {
  // Modals
  modalOpened: {
    modalTitle: string;
  };
  modalConfirmButtonClicked: {
    modalTitle: string;
    buttonText: string;
  };
  modalClosed: {
    modalTitle: string;
  };
  // Login / Sign up
  login: {};
  logout: {};
  forgotPassword: {};
  sendPasswordResetEmail: {};
  toggleUSACheckbox: {
    checked: boolean;
  };
  typeDiscountCode: {
    step: 'signUp' | 'createAlbum';
    discountCode: string;
  };
  applyDiscountCode: {
    step: 'signUp' | 'createAlbum';
    discountCode: string;
  };
  startRemoveDiscountCode: {
    discountCode: string;
  };
  confirmRemoveDiscountCode: {
    discountCode: string;
  };
  createAccount: {};
  // Account
  // Albums
  toggleListView: {};
  toggleGridView: {};
  openSortBy: {};
  modifySortBy: {
    sortBy: 'a-z' | 'z-a' | 'newest' | 'oldest';
  };
  startAlbumCreation: {
    location: 'topBar' | 'center';
  };
  startAlbumCreationCancel: {};
  confirmAlbumCreationCancel: {};
  learnMoreAboutSubscriptions: {};
  selectAlbumTier: {
    tier: 'trial' | 'standard' | 'legacy';
  };
  createAlbumNext: {};
  enterAlbumName: {
    albumName: string;
  };
  addAlbumCoverPhoto: {};
  startAddContributor: {
    step: 'createAlbum' | 'aboutAlbum';
  };
  confirmAddContributor: {
    step: 'createAlbum' | 'aboutAlbum';
    contributorName: string;
    contributorEmail: string;
  };
  startRemoveContributor: {
    step: 'createAlbum' | 'aboutAlbum';
  };
  confirmRemoveContributor: {
    step: 'createAlbum' | 'aboutAlbum';
    contributorName: string;
    contributorEmail: string;
  };
  createNewAlbum: {};
  startAddPaymentMethod: {
    step: 'createAlbum' | 'account';
  };
  cancelAddPaymentMethod: {
    step: 'createAlbum' | 'account';
  };
  confirmAddPaymentMethod: {
    step: 'createAlbum' | 'account';
  };
  confirmAlbumPayment: {};
  cancelAlbumPayment: {};
  //   Album details
  copyLinkToAlbum: {};
  openAlbumMenu: {};
  startEditAlbum: {};
  confirmEditAlbum: {};
  startDeleteAlbum: {};
  confirmDeleteAlbum: {};
  startRequestAlbumDownload: {};
  confirmRequestAlbumDownload: {};
  selectAlbumTab: {
    tab: 'clips' | 'planInterview' | 'videoCall' | 'uploadVideos' | 'about';
  };
  //   Clips tab
  //  Plan interview tab
  openMobileStoreLink: {
    store: 'apple' | 'android';
  };
  startAddSuggestedQuestions: {};
  toggleAddSuggestedQuestionsSection: {
    section: string;
    isExpanded: boolean;
  };
  addSuggestedQuestion: {
    question: string;
  };
  startAddCustomQuestion: {};
  typeCustomQuestion: {
    question: string;
  };
  typeAnotherCustomQuestion: {};
  addAllCustomQuestions: {};
  startAddTemplates: {};
  toggleTemplateExpanded: {
    title: string;
    isExpanded: boolean;
  };
  toggleTemplateSelected: {
    title: string;
    selected: boolean;
  };
  toggleTemplateQuestionSelected: {
    question: string;
    selected: boolean;
  };
  addSelectedTemplates: {};
  reorderQuestions: {};
  startEditQuestion: {
    question: string;
  };
  cancelEditQuestion: {
    question: string;
  };
  confirmEditQuestion: {
    question: string;
  };
  startDeleteQuestion: {
    question: string;
  };
  confirmDeleteQuestion: {
    question: string;
  };
  // Video call tab
  viewVideoCallTab: {};
  sendLinkToContributor: {};
  joinCall: {
    isOwner: boolean;
  };
  toggleVideo: {
    insideCall: boolean;
  };
  toggleAudio: {
    insideCall: boolean;
  };
  openMicMenu: {
    microphoneName: string;
  };
  selectMic: {
    microphoneName: string;
  };
  // Upload videos tab
  browseFiles: {};
  uploadStarted: {
    clipName: string;
  };
  uploadCompleted: {
    clipName: string;
  };
  seeUploadDetails: {
    clipName: string;
  };
  // About tab
  modifyOwnerInAccountSettings: {};
  modifySubscriptionInAccountSettings: {};
};

// take event name and event properties as separate arguments
export const captureEvent = <T extends keyof PostHogEvent>(
  eventName: T,
  eventProperties: PostHogEvent[T] = {} as PostHogEvent[T],
) => {
  if (!isImpersonating() && posthogKey) {
    posthog.capture(eventName, eventProperties);
  }
};
