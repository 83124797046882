import React, { useContext } from 'react';
import { classnames } from 'helpers/utils';
import { SelfRecordingContext } from 'context/self-recording-context';
import styles from './questions-selector.module.scss';

type QuestionSelectorProps = {
  album: AlbumType;
  selectedQuestion?: ClipType;
  onSelectQuestion: (id?: string) => void;
  isCustomRecording: boolean;
  onSelectCustomRecording: () => void;
};

const QuestionsSelector: React.FC<QuestionSelectorProps> = ({
  album,
  selectedQuestion,
  onSelectQuestion,
  isCustomRecording,
  onSelectCustomRecording,
}) => {
  const { state } = useContext(SelfRecordingContext);
  const questionDisabled = state.isRecording || state.isUploading;
  return (
    <div className={styles.questionsList}>
      <div className={styles.lineSeparator} />
      <div
        className={classnames(
          'text__body__semi__bold__large__textNeutral20',
          styles.question,
        )}
      >
        +
        <button
          type="button"
          className={classnames(styles.questionTitle, {
            [styles.questionEnabled]: !questionDisabled,
            [styles.questionSelected]: isCustomRecording,
            [styles.questionDisabled]: questionDisabled && !isCustomRecording,
          })}
          onClick={onSelectCustomRecording}
          disabled={questionDisabled}
        >
          Custom Recording
        </button>
      </div>
      {album.unansweredQuestions?.map((question, index) => (
        <>
          <div className={styles.lineSeparator} />
          <div
            className={classnames(
              'text__body__semi__bold__large__textNeutral20',
              styles.question,
            )}
          >
            {index + 1}
            <button
              type="button"
              className={classnames(styles.questionTitle, {
                [styles.questionEnabled]: !questionDisabled,
                [styles.questionSelected]: selectedQuestion?.id === question.id,
                [styles.questionDisabled]:
                  questionDisabled && selectedQuestion?.id !== question.id,
              })}
              onClick={() => onSelectQuestion(question.id)}
              disabled={questionDisabled}
            >
              {question.name}
            </button>
          </div>
        </>
      ))}
    </div>
  );
};

export { QuestionsSelector };
