import { Dispatch, useState } from 'react';
import { classnames } from 'helpers/utils';
import { InfoIcon } from 'assets/icons';
import { SubscriptionCard } from 'common/subscription-card';
import { DiscountCode } from 'common/discount_code';
import { maxNumberDiscountCodes, rootUrl } from 'config/constants';
import { SubscriptionController } from 'networking/controllers/subscripton-controller';
import { DiscountCodeDetailsModal } from 'common/discount-code-details-modal';
import { HelperText } from 'common/helper-text';
import { InitialStateType } from 'context/app-context/context-reducer';
import { DiscountCodeItem } from 'common/discount-code-item';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import { appActions } from 'context';
import { NotificationType } from 'common/enums';
import { NotificationObject } from 'models/notificationObject';
import { getDiscountedPriceIfBetter } from 'helpers/subscription-utils';
import { trackCustomEvent } from 'helpers/analytics';
import { AlbumNewStateType, NewAlbumAction } from '../../album-new-reducer';
import styles from './step-subscription-type.module.scss';

type StepSubscriptionTypeProps = {
  generalState: InitialStateType;
  newAlbumState: AlbumNewStateType;
  generalDispatch: Dispatch<any>;
  newAlbumDispatch: Dispatch<NewAlbumAction>;
};

const StepSubscriptionType: React.FC<StepSubscriptionTypeProps> = ({
  generalState,
  newAlbumState,
  newAlbumDispatch,
  generalDispatch,
}) => {
  const { subscriptionSelected, discountedPrices, couponCode } = newAlbumState;

  const { user } = generalState.data;

  const { subscriptionDeals } = generalState.data.user;
  const subscriptionsScheme = generalState.subscriptionsScheme!;

  const [showDiscountDetailsModal, setShowDiscountDetailsModal] =
    useState(false);

  const getSubsAllowedForNewAlbum = (
    actualUser: UserType,
    subsList: SubscriptionTypeType[],
  ) =>
    subsList.filter((sub: SubscriptionTypeType) => {
      const { permissions, onlyFirstAlbum } = sub;

      return (
        permissions!.create &&
        (!onlyFirstAlbum || !actualUser.firstAlbumPaymentProcessed)
      );
    });

  const showTitleCards = () => {
    const subTitleCardText = () => (
      <>
        <span className="text__body__regular__small__textNeutral30">
          {' '}
          Not Sure?{' '}
        </span>
        <a
          className={classnames('text__body__regular__small__primary50')}
          href={`${rootUrl}/pricing`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span> Learn more about our subscriptions. </span>
        </a>
      </>
    );

    return (
      <>
        <div className="text__title2__textNeutral40">
          What type of album do you want to create?
        </div>
        <div className={styles.subTitle}>
          <HelperText
            content={subTitleCardText()}
            icon={<InfoIcon className={classnames(styles.infoIcon)} />}
            className={styles.helperText}
          />
        </div>
      </>
    );
  };

  const showTitleDiscounts = () => (
    <>
      <span className="text__title2__textNeutral40">Discounts</span>
      <div className={styles.subTitle}>
        <HelperText
          content="Your discount code will apply to all elegible albums."
          icon={<InfoIcon className={classnames(styles.infoIcon)} />}
          className={styles.helperText}
        />
      </div>
    </>
  );

  const showAlbumsSubscriptionCards = (
    actualUser: UserType,
    susbs: SubscriptionTypeType[],
  ) => {
    const subsAllowedForNewAlbum = getSubsAllowedForNewAlbum(actualUser, susbs);

    return subsAllowedForNewAlbum.map((subscription: SubscriptionTypeType) => (
      <SubscriptionCard
        key={subscription.id}
        subscription={subscription}
        cardSelected={subscriptionSelected.id === subscription.id}
        betterPrice={getDiscountedPriceIfBetter(subscription, discountedPrices)}
        notifyClickCard={() => {
          newAlbumDispatch({
            type: 'SUBSCRIPTION_SELECTED',
            subSelected: subscription,
          });
        }}
      />
    ));
  };

  const handleChangeCouponCode = (newValue: string) => {
    newAlbumDispatch({
      type: 'COUPON_INPUT',
      couponCode: newValue,
    });
  };

  const getNewSubscriptionsPrices = async () => {
    const response = await SubscriptionController.getSubscriptionPrices();

    newAlbumDispatch({
      type: 'UPDATE_SUBSCRIPTION_NEW_PRICES',
      discountedPrices: response.prices,
    });
  };

  const deleteCouponCode = async (deal: SubscriptionDealResponseType) => {
    try {
      await SubscriptionController.removeSubscriptionDealCode(deal.id);

      trackCustomEvent('confirmRemoveDiscountCode', { discountCode: deal.id });

      const newShallowSubDealsListCopy = subscriptionDeals.filter(
        (coupon) => coupon.id !== deal.id,
      );

      const newUserData = {
        ...generalState.data.user,
        subscriptionDeals: newShallowSubDealsListCopy,
      };

      generalDispatch({
        type: appActions.USER_LOGGED,
        data: { user: newUserData },
      });

      getNewSubscriptionsPrices();
    } catch (err) {
      generalDispatch({
        type: appActions.NOTIFICATION,
        notification: new NotificationObject({
          show: true,
          title: 'Discount code',
          message: "Discount code couldn't be deleted",
          type: NotificationType.Error,
        }),
      });
    }
  };

  const showDiscountList = (codesList: SubscriptionDealResponseType[]) => (
    <div className={styles.discountCodesList}>
      {codesList.map((deal: SubscriptionDealResponseType) => (
        <DiscountCodeItem
          key={deal.id}
          deal={deal}
          deleteCouponCode={deleteCouponCode}
        />
      ))}
    </div>
  );

  const showDiscountDetailsButton = () => (
    <div className={styles.discountDetailsButtonContent}>
      <InfoIcon className={classnames(styles.infoIcon)} />
      <Button
        buttonSize={ButtonSize.Small}
        buttonStyle={ButtonStyle.PrimaryGhostLink}
        onClick={() => setShowDiscountDetailsModal(true)}
      >
        Discount details
      </Button>
    </div>
  );

  const showDiscountDetailsModalComponent = (
    discountList: SubscriptionDealResponseType[],
  ) => (
    <DiscountCodeDetailsModal
      discountCodesList={discountList}
      notifyCloseModal={() => setShowDiscountDetailsModal(false)}
    />
  );

  const handleChangeCoupon = (newValue: string) => {
    newAlbumDispatch({ type: 'COUPON_INPUT', couponCode: newValue });
  };

  const handleApplyCode = async () => {
    const response =
      await SubscriptionController.applySubscriptionDealByCode(couponCode);
    trackCustomEvent('applyDiscountCode', {
      step: 'createAlbum',
      discountCode: couponCode,
    });
    generalDispatch({
      type: appActions.USER_LOGGED,
      data: { user: response.user },
    });

    return response;
  };

  return (
    <>
      {showDiscountDetailsModal &&
        showDiscountDetailsModalComponent(subscriptionDeals)}

      <div className={styles.cardsAndDiscountColumns}>
        <div className={styles.cardsColumn}>
          <div className={styles.cardTitleContainer}>{showTitleCards()}</div>
          <div className={styles.cardsContainer}>
            {showAlbumsSubscriptionCards(user, subscriptionsScheme)}
          </div>
        </div>
        <div className={styles.discountColumn}>
          <div className={styles.discountsTitleContainer}>
            {showTitleDiscounts()}
          </div>
          <DiscountCode
            couponCode={couponCode}
            canAddMore={subscriptionDeals.length < maxNumberDiscountCodes}
            notifyApplyNewCode={() => handleApplyCode()}
            notifyChangeCoupon={handleChangeCoupon}
            onSuccess={() => {
              handleChangeCouponCode('');
              getNewSubscriptionsPrices();
            }}
          />

          {subscriptionDeals.length ? (
            <>
              {showDiscountList(subscriptionDeals)}
              {showDiscountDetailsButton()}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { StepSubscriptionType };
