import { Modal } from 'common/modal';
import React, { useRef } from 'react';
import { Button, ButtonSize, ButtonStyle } from 'common/button';
import styles from './modal-choose-guest-type.module.scss';

type ModalChooseGuestTypeProps = {
  onClickInviteGuests: () => void;
  onClickInviteContributors: () => void;
  handleClose: () => void;
};

const ModalChooseGuestType: React.FC<ModalChooseGuestTypeProps> = ({
  onClickInviteContributors,
  onClickInviteGuests,
  handleClose,
}) => {
  const modalRef = useRef(null);
  return (
    <Modal contentClassName={styles.container} onClose={handleClose}>
      <div ref={modalRef} className={styles.content}>
        <div className="text__heading4__primary50">
          Do you want to invite contributors or one time guests?
        </div>
        <p className="text__body__regular__medium__textNeutral30">
          <span className="text__body__semi__bold__medium__primary50">
            Contributors have permanent management access to the album.
          </span>{' '}
          They can add prompts, see, add, edit and delete content. You’re
          allowed to have up 1 contributor on free albums, 5 on standard and 10
          on lifetime or legacy.
        </p>
        <p className="text__body__regular__medium__textNeutral30">
          <span className="text__body__semi__bold__medium__primary50">
            Guests have limited access to add content to the album.
          </span>{' '}
          They can’t add, edit or delete content that’s already there or modify
          the prompts. They can access the album for a week, after that they
          will have to be re-invited.
        </p>
        <div className={styles.buttons}>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={onClickInviteGuests}
          >
            Invite guest(s)
          </Button>
          <Button
            buttonSize={ButtonSize.Large}
            buttonStyle={ButtonStyle.PrimaryStroke}
            onClick={onClickInviteContributors}
          >
            Invite contributor(s)
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { ModalChooseGuestType };
