import { useContext, useEffect, useState } from 'react';
import { appActions, AppContext } from 'context';
import { SignHeader } from 'common/sign-header';
import { classnames } from 'helpers/utils';
import { Line } from 'common/line';
import { Button } from 'common/button';
import { CornerLogoutButton } from 'common/corner-logout-button';
import { Footer, FooterType } from 'common/footer';
import { goToPage, RouteName } from 'routes';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import { Spinner } from 'common/spinner';
import { UserController } from 'networking/controllers/user-controller';
import { useParams } from 'react-router-dom';
import { LocalStorageApi } from 'helpers/local-storage';
import styles from './email-verifying.module.scss';
import { history } from '../../routes/routes';

type ParamType = {
  emailVerificationToken: string;
};

enum Viewstatus {
  Verifying = 'verifying',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

const EmailVerifying = () => {
  const { state, dispatch } = useContext(AppContext);

  const { emailVerificationToken } = useParams<ParamType>();
  const email = new URLSearchParams(window.location.search).get('email');

  const [viewStatus, setViewStatus] = useState(Viewstatus.Verifying);
  const [fetching, setFetching] = useState(true);

  const previousUrl = LocalStorageApi.get('previous-url');

  const verifyUser = async () => {
    try {
      await UserController.verifyUserEmail({
        emailVerificationToken,
        email: email!,
      });

      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });

      setViewStatus(Viewstatus.Succeeded);
    } catch (err: any) {
      const data = await UserController.me();
      dispatch({ type: appActions.USER_LOGGED, data });

      if (data.user.verified) {
        goToPage(RouteName.Home);
      } else {
        setViewStatus(Viewstatus.Failed);
      }
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (!emailVerificationToken || !email) {
      goToPage(RouteName.NotFoundRedirect);
    } else {
      verifyUser();
    }
  }, []);

  const handleAction = () => {
    if (viewStatus === Viewstatus.Failed) {
      setViewStatus(Viewstatus.Verifying);
      setFetching(true);
      verifyUser();
    }

    if (viewStatus === Viewstatus.Succeeded) {
      if (state.data.user.id) {
        if (previousUrl) {
          history.push(previousUrl);
          LocalStorageApi.set('previous-url', '');
        } else {
          goToPage(RouteName.Home);
        }
      } else {
        goToPage(RouteName.SignIn);
      }
    }
  };

  const showVeryfing = () => (
    <div className={styles.spinnerContent}>
      <Spinner className={globalStyles.mediumSpinner} />
    </div>
  );

  const showSucceded = () => (
    <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
      Thank you for verifying your email!
    </div>
  );

  const showFailed = () => (
    <>
      <div className={classnames('text__title1__textNeutral40', 'mt-15')}>
        Oh no, something went wrong.
      </div>
      <div
        className={classnames(
          'text__body__regular__medium__textNeutral30',
          'mt-8',
        )}
      >
        Please click the button below to try again. If that doesn’t work, click
        the link on your email again.
      </div>
    </>
  );

  const showContent = () => (
    <div className={globalStyles.signMainContainer}>
      <CornerLogoutButton />

      <div className={globalStyles.signBoxContent}>
        <div className={styles.elements}>
          <SignHeader />
          {viewStatus === Viewstatus.Verifying && showVeryfing()}
          {viewStatus === Viewstatus.Succeeded && showSucceded()}
          {viewStatus === Viewstatus.Failed && showFailed()}

          <Line className={styles.line} />

          <Button disabled={fetching} onClick={handleAction}>
            {viewStatus === Viewstatus.Failed ? 'Verify Email' : 'Continue'}
          </Button>
        </div>
      </div>
      <Footer footerType={FooterType.NoSignedUser} />
    </div>
  );

  return <>{showContent()}</>;
};

export { EmailVerifying };
