/* eslint-disable max-len */
import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react';
import { Spinner } from 'common/spinner';
import { Notification } from 'common/notification';
import { AlbumsController } from 'networking/controllers/albums-controller';
import globalStyles from 'assets/stylesheets/global-styles.module.scss';
import {
  classnames,
  getAlbumStatusLabel,
  isTemporaryUser,
} from 'helpers/utils';
import { Select } from 'common/select';
import { Breakpoints, NavSections } from 'common/enums';
import {
  albumsOrderByOptions,
  albumsTitles,
  paginationDefault,
} from 'config/constants';
import { Button, ButtonSize, ButtonType, ButtonStyle } from 'common/button';
import { goToPage, RouteName } from 'routes';
import { useMediaQuery } from 'hooks/use-media-query';
import { Input } from 'common/input';
import { SearchIcon, ColumnsIcon, ListIcon, PlusIcon } from 'assets/icons';
import { appActions, AppContext } from 'context';
import { AlbumsQueryParams } from 'models/albumsQueryParams';
import { cloneDeep, debounce } from 'lodash';
import { InfoLabel } from 'common/info-label';
import { useHandleApiError } from 'hooks/use-handle-api-error';
import { QrCodesDetailsModal } from 'common/qr-codes-details-modal';
import CypressIds from 'cypressIds';
import { trackCustomEvent } from 'helpers/analytics';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import { AlbumCardLarge } from './components/album-card-large';
import { AlbumCardRow } from './components/album-card-row';
import { AlbumCardMobile } from './components/album-card-mobile';
import styles from './albums.module.scss';
import { AlbumsFilters } from './components/albums-filters';

const ROWS_PER_PAGE_DEFAULT = paginationDefault.pageSize;
const INITIAL_CURRENT_PAGE = paginationDefault.pageNum;
const DEFAULT_SORT_BY_OBJECT = albumsOrderByOptions.find(
  (elem: SelectType) => elem.id === paginationDefault.lastUpdate.id,
);

const Albums: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);

  const [albums, setAlbums] = useState<AlbumType[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [isLayoutsColumns, setIsLayoutsColumns] = useState(true);
  const [search, setSearch] = useState('');
  const [orderByOption, setOrderByOption] = useState(DEFAULT_SORT_BY_OBJECT);
  const [fetching, setFetching] = useState(true);
  const [fetchingAlbums, setFetchingAlbums] = useState(false);
  const [showError, setShowError] = useState(false);
  const [queryParams, setQueryParams] = useState<AlbumsQueryParams>({
    pageSize: ROWS_PER_PAGE_DEFAULT,
    pageNum: INITIAL_CURRENT_PAGE,
    sortBy: DEFAULT_SORT_BY_OBJECT.id,
  });
  const [showQrCodesModal, setShowQrCodesModal] = useState(false);
  const [qrCodesForModal, setQrCodesForModal] = useState<QrCode[]>([]);

  const firstCardRef = useRef(null);

  const didMount = useRef(false);
  const concatNextAlbums = useRef(false);
  const mobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const setApiError = useHandleApiError(() => {
    setShowError(true);
  });

  const fetchAlbums = async () => {
    try {
      const { data, pagination } =
        await AlbumsController.getAlbums(queryParams);

      let newAlbums = data;

      if (concatNextAlbums.current) {
        const actualAlbums = cloneDeep(albums);
        newAlbums = actualAlbums.concat(newAlbums);
      }

      setAlbums(newAlbums);
      setPaginationInfo(pagination);
    } catch (err: any) {
      setApiError(err);
    } finally {
      setFetching(false);
      setFetchingAlbums(false);
    }
  };

  // TODO: Uncomment this to show the first message for the onboarding
  // useEffect(() => {
  // if (firstCardRef.current && !state.data.user.firstVisitDisplayed) {
  // onboardingDispatch({
  //   type: onboardingActions.ONBOARDING_MESSAGE,
  //   onboardingMessage: new OnboardingMessage({
  //     show: true,
  //     title: 'Welcome to Generational Story!',
  //     message: 'Open your album to start your story.',
  //     itemRef: firstCardRef,
  //     skippable: true,
  //     confirmationButton: {
  //       text: 'Ok',
  //       action: () => {
  //         if (albums.length) {
  //           goToPage(RouteName.AlbumDetail, { id: albums[0].id });
  //         }
  //         setTimeout(() =>
  //           onboardingDispatch({
  //             type: onboardingActions.SET_ONBOARDING_PHASE,
  //             onboardingPhase: OnboardingPhase.productTour,
  //           }),
  //         );
  //       },
  //     },
  //   }),
  // });
  //   }
  // }, [firstCardRef.current, state.data.user.firstVisitDisplayed]);

  useEffect(() => {
    if (!fetchingAlbums) {
      if (albums.length) {
        dispatch({
          type: appActions.SET_HELP_SECTION,
          helpSection: HelpInfoSections.albums_filled,
        });
      } else {
        dispatch({
          type: appActions.SET_HELP_SECTION,
          helpSection: HelpInfoSections.albums_empty,
        });
      }
    }
  }, [albums, fetchingAlbums]);

  useEffect(() => {
    // if is a temporary user he can't see the albums page
    if (isTemporaryUser(state.data.user)) {
      if (state.data.user.trialAlbumId) {
        goToPage(RouteName.AlbumDetail, { id: state.data.user.trialAlbumId });
      } else {
        goToPage(RouteName.NotFound);
      }
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: appActions.ACTUAL_SECTION,
      actualSection: NavSections.albums,
    });

    if (!didMount.current) {
      didMount.current = true;
      return;
    }

    fetchAlbums();
  }, []);

  useEffect(() => {
    setFetchingAlbums(true);
    fetchAlbums();
  }, [queryParams]);

  const navigateToAlbumCreation = () => {
    goToPage(RouteName.AlbumNew);
    trackCustomEvent('startAlbumCreation', { step: 'home' });
  };

  const handleDebounceFn = (value: string) => {
    const params = { ...queryParams };
    if (value === '') delete params.search;
    else params.search = value;

    setQueryParams({ ...params, pageNum: 1 });
  };

  const debounceFn = useCallback(debounce(handleDebounceFn, 500), [
    queryParams,
  ]);

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    concatNextAlbums.current = false;
    setSearch(value);
    debounceFn(value);
  };

  const onClickSortBy = (option: SelectType) => {
    concatNextAlbums.current = false;
    setOrderByOption(option);

    setQueryParams((prevState) => ({
      ...prevState,
      sortBy: option.id as string,
      pageNum: 1,
    }));
  };

  const getMoreAlbums = async () => {
    const newPageNum = queryParams.pageNum + 1;
    concatNextAlbums.current = true;
    setQueryParams((prevState) => ({ ...prevState, pageNum: newPageNum }));
  };

  const handleClickCard = (id: number, keyEvent?: boolean) => () => {
    if (!keyEvent) {
      goToPage(RouteName.AlbumDetail, { id });
    } else if (!showQrCodesModal) {
      goToPage(RouteName.AlbumDetail, { id });
    }
  };

  const handleClickQrIcon = async (id: number) => {
    setShowQrCodesModal(true);
    const qrCodes = albums.find((album) => album.id === id)?.qrCodes ?? [];
    setQrCodesForModal(qrCodes);
  };

  const showAlbumStatusLabel = (alb: AlbumType, positionClass: string) => {
    const { withIcon, statusValue } = getAlbumStatusLabel(alb);

    return (
      statusValue && (
        <InfoLabel
          className={classnames(
            'text__body__semi__bold__overline__textNeutral30',
            styles.albumStatusBox,
            positionClass,
          )}
          withIcon={withIcon}
          content={
            <p
              className={classnames(
                'text__body__semi__bold__overline__textNeutral30',
              )}
            >
              {statusValue}
            </p>
          }
        />
      )
    );
  };

  const showHeaderRow = () => (
    <div className={styles.headerRow}>
      <div className={styles.headerContent}>
        <div className={styles.headerFirstRow}>
          <div
            className={classnames(
              styles.albumTitle,
              mobile
                ? 'text__heading3__mobile__textNeutral40'
                : 'text__heading4__textNeutral40',
            )}
          >
            {' '}
            Click the album you want to open{' '}
          </div>
          <div className={styles.createButtonBox}>
            <Button
              buttonSize={mobile ? ButtonSize.Small : ButtonSize.Medium}
              buttonType={ButtonType.Submit}
              buttonStyle={ButtonStyle.PrimaryStroke}
              onClick={navigateToAlbumCreation}
              dataCy={CypressIds.newAlbumButton}
            >
              <div className={styles.createButtonInside}>
                <PlusIcon />
                <span>{mobile ? 'New' : 'New Album'}</span>
              </div>
            </Button>
          </div>
        </div>
        <div className={styles.headerSecondRow}>
          <div className={styles.inputSearch}>
            <Input
              id="search"
              name="search"
              placeholder="Search"
              value={search}
              withValidation={false}
              withIconEnd={
                <SearchIcon className={classnames(styles.searchIcon)} />
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeSearch(e)}
            />
          </div>
          <div className={styles.sortAndFilters}>
            <div className={styles.orderByDropdown}>
              <span className="mr-8 text__body__regular__small__textNeutral30">
                {' '}
                Sort by:{' '}
              </span>
              <Select
                options={albumsOrderByOptions}
                optionSelected={orderByOption}
                onClickOption={(option) => onClickSortBy(option)}
                id="orderByOption"
                value={orderByOption.value}
                placeholder="Select option"
              />
            </div>
            <AlbumsFilters
              queryParams={queryParams}
              setQueryParams={setQueryParams}
            />
            <div className={styles.iconsLayout}>
              <button type="button" onClick={() => setIsLayoutsColumns(true)}>
                <ColumnsIcon
                  className={classnames(
                    isLayoutsColumns
                      ? styles.iconSelected
                      : styles.iconNotSelected,
                  )}
                />
              </button>
              <button type="button" onClick={() => setIsLayoutsColumns(false)}>
                <ListIcon
                  className={classnames(
                    !isLayoutsColumns
                      ? styles.iconSelected
                      : styles.iconNotSelected,
                  )}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const showCardsLarge = () => (
    <div className={styles.cardsLarge}>
      {albums.map((album, index) => (
        <AlbumCardLarge
          cardRef={index === 0 ? firstCardRef : undefined}
          key={album.id}
          album={album}
          showAlbumStatusLabel={showAlbumStatusLabel}
          notifyClickCard={handleClickCard}
          notifyClickQrIcon={(id) => {
            handleClickQrIcon(id);
          }}
        />
      ))}
      <div
        className={styles.newAlbumCard}
        ref={albums.length === 0 ? firstCardRef : undefined}
      >
        <button
          type="button"
          className={styles.newAlbumCardContent}
          onClick={navigateToAlbumCreation}
        >
          <Button className={styles.openAlbumButton}>
            <PlusIcon /> New album
          </Button>
        </button>
      </div>
    </div>
  );

  const showCardsRow = () => (
    <div className={styles.cardsRows}>
      <div className={styles.titles}>
        {Object.keys(albumsTitles).map((title) => (
          <div
            key={title}
            className={classnames(
              styles[title],
              'text__body__semi__bold__medium__textNeutral40',
            )}
          >
            {albumsTitles[title]}
          </div>
        ))}
      </div>
      {albums.map((album) => (
        <AlbumCardRow
          key={album.id}
          album={album}
          showAlbumStatusLabel={showAlbumStatusLabel}
          notifyClickCard={handleClickCard}
          notifyClickQrIcon={(id) => {
            handleClickQrIcon(id);
          }}
        />
      ))}
    </div>
  );

  const showCardsMobile = () => (
    <div className={styles.cardsMobile}>
      {albums.map((album) => (
        <AlbumCardMobile
          key={album.id}
          album={album}
          showAlbumStatusLabel={showAlbumStatusLabel}
          notifyClickCard={handleClickCard}
          notifyClickQrIcon={(id) => {
            handleClickQrIcon(id);
          }}
        />
      ))}
    </div>
  );

  const showRowsLayout = () => (mobile ? showCardsMobile() : showCardsRow());

  const showAlbums = () =>
    isLayoutsColumns ? showCardsLarge() : showRowsLayout();

  const showMoreAlbumsButton = () => (
    <div className={styles.moreAlbumsButton}>
      {!fetchingAlbums ? (
        <Button
          buttonStyle={ButtonStyle.PrimaryRoundedFilled}
          onClick={() => getMoreAlbums()}
        >
          Show more albums
        </Button>
      ) : (
        <Spinner className={globalStyles.smallSpinner} />
      )}
    </div>
  );

  const isNotLastPage = () =>
    paginationInfo?.currentPage !== paginationInfo?.lastPage;

  const showContentView = () => (
    <>
      {showAlbums()}
      <div className={styles.moreAlbumsButton}>
        {albums.length && isNotLastPage() ? showMoreAlbumsButton() : ''}
      </div>
    </>
  );

  const showErrorNotification = () => (
    <Notification handleClose={() => setShowError(false)} />
  );

  return (
    <>
      {showHeaderRow()}
      <div className={globalStyles.loggedMainContainer}>
        {!fetching ? (
          <div
            className={classnames({ [styles.contentView]: isLayoutsColumns })}
          >
            {showError && showErrorNotification()}
            {showQrCodesModal && (
              <QrCodesDetailsModal
                qrCodes={qrCodesForModal}
                onClose={() => setShowQrCodesModal(false)}
              />
            )}
            {showContentView()}
          </div>
        ) : (
          <Spinner />
        )}
      </div>
    </>
  );
};

export { Albums };
