import { Button, ButtonSize, ButtonStyle, ButtonType } from 'common/button';
import { ChevronRightIcon, InfoIcon, LinkIcon, ShareIcon } from 'assets/icons';
import { useContext, useState } from 'react';
import { SelfRecordingContext } from 'context/self-recording-context';
import { appActions, AppContext } from 'context';
import {
  classnames,
  isAnyLoggedUser,
  isFreeTrial,
  isOwner,
  showTextEllipsis,
  totalAlbumVideoClips,
  userPermissionUpgrade,
} from 'helpers/utils';
import { Breakpoints } from 'common/enums';
import { useMediaQuery } from 'hooks/use-media-query';
import { Select } from 'common/select';
import { LocalStorageApi } from 'helpers/local-storage';
import { selfRecordingActions } from 'context/self-recording-context/action-types';
import { InfoBadge } from 'common/info-badge/info-badge';
import { HelpInfoSections } from 'context/app-context/context-reducer';
import { CameraRecord } from './components/camera-record';
import { UploadVideos } from './components/upload-videos';
import styles from './camera-upload.module.scss';

type CameraUploadProps = {
  album: AlbumType;
  selectedQuestion?: ClipType;
  notifySelectQuestion: (id?: string) => void;
  notifySelectCustomRecording: () => void;
  notifyShowInviteModal?: () => void;
  upgradeAction?: () => void;
  refetchAlbum: () => Promise<void>;
};

const CameraUpload: React.FC<CameraUploadProps> = ({
  album,
  selectedQuestion,
  notifySelectQuestion,
  notifySelectCustomRecording,
  notifyShowInviteModal,
  upgradeAction,
  refetchAlbum,
}) => {
  const { state, dispatch } = useContext(SelfRecordingContext);
  const { state: generalState, dispatch: generalDispatch } =
    useContext(AppContext);
  const [uploadMode, setUploadMode] = useState(state.uploadMode);

  const getHelpSection = () => {
    if (uploadMode) {
      return album.unansweredQuestions?.length
        ? HelpInfoSections.upload_filled
        : HelpInfoSections.upload_empty;
    }
    return album.unansweredQuestions?.length
      ? HelpInfoSections.record_filled
      : HelpInfoSections.record_empty;
  };
  const toggleUploadMode = () => {
    generalDispatch({
      type: appActions.SET_HELP_SECTION,
      helpSection: getHelpSection(),
    });
    setUploadMode(!uploadMode);
    dispatch({
      type: selfRecordingActions.setUploadMode,
      uploadMode: !uploadMode,
    });
  };
  const isMobile = useMediaQuery(`(max-width: ${Breakpoints.sm}px)`);
  const maxQuestionsAllowed = album.maxClips;
  const guestToken = LocalStorageApi.get('guestToken');

  const buttonText = uploadMode ? 'Record a video' : 'Upload a video';

  const showTitle = () => {
    if (uploadMode) {
      return 'Upload Video';
    }
    return 'Record Video';
  };

  const showCamera = () => (
    <CameraRecord
      album={album}
      upgradeAction={upgradeAction}
      refetchAlbum={refetchAlbum}
    />
  );

  const showUpload = () => (
    <UploadVideos
      album={album}
      clipsExistingAmount={totalAlbumVideoClips(album)}
      refetchAlbum={refetchAlbum}
    />
  );

  const onSelectQuestion = (option: SelectType) => {
    if (option.id !== '-1') {
      notifySelectQuestion(option.id as string);
    } else {
      notifySelectCustomRecording();
    }
  };

  const showMode = () => {
    if (uploadMode) {
      return showUpload();
    }
    return showCamera();
  };

  return (
    <div className={styles.allPage}>
      <div className={styles.titleAndButton}>
        <div className={styles.title}>{showTitle()}</div>
        <div className={styles.buttonAndText}>
          <span className="text__body__regular__overline__textNeutral30">
            OR
          </span>
          <Button
            buttonSize={ButtonSize.Small}
            buttonType={ButtonType.Button}
            buttonStyle={ButtonStyle.PrimaryStroke}
            disabled={
              state.isRecording ||
              state.isUploading ||
              (isAnyLoggedUser(generalState.data.user) &&
                !generalState.data.user.permissions?.permissions.uploadVideos)
            }
            onClick={toggleUploadMode}
          >
            <div className={styles.buttonAndIcon}>
              <ShareIcon />
              {buttonText}
            </div>
          </Button>
        </div>
      </div>
      <div className={styles.questionSelected}>
        <span className="text__body__regular__overline__textNeutral30">
          QUESTION SELECTED
        </span>
        {isMobile ? (
          <Select
            options={[
              { id: '-1', value: 'Custom recording' },
              ...(album?.unansweredQuestions?.map((question: ClipType) => ({
                id: question.id,
                value: question.name,
              })) ?? []),
            ]}
            optionSelected={{
              id: state.selectedQuestion?.id ?? '-1',
              value: state.selectedQuestion?.name ?? 'Custom recording',
            }}
            onClickOption={onSelectQuestion}
            id="questions"
            value={
              selectedQuestion
                ? showTextEllipsis(selectedQuestion?.name, 60)
                : 'Custom recording'
            }
            placeholder="Select a question"
          />
        ) : (
          <span className="text__body__semi__bold__medium__textNeutral40">
            {selectedQuestion ? selectedQuestion?.name : 'Custom recording'}
          </span>
        )}
      </div>
      {showMode()}
      <InfoBadge
        icon={<InfoIcon className={classnames(styles.infoIcon)} />}
        className={styles.banner}
        message={`${album?.clips?.length} out of ${maxQuestionsAllowed} clips recorded.`}
        showAction={
          isOwner(generalState.data.user, album) &&
          isFreeTrial(album) &&
          userPermissionUpgrade(generalState.data.user)
        }
        buttonAction={upgradeAction}
        buttonText="Upgrade album"
        buttonIcon={<ChevronRightIcon />}
      />
      {isMobile && !guestToken && (
        <>
          <div className={styles.lineSeparator} />
          <div className={styles.inviteGuest}>
            <span className="text__body__regular__overline__textNeutral30">
              WANT SOMEONE ELSE TO RECORD?
            </span>
            <Button
              className={styles.copyLinkButton}
              buttonStyle={ButtonStyle.PrimaryStroke}
              buttonSize={ButtonSize.Small}
              onClick={notifyShowInviteModal}
            >
              <LinkIcon />
              Invite collaborator(s)
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export { CameraUpload };
