/* eslint-disable max-len */
import { AgoraVideoCallProps } from 'models/agora-video-call-props';
import { Owner } from 'models/owner';
import {
  AlbumsQueryParamsRaw,
  AlbumsQueryParamsSerialized,
} from 'networking/types/albums-query-params';
import { PaginationSerializer } from './pagination-serializer';
import { PaymentSerializer } from './payment-serializer';
import { OwnerSerializer } from './owner-serializer';

class AlbumsSerializer {
  static deSerializeContributorInfo(
    data: ContributorRaw,
  ): ContributorSerialized {
    return {
      id: data.id,
      name: data.name,
      email: data.email,
      backupEmail: data.backup_email,
      backupName: data.backup_name,
      invitationId: data.invitation_id,
      invitationStatus: data.invitation_status,
      invitationResent: false,
    };
  }

  static deSerializeAlbums(
    response: AlbumsPaginationRaw,
  ): AlbumsPaginationSerialized {
    return {
      data: response.data.map((album: AlbumRaw) =>
        AlbumsSerializer.deSerializeAlbum(album),
      ),
      pagination: PaginationSerializer.deSerializePagination(
        response.pagination,
      ),
    };
  }

  static deSerializeContributorInCallResponse(
    data: ContributorInCallRaw,
  ): ContributorInCallSerialized {
    return {
      id: data.id,
      name: data.name,
    };
  }

  static deSerializeReminder(data: ReminderRaw): ReminderType {
    return {
      id: data.id,
      frequency: data.frequency,
      targetId: data.target_id,
      lastEditorId: data.last_editor_id,
      targetIsOwner: data.target_is_owner,
    };
  }

  static getDeSerializeQrCode = (qrCode: QrCodeRaw): QrCode => ({
    id: qrCode.id,
    code: qrCode.code,
    albumId: qrCode.album_id,
    clipId: qrCode.clip_id,
    owner: qrCode.owner,
    linkDate: qrCode.link_date ?? undefined,
    image: qrCode.image_url ?? undefined,
  });

  static deSerializeAlbum(album: AlbumRaw): AlbumSerialized {
    const deSerializeClips = (clips: ClipRaw[]): ClipSerialized[] =>
      clips.map((clip: ClipRaw) => AlbumsSerializer.deSerializeClip(clip));

    const getDeSeralizeUnansweredQuestions = (
      clips: ClipRaw[],
    ): ClipSerialized[] => {
      const unansweredQuestions = clips.filter(
        (clip: ClipRaw) => !clip.video_url!,
      );

      return deSerializeClips(unansweredQuestions);
    };

    const getDeSeralizeAnswerQuestions = (
      clips: ClipRaw[],
    ): ClipSerialized[] => {
      const answeredQuestions = clips.filter((clip: ClipRaw) => clip.video_url);

      return deSerializeClips(answeredQuestions);
    };

    const getDeSerializeAgoraVideoCallProps = (
      videoCallprops: AgoraVideoCallPropsRaw,
    ): AgoraVideoCallPropsSerialized => ({
      maxCallTime: videoCallprops.max_call_time,
      usedCallTime: videoCallprops.used_call_time,
      ownerName: videoCallprops.owner_name,
      videoCallToken: videoCallprops.video_call_token,
      videocallInterviewerId: videoCallprops.videocall_interviewer_id,
      videocallRespondentId: videoCallprops.videocall_respondent_id,
      videocallInterviewerIsGuest:
        videoCallprops.videocall_interviewer_is_guest,
      videocallRespondentIsGuest: videoCallprops.videocall_respondent_is_guest,
    });

    return {
      id: album.id,
      title: album.title,
      coverUrl: album.cover_url,
      coverThumbnailUrl: album.cover_thumbnail_url,
      clipAmount: album.clip_amount,
      createdAt: album.created_at,
      lastUpdate: album.last_update,
      totalTime: album.total_time,
      unansweredQuestions: album.clips
        ? getDeSeralizeUnansweredQuestions(album.clips)
        : [],
      clips: album.clips ? getDeSeralizeAnswerQuestions(album.clips) : [],
      scheduledDowngrade: album.scheduled_downgrade,
      subscriptionStatus: album.subscription_status,
      subscriptionType: album.subscription_type,
      owner: album.owner
        ? OwnerSerializer.deSerializeOwnerInfo(album.owner)
        : new Owner(),
      contributors: album.contributors
        ? album.contributors.map((contri) =>
            this.deSerializeContributorInfo(contri),
          )
        : [],
      lastPayment: album.last_payment
        ? PaymentSerializer.deSerializePaymentInfo(album.last_payment)
        : null,
      lastPendingPayment: album.last_pending_payment
        ? PaymentSerializer.deSerializePaymentInfo(album.last_pending_payment)
        : null,
      nextPaymentDate: album.next_payment_date ? album.next_payment_date : null,
      nextPaymentAmount: album.next_payment_amount,
      videoCallProps: album.video_call_props
        ? getDeSerializeAgoraVideoCallProps(album.video_call_props)
        : new AgoraVideoCallProps(),
      qrCodes: album.qr_codes
        ? album.qr_codes.map(this.getDeSerializeQrCode)
        : [],
      reminder: album.reminder
        ? this.deSerializeReminder(album.reminder)
        : undefined,
      maxClips: album.max_clips,
    };
  }

  static serializeAlbumsQueryParams(
    params: AlbumsQueryParamsSerialized,
  ): AlbumsQueryParamsRaw {
    return {
      search: params.search,
      sort_by: params.sortBy,
      page_num: params.pageNum,
      page_size: params.pageSize,
      role: params.role,
      active: params.active,
      has_qr: params.hasQr,
      locked: params.locked,
      restricted: params.restricted,
    };
  }

  static deSerializeClip(clip: ClipRaw): ClipSerialized {
    return {
      id: clip.id,
      createdAt: clip.created_at,
      createdBy: clip.created_by,
      recordedBy: clip.recorded_by,
      recordedByOwner: clip.created_by_owner,
      createdByOwner: clip.created_by_owner,
      description: clip.description,
      isPrivate: clip.is_private,
      length: clip.length,
      name: clip.name,
      order: clip.order,
      recordedAt: clip.recorded_at,
      thumbnailUrl: clip.thumbnail_url,
      videoUrl: clip.video_url,
      recordingStatus: clip.recording_status,
      qrCodes: clip.qr_codes
        ? clip.qr_codes.map(this.getDeSerializeQrCode)
        : [],
      isAudioOnly: clip.is_audio_only,
      customThumbnail: clip.custom_thumbnail_url,
    };
  }

  static serializeNewAlbum(newAlbum: AlbumNewRequestType): AlbumNewRequestRaw {
    return {
      album: {
        title: newAlbum.title,
        cover_signed_id: newAlbum.coverSignedId,
        subscription_type: newAlbum.subscriptionType,
        contributors: newAlbum.contributors,
      },
    };
  }

  static serializeEditQuestion(
    question: QuestionEditRequestType,
  ): QuestionEditRequestRaw {
    return {
      id: question.id,
      name: question.name,
      description: question.description,
      is_private: question.isPrivate,
    };
  }

  static serializeAlbumEditRequest(
    data: AlbumEditRequestType,
  ): AlbumEditRequestRaw {
    const { questions } = data.album;

    return {
      album: {
        id: data.album.id,
        title: data.album.title,
        cover_signed_id: data.album.coverSignedId,
        questions:
          questions && questions.map((q) => this.serializeEditQuestion(q)),
        template_ids: data.album.templateIds,
      },
    };
  }

  static serializeClipEditRequest(
    clip: ClipEditRequestType,
  ): ClipEditRequestRaw {
    return {
      clip: {
        name: clip.name,
        description: clip.description,
        is_private: clip.isPrivate,
        video_signed_id: clip.videoSignedId,
        is_audio_only: clip.isAudioOnly,
        custom_thumbnail_signed_id: clip.customThumbnailSignedId,
        recorded_at: clip.recodedAt,
        batch_id: clip.batchId,
        position_in_batch: clip.positionInBatch,
      },
    };
  }

  static serializeDestroyClipsRequest(
    data: DestroyClipsRequestType,
  ): DestroyClipsRequestRaw {
    return {
      clip_ids: data.clipIds,
    };
  }

  static serializeCreateClipRequest(
    data: CreateClipRequestType,
  ): CreateClipRequestRaw {
    return {
      clip: {
        video_signed_id: data.clip.videoSignedId,
        name: data.clip.name,
        recorded_at: data.clip.recordedAt,
        description: data.clip.description,
        is_audio_only: data.clip.isAudioOnly,
        custom_thumbnail_signed_id: data.clip.customThumbnailSignedId,
        batch_id: data.clip.batchId,
        position_in_batch: data.clip.positionInBatch,
      },
    };
  }

  static deSerializeGuest(data: RecordingGuestRaw): RecordingGuestType {
    return {
      id: data.id,
      album_id: data.album_id,
      album_title: data.album_title,
      email: data.email,
      name: data.name,
      expiration_date: data.expiration_date,
      token: data.token,
      album_url: data.album_url,
    };
  }

  static serializeReminderRequest(
    data: ReminderRequestType,
  ): ReminderRequestRaw {
    return {
      reminder: {
        target_id: data.targetId,
        frequency: data.frequency,
        target_is_owner: data.targetIsOwner,
      },
    };
  }

  static serializeBuyExtraCallTime(
    extra_call_time: number,
  ): BuyExtraCallTimeRaw {
    return {
      album: {
        hours_amount: extra_call_time,
      },
    };
  }
}

export { AlbumsSerializer };
